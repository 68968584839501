// useContextMenu.js
import { useEffect, useCallback, useState } from "react";

const useContextMenu = () => {
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);

    const handleContextMenu = useCallback(
        (event) => {
            event.preventDefault();
            setAnchorPoint({ x: event.clientX , y: event.clientY });
            setShow(true);
        },
        [setShow, setAnchorPoint]
    );

    const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

    useEffect(() => {
        const FolderTreeEl = document.querySelector('#TreeViewID > li');
        document.addEventListener("click", handleClick);
        FolderTreeEl.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("click", handleClick);
            FolderTreeEl.removeEventListener("contextmenu", handleContextMenu);
        };
    });
    return { anchorPoint, show };
};

export default useContextMenu;