import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import './Properties';
import {
    Alert,
    Button,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItem,
    ListItemButton,
    MenuItem, Portal,
    Select,
    Skeleton,
    Slide,
    Stack,
    TextField,
    Tooltip, tooltipClasses
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import FunctionsIcon from '@mui/icons-material/Functions';
import TableViewIcon from '@mui/icons-material/TableView';
import DoneIcon from '@mui/icons-material/Done';
import LinkIcon from '@mui/icons-material/Link';
import ColumnsIcon from '@mui/icons-material/ViewColumn';
import RowsIcon from '@mui/icons-material/TableRows';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import InputDataIcon from '@mui/icons-material/ReadMore';

import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';

import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import $ from "jquery";
import Request from "../../utils/request";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@material-ui/core/Dialog";


import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AutocomplateTextarea from "../AutocomplateTextarea/";
import {
    DataGrid,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton
} from "@mui/x-data-grid";
import DraggableRct from "react-draggable";
import Paper from '@mui/material/Paper';
import {ResizableBox} from "react-resizable";
import AbcIcon from "@mui/icons-material/Abc";
import NumberIcon from "@mui/icons-material/Filter1";
import TextIcon from "@mui/icons-material/TextFormat";
import Fade from '@mui/material/Fade';
import DateIcon from "@mui/icons-material/CalendarMonth";
import {withStyles} from "@material-ui/core/styles";
import BooleanIcon from "@mui/icons-material/CompareArrows";
import moment from "moment/moment";
import {styled} from '@mui/material/styles';
import uuid from 'react-uuid';
import {getSelectedCompanyCfg, getToken} from "../../utils/Common";
import jwt_decode from "jwt-decode";
import * as xlsx from "xlsx";
import Loading from "../Loading/Loading";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import OkIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from '@mui/icons-material/Search';
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {useHotkeys} from "react-hotkeys-hook";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        top: '9px',
        backgroundColor: '#FFFFFF',
        color: '#676f7a',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: '15px',
        boxShadow: '5px 5px 10px 0px rgb(171 171 171 / 63%)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    }
}));
const LookupValidateStyle = withStyles({
    arrow: {
        fontSize: 20,
        "&::before": {
            backgroundColor: "rgba(243,62,62,0.8)",
        }
    },
    tooltip: {
        color: "white",
        backgroundColor: "rgba(243,62,62,0.8)",
        height: "20px",
        lineHeight: "20px"
    }
    })(Tooltip);

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
        </StyledGridOverlay>
    );
}

function UnableToSHowOverlay() {
    return (
        <StyledGridOverlay>
            <svg id="SvgjsSvg1001" width="120" height="100">
                <defs id="SvgjsDefs1002"></defs>
                <g id="SvgjsG1008">
                    <svg fill="none" viewBox="0 0 24 24" width="120" height="100">
                        <path fill="#ababab"
                              d="M2.21967 2.21967C1.9534 2.48594 1.9292 2.9026 2.14705 3.19621L2.21967 3.28033L6.25424 7.3149C4.33225 8.66437 2.89577 10.6799 2.29888 13.0644C2.1983 13.4662 2.4425 13.8735 2.84431 13.9741C3.24613 14.0746 3.6534 13.8305 3.75399 13.4286C4.28346 11.3135 5.59112 9.53947 7.33416 8.39452L9.14379 10.2043C8.43628 10.9258 8 11.9143 8 13.0046C8 15.2138 9.79086 17.0046 12 17.0046C13.0904 17.0046 14.0788 16.5683 14.8004 15.8608L20.7197 21.7803C21.0126 22.0732 21.4874 22.0732 21.7803 21.7803C22.0466 21.5141 22.0708 21.0974 21.8529 20.8038L21.7803 20.7197L15.6668 14.6055L15.668 14.604L14.4679 13.4061L11.598 10.5368L11.6 10.536L8.71877 7.65782L8.72 7.656L7.58672 6.52549L3.28033 2.21967C2.98744 1.92678 2.51256 1.92678 2.21967 2.21967ZM10.2041 11.2655L13.7392 14.8006C13.2892 15.2364 12.6759 15.5046 12 15.5046C10.6193 15.5046 9.5 14.3853 9.5 13.0046C9.5 12.3287 9.76824 11.7154 10.2041 11.2655ZM12 5.5C10.9997 5.5 10.0291 5.64807 9.11109 5.925L10.3481 7.16119C10.8839 7.05532 11.4364 7 12 7C15.9231 7 19.3099 9.68026 20.2471 13.4332C20.3475 13.835 20.7546 14.0794 21.1565 13.9791C21.5584 13.8787 21.8028 13.4716 21.7024 13.0697C20.5994 8.65272 16.6155 5.5 12 5.5ZM12.1947 9.00928L15.996 12.81C15.8942 10.7531 14.2472 9.10764 12.1947 9.00928Z"
                              className="color212121 svgShape"></path>
                    </svg>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Unable to show data</Box>
        </StyledGridOverlay>
    );
}

function PaperComponent(props) {
    return (
        <DraggableRct
            handle="#lookup-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </DraggableRct>
    );
}


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    position: "relative"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";


export default function Properties(props) {
    const [state, setState] = React.useState({
        right: false,
    });

    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');

    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const handleInfoClose = (event, reason) => {
       // console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.OpenPropertiesClose(open);
        setState({ ...state, [anchor]: open });
       // console.log ('closed')

        let PreReturned = $.extend(true, {}, props.DiagtamItemProperties);
        PreReturned.rules.items = rules;
        PreReturned.rules.logic = rulesLogic;
        PreReturned.actions = actions;
        PreReturned.Link = link;
        PreReturned.Title = title;
        PreReturned.table = table;
        if (PreReturned.Title){
            props.ReturnedProperties(PreReturned);
        }

    };

    let elementType = props.DiagtamItemProperties.type;

    useEffect(() => {
        if (props.OpenProperties === true) {
          //  console.log ('open', props.DiagtamItemProperties.id)
            setState({right:true});
            GetFunctionListProcess();
        }

            setCompareMode(!!props.compareMode)
            setCompareProperties(props.compareProperties)
        // eslint-disable-next-line
    },[props.OpenProperties]);


    const get_diag_item_color = (type) => {
        const diag_item_colors = {
            "Input":"#aada00",
            "Switch":"#ffda00",
            "Actions":"#ff7070",
            "Rule":"#649de3",
            "Link":"#cf6ee7",
            "Output":"#d3d3d3",
            "Lookup": "rgb(9, 206, 255)",
            "Parallel": "#c72121"

        }
        return diag_item_colors[type];
    }


    //---------------
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [actions, setActions] = useState([]);
    const [rules, setRules] = useState([]);
    const [title, setTitle] = useState('');
    const [rulesLogic, setRulesLogic] = useState('');
    const [link, setLink] = useState('');
    const [selectedLinkId, setSelectedLinkId] = useState('');
    const [linkArr, setLinkArr] = useState([{id: '' , code: '', title: ''}]);

    const [FunctionPopupOpen, setFunctionPopupOpen] = useState(false);
    const [FunctionTitle, setFunctionTitle] = useState('');
    const [FunctionType, setFunctionType] = useState('');
    const [FunctionValue, setFunctionValue] = useState( JSON.stringify({}  , null, 4 ));
    const [currentFuncionIndex, setcurrentFuncionIndex] = useState(null);
    const [FunctionsList, setFunctionsList] = useState([]);

    const [LookupPopupOpen, setLookupPopupOpen] = useState(false);
    const [LookupTitle, setLookupTitle] = useState('');
    const [lookupTableSize , setlookupTableSize] = useState('empty');

    const [lookupColName , setlookupColName] = useState('');
    const [lookupColType , setlookupColType] = useState('String');

    const [lookupColumns , setlookupColumns] = useState([]);
    const [lookupRows , setlookupRows] = useState( []);


    const [DataExpression , setDataExpression] = useState('');
    const [FixedDataExpression , setFixedDataExpression] = useState(null);

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [tooltipValidationText, setTooltipValidationText] = useState('');

    const [moreActionsAnchorEl, setmoreActionsAnchorEl] = useState(null);

    const [table , setTable] = useState({ columns: [], rows: [] , dataExpression: null});

    const [loading, setLoading] = useState(false);
    const [ExpErr, setExpErr] = useState(false);


    const [FromInputDataAnchorEl, setFromInputDataAnchorEl] = useState(null);

    const [confirmclearAllDialog, setConfirmclearAllDialog] = useState(false);
    const [confirmclearRowsDialog, setConfirmclearRowsDialog] = useState(false);

    const [confirmRowDeleteDialog, setConfirmRowDeleteDialog] = useState(false);
    const [curRowData, setCurRowData] = useState(null);

    const [confirmColumnDeleteDialog, setConfirmColumnDeleteDialog] = useState(false);
    const [deletableE, setDeletableE] = useState(null);
    const [deletableCurrentColumn, setDeletableCurrentColumn] = useState(null);

    const [pageSize, setPageSize] = useState(25);

    const [compareMode,setCompareMode] = useState(false);
    const [compareProperties,setCompareProperties] = useState(true);


    const moreActionsOpen = Boolean(moreActionsAnchorEl);
    const moreActionsHandleClick = (event) => {
        setmoreActionsAnchorEl(event.currentTarget);
    };
    const moreActionsHandleClose = () => {
        setmoreActionsAnchorEl(null);
    };

    useHotkeys('ctrl+f', (e) =>{
            e.preventDefault();
            $('.filter-button').click();
        }
    );

    useHotkeys('cmd+f', (e) =>{
            e.preventDefault();
            $('.filter-button').click();
        }
    );

    $(document).on('keypress', function(event) {
        if (event.keyCode === 13) {
                if ($('.MuiDataGrid-panel').length > 0 && $('.MuiDataGrid-filterForm').length  && $('.LookupWrapper').length > 0){
                    $('.filter-button').click();
                }
            }
    });



    const token = getToken();
    let company = 0;
    if (token){
        const userInfo = jwt_decode(getToken());
        if (getSelectedCompanyCfg()) company = getSelectedCompanyCfg()[userInfo.sub];
    }


    useEffect(() => {
        let _rules = props.DiagtamItemProperties.rules.items || [{value : ''}];
        let _actions = props.DiagtamItemProperties.actions || [{value : ''}];
        let _table = props.DiagtamItemProperties.table || { columns: [], rows: [] , dataExpression: null };
        _rules.push({value : ''});
        _actions.push({value : ''});
        setRules(_rules);
        setActions(_actions);
        setlookupRows(_table.rows);

        setDataExpression(_table.dataExpression);
        setFixedDataExpression(_table.dataExpression);
        setTitle(props.DiagtamItemProperties.Title);
        setLink(props.DiagtamItemProperties.Link);
        setRulesLogic(props.DiagtamItemProperties.rules.logic);
        setlookupTableSize(_table.columns.length + "x" + _table.rows.length);


        if (_table.columns && compareMode){
            for (let i = 0; i < _table.columns.length; i++) {
                _table.columns[i].editable = false
            }
        }


        setTable(_table);

        //console.log ('ddd',_table)


        // let ReqBody = {
        //     "StartIndex": 0,
        //     "RowCount": 0,
        //     "SortBy": [
        //         {
        //             "Field": "code",
        //             "Direction": "Asc"
        //         }
        //     ],
        //     "Filter": {
        //         "Operator": "And",
        //         "Filters": [
        //             // {
        //             //     "Field": "IsActive",
        //             //     "Value": true,
        //             //     "Operator": "Equals"
        //             // },
        //             {
        //                 Field: "Version",
        //                 Value: 0,
        //                 Operator: "NotEquals"
        //             },
        //             {
        //                 "Field": "FolderId",
        //                 "Value": company,
        //                 "Operator": "Equals"
        //             },
        //             {
        //                 "Field": "Enabled",
        //                 "Value": true,
        //                 "Operator": "Equals"
        //             }
        //         ]
        //     }
        // }



        Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/links?companyId=' + company).get()
            .then((data) => {
                // setLoading(false);
                if (data) {
                    // let arr = [];
                    // let unionArray = data.rows.filter((item) => item.isActive === true);
                    // unionArray.map((row) => arr.push({id: row.id , code: row.code , title: row.title}))
                     setLinkArr(data.rows)
                } else {
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Cannot get list of LINK', 'warning')
        })
        // eslint-disable-next-line
    },[
        props.DiagtamItemProperties.rules.items,
        props.DiagtamItemProperties.actions,
        props.DiagtamItemProperties.Title,
        props.DiagtamItemProperties.Link,
        props.DiagtamItemProperties.rules.logic,
        props.DiagtamItemProperties.table,
    ]);


    useEffect(()=>{
        if (link){
            const SelectedLinkValue = linkArr.find((lnk) => lnk.code === link);
            setSelectedLinkId(SelectedLinkValue.id)
        }
    },[link,linkArr])


    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setPlaceholderProps({})

        if (result.destination.index >= rules.length - 1) {
            result.destination.index = rules.length - 2;
        }


        setRules(rules => reorder(rules, result.source.index, result.destination.index));


        //setActions(actions => reorder(actions, result.source.index, result.destination.index));
    };

   const inputDataExpRegexMatch = (str) => {
        const regexp = /^@([a-zA-Z0-9._@\][]+)$/g;
       return (regexp.exec(str)) !== null;
    }

    const onDragEndActions = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setPlaceholderProps({})

        if (result.destination.index >= actions.length - 1) {
            result.destination.index = actions.length - 2;
        }
        setActions(actions => reorder(actions, result.source.index, result.destination.index));
    };

    const onDragUpdate = update => {
        if(!update.destination){
            return;
        }
        const draggableId = update.draggableId;
        const destinationIndex = update.destination.index;

        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) {
            return;
        }
        const { clientHeight, clientWidth } = draggedDOM;

        const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
            .slice(0, destinationIndex)
            .reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
        });
    };

    function onRulesTextChange(value,index) {
        setRules((prevState) => {
            return [
                ...prevState.slice(0, index),
                prevState[index] = {value : value},
                ...prevState.slice(index + 1),
            ];
        });
        setTimeout(function (){
            if (rules.at(-1).value) {
                setRules([...rules, {value : ''}]);
            }
        },0)



    }



    function onAutoCompleteType(e,index) {
       // console.log('OnType', e);
        if (e.slug) {
            if (e.slug.substring(0,1) === '@') {
                try {
                    let data = JSON.parse(props.DebugValue);
                    let parts = e.slug.substring(1).split('.');
                    if (e.slug.charAt(e.slug.length - 1) !== '.') {
                        parts.pop();
                    }
                    if (parts.length>0 && parts[parts.length-1]==='') {
                        parts.pop();
                    }

                    let obj = data;

                    for(let i=0;i<props.DataFields.length;i++) {
                        obj[props.DataFields[i].name] = props.DataFields[i];
                    }


                    for(let i=0;i<parts.length;i++) {
                        obj = obj[parts[i]];
                    }

                    if (typeof obj === 'object' && obj !== null) {
                        return Object.keys(obj).map( a => '@' + parts.join('.') + (parts.length>0? '.':'') + a);
                    }

                } catch (ex) {

                }
               //console.log ("DebugValue",);
               //console.log ("DataFields",props.DataFields);
            } else {
                return FunctionsList.map( a => a.name );
            }
        } else {
            return FunctionsList.map( a => a.name );
        }
    }

    function onActionTextChange(value,index) {
        setActions((prevState) => {
            return [
                ...prevState.slice(0, index),
                prevState[index] = {value : value},
                ...prevState.slice(index + 1),
            ];
        });
        setTimeout(function (){
        if (actions.at(-1).value) {
            setActions([...actions, {value : ''}]);
        }
        },0)
    }

    const RemoveArea = (index) => {
        setRules(rules.filter((rule,i) => i !== index))
    }

    const RemoveActionsArea = (index) => {
        setActions(actions.filter((action,i) => i !== index))
    }

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const exportToExcel = (data) => {
        const excludedColumns = ["id"]; // Add the column names you want to exclude here
        // let dateColumn = null;

        const filteredData = data.map(item => {
            const filteredItem = { ...item };
            excludedColumns.forEach(column => delete filteredItem[column]);

            Object.entries(filteredItem).forEach(([key, value]) => {
                if (moment.isMoment(value)) {
                    // Convert Moment.js date to string
                    filteredItem[key] = value.format("DD/MM/YYYY");

                    // Track the date column
                    // dateColumn = key;
                }
            });

            return filteredItem;
        });

        const worksheet = XLSX.utils.json_to_sheet(filteredData, {
            dateNF: 'dd/mm/yyyy' // Specify the desired date format
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        const excelData = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        saveAs(excelData, title+ '_lookup_table.xlsx');
    };


    const FunctionButtonClick = (type,index) => {

        setFunctionPopupOpen(true);
        setFunctionTitle (title);
        setFunctionType(type)
        setcurrentFuncionIndex(index)

        switch(type) {
            case 'rule':
                let ruleFromProperties = rules[index] ?  rules[index].value : '';
                setFunctionValue(ruleFromProperties)
                break;
            case 'action':
                let actionFromProperties = actions[index] ? actions[index].value : '';
                setFunctionValue(actionFromProperties)
                break;
            default:
                break;
        }

        GetFunctionListProcess();

    }

    const FunctionPopupClose = () => {
        setFunctionPopupOpen(false);
    }

    const LookupPopupClose = (e,r) => {
            if (r === "backdropClick") {
                e.stopPropagation();
            } else {
                setLookupPopupOpen(false);
            }
    }

    const FunctionReturnResult = () => {
        let newRules = [...rules];
        let newActions = [...actions];

        if(currentFuncionIndex >= 0){
            if (FunctionType === 'rule'){
                newRules[currentFuncionIndex].value = FunctionValue
                setRules(newRules);
            } else if (FunctionType === 'action'){
                newActions[currentFuncionIndex].value = FunctionValue
                setActions(newActions);
            }
        }
        FunctionPopupClose();
    }


    const GetFunctionListProcess = () => {
        if (FunctionsList && FunctionsList.length > 0) {
            return;
        }
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Common/functions').get()
            .then((data) => {
                if (data) {
                    setFunctionsList(data)
                } else {
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Cannot Load Function List', 'error')
        })
    }

    const FuncListDblClick = (e) =>{
        let val = e;
        let txtarea = $('.FunctionContent textarea')[0];
        let start = txtarea.selectionStart;
        let end = txtarea.selectionEnd;
        let sel = txtarea.value.substring(start, end);


        if (val) {
            let funcName = val.name;
            let result = " " + funcName + "(" + sel;
            if (val.param_count > 1) {
                for (let idx = 0; idx < val.param_count - 1; idx++) {
                    result = result + ", ";
                }
            }
            let finText = txtarea.value.substring(0, start) + result + ") " + txtarea.value.substring(end);
            let targetPos = result.length
            if (val.param_count === 0) {
                 targetPos = targetPos + 2;
             } else (
                 targetPos = targetPos - (val.param_count * 2) + 2
             )
            txtarea.value = finText;
            txtarea.focus();
            txtarea.selectionEnd = start + targetPos;

        }
    }


    const ToolbarButtonClick = (e)=>{
        e.preventDefault();
        const value = e.target.dataset.tool;
        let txtarea = $('.FunctionContent textarea')[0];
        let start = txtarea.selectionStart;
        let end = txtarea.selectionEnd;
        //let sel = txtarea.value.substring(start, end);
        let finText = txtarea.value.substring(0, start) + value + txtarea.value.substring(end);
        txtarea.value = finText;
        txtarea.focus();
        txtarea.selectionEnd = start + value.length;
    }

    const handleAddedType = (e) => {
        props.onAddTypeCallback (e);
    }

    function capitalize(word) {
        return word[0].toUpperCase() + word.substring(1);
    }

    const LookupAddColumn = () => {
        let isDublicated = lookupColumns.find(o => o.field === lookupColName);
            if (isDublicated){
                setTooltipValidationText ('Dublicate Column Name');
                setTooltipIsOpen(true);
            } else if (!lookupColName) {
                setTooltipValidationText ('Empty Column Name');
                setTooltipIsOpen(true);
            } else {
                let newColumn = {
                    field: lookupColName,
                    headerName: lookupColName,
                    type: lookupColType.toLowerCase(),
                    width: 250,
                    editable: !compareMode,
                    description: capitalize(lookupColType.toLowerCase())
                };

                if (newColumn.type === 'date') {
                    newColumn.valueFormatter = function (params) {
                        if (params?.value) {
                            return moment(params?.value).format("DD/MM/YYYY");
                        } else {
                            return null;
                        }
                    };
                }
                if (newColumn.type === 'boolean') {
                   lookupRows.forEach(function (row){
                       row[lookupColName] = false;
                   })

                }
                setlookupColumns([...lookupColumns , newColumn]);
                setTimeout(function() {
                    $('.MuiDataGrid-virtualScroller').scrollLeft($('.MuiDataGrid-virtualScrollerContent').width());
                }, 0);
            }
           setlookupColName('');
        }


    function checkProperties(obj) {
        let state = false;
        for (let key in obj) {
            if (key !== 'id'){
                if ( !( obj[key] === null || obj[key] === "" ) ) {
                    state = true;
                    break;
                }
            }
        }
        return state;
    }

    const lookupRowsRef = useRef();
    lookupRowsRef.current = lookupRows ? lookupRows : null;

    const delRowClick = (params) => {
        const currentRow = params;
        let rows = [...lookupRowsRef.current] || [];
       // console.log (rows)
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].id === currentRow.id){
                    rows.splice(i , 1)
            }
        }
        setlookupRows(rows);
    };


    const LookupButtonClick = () => {

        let cols = [...table.columns];
        let rows = table.rows;
        let parsedRows = [];
        // console.log ('cols', cols)
        // console.log ('rows',rows)

       // console.log ('lookupRows',lookupRows)

        setLookupPopupOpen(true);
        setLookupTitle(title);
        let dateCols = [];
        for(let i=0;i<cols.length;i++) {
            if (cols[i].type === 'date') {
                dateCols.push(cols[i].field);
                cols[i].valueFormatter = function (params) {
                    if (params?.value) {
                        return moment(params?.value).format("DD/MM/YYYY");
                    } else {
                        return null;
                    }
                };
            }
        }

        if (!FixedDataExpression){
            cols.unshift({
                    field: 'action',
                    headerName: 'Action',
                    width: 65,
                    sortable: false,
                    disableColumnMenu: true,
                    disableColumnFilter: true,
                    disableColumnSelector: true,
                    disableClickEventBubbling: true,
                    align:'center',
                    renderCell: (params) => {
                        return (
                            <Stack direction="row" spacing={2}>
                                <IconButton disabled={compareMode} component="label" title="Delete Row" fontSize="inherit" className='rowDelButton' variant="outlined" color="secondary" size="small" onClick={()=> { openRowDeleteDialog (params.row)}}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Stack>
                        );
                    },
                },
                {
                    editable:false,
                    field:"rowNum",
                    headerName:"N",
                    width:50,
                    sortable: false,
                    disableColumnMenu: true,
                    disableColumnFilter: true,
                    disableColumnSelector: true,
                    disableClickEventBubbling: true,
                    align:'center',
                    renderCell: (params) => {
                        let aa =  params.api.getVisibleRowModels();
                        // params.api.getRowIndexRelativeToVisibleRows(params.id)
                        const arr = [];
                        aa.forEach((value, key) => arr.push({ key, value }));
                        let index = arr.map((o) => o.key).indexOf(params.id) + 1;

                        return (
                            <div>{index.toString()}</div>
                        );
                    }
                }
            )
        }




        setlookupColumns(cols);
        if (dateCols.length>0) {
            for(let i=0;i<rows.length;i++) {
                let newRow = $.extend(false, {}, rows[i]);
                dateCols.forEach(function (colName) {
                    newRow[colName] = (newRow[colName]?  moment(newRow[colName], 'DD/MM/YYYY') : null);
                });
                parsedRows.push(newRow);
            }
        } else {
            parsedRows  = [...rows];
        }
        setlookupRows(parsedRows);
        if (DataExpression){
            LoadFromDebugValue();
        }
    }



    const LookupReturnResult = () => {
        let cols = lookupColumns;
        let rows = lookupRows;

         for(let i=0;i<cols.length;i++) {
            if (cols[i].field === 'action'){
                cols.splice(i,1);
            }
        }

        for(let i=0;i<cols.length;i++) {
            if (cols[i].field === 'rowNum'){
                cols.splice(i,1);
            }
        }

        let deletable=[];
        for(let i=0;i<rows.length;i++) {
            if (checkProperties(rows[i]) === false){
                deletable.push(rows[i].id);
            }
        }

        if (deletable.length>0) {
                deletable.forEach(function (delId) {
                    let  myRows = [...rows];
                    for(let i=0;i<myRows.length;i++) {
                        if (myRows[i].id === delId){
                          myRows.splice(i, 1);
                        }
                    }
                    rows = myRows;
                });
        }

        let parsedRows = [];
        let dateCols = [];
        let boolCols = [];

        for(let i=0;i<cols.length;i++) {
            if (cols[i].type === 'date') {
                dateCols.push(cols[i].field);
            }
            if (cols[i].type === 'boolean') {
                boolCols.push(cols[i].field);
            }

        }
        if (dateCols.length>0) {
            for(let i=0;i<rows.length;i++) {
                let newRow = $.extend(false, {}, rows[i]);
                dateCols.forEach(function (colName) {
                    newRow[colName] = (newRow[colName]?  moment(newRow[colName]).format('DD/MM/YYYY') : null);
                });
                parsedRows.push(newRow);
            }
        } else {
            parsedRows  = [...rows];
        }

       let Allrows =[];
        if (boolCols.length>0){
            for(let i=0;i<parsedRows.length;i++) {
                let newRow = $.extend(false, {}, parsedRows[i]);

                boolCols.forEach(function (colName) {
                    newRow[colName] = (!(newRow[colName] === null || newRow[colName] === "" || newRow[colName] === 'false' || newRow[colName] === false));
                });
                Allrows.push(newRow);
            }
        }  else {
            Allrows  = [...parsedRows];
        }

        setTable({columns : cols , rows: Allrows , dataExpression : FixedDataExpression || null});
        setlookupTableSize(cols.length + "x" + parsedRows.length);
        setLookupPopupOpen(false);

    }

    const onCellEditCommit= (cellData) => {
       // console.log('cell data', cellData);
        let rows = lookupRows;
        for(let i=0;i<rows.length;i++) {
            if (rows[i].id === cellData.id) {
                rows[i][cellData.field] = cellData.value;
                break;
            }
        }
        setlookupRows(lookupRows => rows);
    }

    let importEl = document.querySelector("#upload_from_excel");
    if (importEl){
        importEl.addEventListener('change', function(e) {
            readUploadFile(e);

        })
    }
    const readUploadFile = (e) => {
        e.preventDefault();
        setlookupColumns([]);
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" , cellDates: true , dateNF: "dd/mm/yyyy"});
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet,{raw: false, defval:null});
             //   console.log('excell json' , json);
                let importedCols = [{
                    field: 'action',
                    headerName: 'Action',
                    width: 65,
                    sortable: false,
                    disableColumnMenu: true,
                    disableColumnFilter: true,
                    disableColumnSelector: true,
                    disableClickEventBubbling: true,
                    align:'center',
                    renderCell: (params) => {
                        return (
                            <Stack direction="row" spacing={2}>
                                <IconButton disabled={compareMode} component="label" title="Delete Row" fontSize="inherit" className='rowDelButton' variant="outlined" color="secondary" size="small" onClick={()=> { openRowDeleteDialog (params.row)}}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Stack>
                        );
                    },
                },
                    {
                        editable:false,
                        field:"rowNum",
                        headerName:"N",
                        width:50,
                        sortable: false,
                        disableColumnMenu: true,
                        disableColumnFilter: true,
                        disableColumnSelector: true,
                        disableClickEventBubbling: true,
                        align:'center',
                        renderCell: (params) => {
                            let aa =  params.api.getVisibleRowModels();
                            // params.api.getRowIndexRelativeToVisibleRows(params.id)
                            const arr = [];
                            aa.forEach((value, key) => arr.push({ key, value }));
                           let index = arr.map((o) => o.key).indexOf(params.id) + 1;

                            return (
                                 <div>{index.toString()}</div>
                            );
                        }
                    }
                ];
                const colNames = Object.keys(json[0]);
                for (let i = 0; i < colNames.length; i++) {
                    importedCols.push(
                        {
                            "field": colNames[i],
                            "headerName": colNames[i],
                            "type": "string",
                            "width": 250,
                            "editable": !compareMode,
                            description: "String"
                        }
                    )
                }


                json.forEach((jsn) => {
                    jsn.id = uuid();
                });
                setlookupColumns(importedCols);
                setlookupRows(json);

                //console.log ('lookupRows' , lookupRows);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }


    const LookupAddRow = () => {
        let rows = lookupRows;
        let gs = uuid();
        let obj = {
            'id': gs,
        };

        lookupColumns.forEach((cl) => {
            if (cl.type === 'boolean') {
                obj[cl.field] = false;
            }
        })

        lookupColumns.forEach((cl) => {
            if (cl.type === 'number') {
                obj[cl.field] = null;
            }
        })

        lookupColumns.forEach((cl) => {
            if (cl.type === 'string') {
                obj[cl.field] = null;
            }
        })

        lookupRows.forEach((col) => {
          //  console.log ('kkk',col)
            if (obj[col.field] !== undefined){
                obj[col.field] = null;
            }
        });
        //console.log ('obj',obj)
        setlookupRows( [...rows, obj]);
        setTimeout(function() {
            $('.MuiDataGrid-virtualScroller').scrollTop($('.MuiDataGrid-virtualScrollerContent').height());
        }, 0);
    }

    const ColDelCLick = (e,curCol,hideMenu) => {
       // hideMenu(e);
       // console.log ('curCol',curCol.field);
        let newColList = lookupColumns.filter(function( obj ) {
            return obj.field !== curCol.field ;
        });

        let newRowsLst = lookupRows;
        for(let i = 0; i < newRowsLst.length; i++ ) delete newRowsLst[i][curCol.field]
        setlookupColumns(newColList);
        setlookupRows(newRowsLst);
    }

    const setColType = (e,curCol,hideMenu) => {
        hideMenu(e);
     //   console.log ('curCol',curCol.field);
   //  console.log ('e',e.target.dataset.value);
      // console.log (lookupColumns)


        let AllIsOK = true;
        let errors = [];
        handleInfoClose();
        if (e){
            switch (e.target.dataset.value.toLowerCase()) {
                case 'number':
                    lookupRows.forEach((lookRow ,index)=> {
                        if (lookRow[curCol.field] !== null && lookRow[curCol.field] !== ''  && lookRow[curCol.field] !== undefined ){
                            let nn = Number (lookRow[curCol.field]);

                            if (isNaN(nn)) {
                                AllIsOK = false;
                                errors.push ("Line Number - " + (index + 1) + " , Column Name - " + curCol.field + " , Value - " + lookRow[curCol.field] );
                                ShowInfo(errors.join('|'),'error')
                            }
                            else
                            {
                                lookRow[curCol.field] = nn;
                            }
                        } else {
                            lookRow[curCol.field] = null;
                        }
                    })
                    break;
                case 'boolean':
                    lookupRows.forEach((lookRow, index) => {
                        if (lookRow[curCol.field] !== null && lookRow[curCol.field] !== ''){
                            if (lookRow[curCol.field] !== '0' &&
                                lookRow[curCol.field] !== '1' &&
                                lookRow[curCol.field] !== 0 &&
                                lookRow[curCol.field] !== 1 &&
                                lookRow[curCol.field] !== 'true' &&
                                lookRow[curCol.field] !== true &&
                                lookRow[curCol.field] !== false &&
                                lookRow[curCol.field] !== undefined &&
                                lookRow[curCol.field] !== 'false')
                            {
                                AllIsOK = false;
                                errors.push ("Line Number - " + (index + 1) + " , Column Name - " + curCol.field + " , Value - " + lookRow[curCol.field] );
                                ShowInfo(errors.join('|'),'error')

                            } else {
                                if (lookRow[curCol.field] === '0' ||
                                    lookRow[curCol.field] === 0 ||
                                    lookRow[curCol.field] === false ||
                                    lookRow[curCol.field] === 'false' ||
                                    lookRow[curCol.field] === undefined) {
                                    lookRow[curCol.field] = false
                                } else if  (lookRow[curCol.field] === '1' ||
                                    lookRow[curCol.field] === 1 ||
                                    lookRow[curCol.field] === 'true' ||
                                    lookRow[curCol.field] === true) {
                                    lookRow[curCol.field] = true
                                }
                            }
                        }
                    })
                    break;
                case 'date':

                    lookupRows.forEach((lookRow , index) => {
                        if (lookRow[curCol.field] !== null && lookRow[curCol.field] !== ''){

                            let date = moment(lookRow[curCol.field],'DD/MM/YYYY');
                           if (!date.isValid()){
                               AllIsOK = false;
                               errors.push ("Line Number - " + (index + 1) + " , Column Name - " + curCol.field + " , Value - " + lookRow[curCol.field] );
                               ShowInfo(errors.join('|'),'error');
                           }
                           else
                           {
                               lookRow[curCol.field] = date;
                           }
                        }
                    })
                    break;
                default:
                    //console.log(`string`);
            }

        }



        if (AllIsOK){
            lookupColumns.forEach(function (lookCol, index){
                if (lookCol.field.toLowerCase() === curCol.field.toLowerCase()) {
                    lookCol.type = (e.target.dataset.value).toLowerCase();
                    if (lookCol.type === 'date'){
                        lookCol.description = 'Date';
                        lookCol.valueFormatter = function (params) {
                            if (params?.value) {
                                let formated = moment(params?.value).format('DD/MM/YYYY');
                                return formated;
                            } else {
                                return null;
                            }}
                    }

                    if (lookCol.type === 'number'){
                        lookCol.description = 'Number';

                        lookCol.valueFormatter = function (params) {
                            if (params?.value) {
                                return Number(params?.value);
                            } else {
                                return null;
                            }}
                    }

                    if (lookCol.type === 'string'){
                        lookCol.description = 'String';
                            lookCol.valueFormatter = function (params) {
                            if (params?.value) {
                                return String(params?.value);
                            } else {
                                return null;
                            }}
                    }

                    if (lookCol.type === 'boolean') {
                        lookCol.description = 'Boolean';
                    }


                }
            })


            setLoading (true);
            setlookupRows(lookupRows);
            LookupReturnResult();

            let cols = [...lookupColumns];
            cols.unshift({
                field: 'action',
                headerName: 'Action',
                width: 65,
                sortable: false,
                disableColumnMenu: true,
                disableColumnFilter: true,
                disableColumnSelector: true,
                disableClickEventBubbling: true,
                align:'center',
                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <IconButton disabled={compareMode} component="label" title="Delete Row" fontSize="inherit" className='rowDelButton' variant="outlined" color="secondary" size="small" onClick={()=> { openRowDeleteDialog (params.row)}}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Stack>
                    );
                },
            },
                {
                    editable:false,
                    field:"rowNum",
                    headerName:"N",
                    width:50,
                    sortable: false,
                    disableColumnMenu: true,
                    disableColumnFilter: true,
                    disableColumnSelector: true,
                    disableClickEventBubbling: true,
                    align:'center',
                    renderCell: (params) => {
                        let aa =  params.api.getVisibleRowModels();
                        // params.api.getRowIndexRelativeToVisibleRows(params.id)
                        const arr = [];
                        aa.forEach((value, key) => arr.push({ key, value }));
                        let index = arr.map((o) => o.key).indexOf(params.id) + 1;

                        return (
                            <div>{index.toString()}</div>
                        );
                    }
                }
                )


           setlookupColumns(cols);

            setTimeout(() => {
                setLookupPopupOpen(LookupPopupOpen)
                setLoading(false);
            }, 500);
        }


    }

    const FromInputDatahandleClose = () => {
        setFromInputDataAnchorEl (null)
    };


    const confirmclearAllDialogClose = (result) => {
        setConfirmclearAllDialog(false);
        if (result) {
            setTimeout(function (){
                clearAlllookup();
            },0)
        }
    };

    const confirmclearRowsDialogClose = (result) => {
        setConfirmclearRowsDialog(false);
        if (result) {
            setTimeout(function (){
                clearRowslookup();
            },0)
        }
    };


    const openRowDeleteDialog = (row) => {
        setCurRowData(row);
        setConfirmRowDeleteDialog(true);
    }
    const confirmRowDeleteDialogClose = (result) => {
        setConfirmRowDeleteDialog(false);
        if (result) {
            setTimeout(function (){
                delRowClick(curRowData);
            },0)
        }
    };


    const LoadFromDebugValue = () =>{
        let wdtAt = DataExpression.slice(1);
        let Path = deepFind(JSON.parse(props.DebugValue) , wdtAt)

        if (!inputDataExpRegexMatch (DataExpression) || Array.isArray(Path)) {
            setFixedDataExpression (DataExpression);
            setFromInputDataAnchorEl(null)
            setlookupRows([]);
            setlookupColumns([]);
            setExpErr(false);
            if (Array.isArray(Path)) {
                let elArr = [];
                const keys = Array.from(new Set(Path.flatMap(obj => Object.keys(obj))));

                keys.forEach(element => {
                    elArr.push ({
                        "field": element,
                        "headerName": element,
                        "flex" : 1,
                        "type": "string",
                        "minWidth": 160,
                        "editable": false,
                        "sortable": false,
                        "disableColumnMenu": true,
                        "disableColumnFilter": true,
                        "disableColumnSelector": true,
                        "disableClickEventBubbling": true,
                        renderCell: (params) =>  (
                            <HtmlTooltip className="ToolTipStyle"  arrow placement="top"  title={params.row[element]} >
                                <span className="table-cell-trucate">{params.row[element]}</span>
                            </HtmlTooltip>
                        ),
                    })
                });



                Path.forEach(el => {
                    el.id = uuid();
                })

                setlookupColumns(elArr);
                setlookupRows(Path);

            }
        } else if (inputDataExpRegexMatch (DataExpression) && !Array.isArray(Path)) {
            setExpErr(true)
        } else {
            setFixedDataExpression (null);
        }
    }


    const openColDeleteDialog = (e, currentColumn, hideMenu) => {
        hideMenu(e);
        setDeletableE (e);
        setDeletableCurrentColumn (currentColumn);
        setConfirmColumnDeleteDialog(true);
    }

    const confirmColumnDeleteDialogClose = (result) => {
        setConfirmColumnDeleteDialog(false);
        if (result) {
            setTimeout(function (){
                ColDelCLick (deletableE , deletableCurrentColumn )
            },0)
        }
    };


    const clearAlllookup = (props) => {
        setlookupRows([]);
        // setlookupColumns(lookupColumns.slice(0,2));

       // setLookupPopupOpen(false);
        setDataExpression('');
        setFixedDataExpression(null);



        let cols = [{
                field: 'action',
                headerName: 'Action',
                width: 65,
                sortable: false,
                disableColumnMenu: true,
                disableColumnFilter: true,
                disableColumnSelector: true,
                disableClickEventBubbling: true,
                align:'center',
                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <IconButton disabled={compareMode} component="label" title="Delete Row" fontSize="inherit" className='rowDelButton' variant="outlined" color="secondary" size="small" onClick={()=> { openRowDeleteDialog (params.row)}}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Stack>
                    );
                },
            },
            {
                editable:false,
                field:"rowNum",
                headerName:"N",
                width:50,
                sortable: false,
                disableColumnMenu: true,
                disableColumnFilter: true,
                disableColumnSelector: true,
                disableClickEventBubbling: true,
                align:'center',
                renderCell: (params) => {
                    let aa =  params.api.getVisibleRowModels();
                    // params.api.getRowIndexRelativeToVisibleRows(params.id)
                    const arr = [];
                    aa.forEach((value, key) => arr.push({ key, value }));
                    let index = arr.map((o) => o.key).indexOf(params.id) + 1;

                    return (
                        <div>{index.toString()}</div>
                    );
                }
            }];

        setlookupColumns(cols);


    }

    const clearRowslookup = (props) => {
        setlookupRows([]);
    }

    const deepFind = (obj, path) => {
        let paths = path.split('.')
            , current = obj
            , i;

        for (i = 0; i < paths.length; ++i) {
            if (current[paths[i]] === undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton className="filter-button"/>
            </GridToolbarContainer>
        );
    }

    const filterPopupOpen = (e) =>{
        e.preventDefault();
        $('.filter-button').click();
    }


    const CustomColumnMenu = (props) => {
        const { hideMenu, currentColumn } = props;
        return (

             currentColumn.field !== 'action' &&

                        <div>
                            <GridColumnMenuContainer
                                hideMenu={hideMenu}
                                currentColumn={currentColumn}
                                open>
                                <ul className="vertical-nav">
                                    {!compareMode &&
                                        <li className="columnMenuItem custmenuitem"
                                            role="menuitem" >Set column type <ArrowRightIcon className="menuarrow" />
                                            <ul className='sub-menu' >
                                                <li onClick={(e) => {setColType (e, currentColumn, hideMenu)}} data-value="Number"><NumberIcon/> Number</li>
                                                <li onClick={(e) => {setColType (e, currentColumn, hideMenu)}} data-value="String"><TextIcon /> String</li>
                                                <li onClick={(e) => {setColType (e, currentColumn, hideMenu)}} data-value="Date"><DateIcon /> Date</li>
                                                <li onClick={(e) => {setColType (e, currentColumn, hideMenu)}} data-value="Boolean"><BooleanIcon /> Boolean</li>
                                            </ul>
                                        </li>
                                    }

                                </ul>


                                <GridToolbarColumnsButton className="columnMenuItem" onClick={hideMenu} column={currentColumn}/>
                                <GridFilterMenuItem className="columnMenuItem custmenuitem" onClick={hideMenu} column={currentColumn} />
                                {!compareMode &&
                                    <li onClick={(e) => {
                                        openColDeleteDialog(e, currentColumn, hideMenu)
                                    }} className="columnMenuItem custmenuitem"
                                        role="menuitem">Delete Column
                                        <span className="MuiTouchRipple-root"></span>
                                    </li>
                                }
                            </GridColumnMenuContainer>
                    </div>
        );
    };

    const list = (right) => (

        <Box sx={{ width: 450 }} >
            <div className="PropertiesHeader">
                <div className="properties_title">
               <span>
               <CircleOutlinedIcon style={{backgroundColor: get_diag_item_color(props.DiagtamItemProperties.type),color:"white"}}> </CircleOutlinedIcon>
                   {props.DiagtamItemProperties.Title}
               </span>
                </div>
                <div className="properties_close"><IconButton onClick={toggleDrawer(right, false)} size="small" sx={{ ml: 2 }} > <CloseIcon />   </IconButton></div>

            </div>

            <div className='ProperyBox'>
                <div className="prop_textfield">
                    <TextField
                        aria-label="minimum height"
                        placeholder="Title"
                        variant="standard"
                        value={title}
                        onChange={(e) => {setTitle(e.target.value)}}
                        disabled={compareMode}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        style={{ width: '100%' , resize: 'none' }}
                    />
                </div>
                {(elementType === "Rule" || elementType === "Switch" || elementType === "Lookup") &&
                    <div>
                        <Divider />
                        <label className="paragraph">Rules</label>
                        <div className="rules_logic">
                            <FormControl fullWidth>
                                <InputLabel id="rules-logic-label">Logic</InputLabel>
                                <Select
                                    labelId="rules-logic-label"
                                    id="rules-logic"
                                    value={rulesLogic}
                                    onChange={(e)=>{setRulesLogic(e.target.value)}}
                                    disabled={compareMode}
                                >
                                    <MenuItem value={'and'}>AND</MenuItem>
                                    <MenuItem value={'or'}>OR</MenuItem>

                                </Select>

                            </FormControl>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {rules.map((rule, index,row) => (
                                            <Draggable key={index} draggableId={'rule' + index} index={index} isDragDisabled={index + 1 === row.length || compareMode}>
                                                {(provided, snapshot) => (
                                                    <div>
                                                    <div className="FunctionBigBotton" onClick={() => FunctionButtonClick('rule', index)}> <FunctionsIcon /></div>
                                                        <div className={`prop_textarea ${index + 1 === row.length ? "add_area" : compareMode ? "compare_add_area" : "" }`}
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}>
                                                            <AutocomplateTextarea
                                                                placeholder="+ Add Rule"
                                                                value = {rule.value}
                                                                style={snapshot.isDragging ? {border: '1px solid #cecece',width: '100%'+ 45 , resize: 'none' } : { width: '100%' , resize: 'none' }}
                                                                onType = { e => onAutoCompleteType(e, index) }
                                                                onChange={ e => onRulesTextChange(e, index)}
                                                                onAddType = { handleAddedType }
                                                                DataFields = {props.DataFields}
                                                                disabled={compareMode}
                                                            />
                                                            <DragIndicatorIcon  style={snapshot.isDragging || compareMode ? {display: 'none' } : { display : 'block' }} />
                                                            <div className="functionButton" onClick={() => FunctionButtonClick('rule', index)} style={snapshot.isDragging ? {display: 'none' } : { display : 'block' }}> <FunctionsIcon  /></div>
                                                            <div className="close_area" style={snapshot.isDragging || compareMode ? {display: 'none' } : { display : 'block' }}><CloseIcon onClick={() => RemoveArea(index)} /></div>
                                                        </div>

                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}
                                        <div style={{
                                            position: "absolute",
                                            top: placeholderProps.clientY,
                                            left: placeholderProps.clientX,
                                            //height: placeholderProps.clientHeight,
                                            //width: placeholderProps.clientWidth,
                                            width: '1px',
                                            height: '1px'
                                        }}/>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {(elementType === "Lookup") &&
                            <div className='hintLabel'>
                                <span className='hintLabelUse'>* Use @_. for access row data <span
                                    className='hintLabelExample'>example։ @_.id , @_.amount</span></span>
                            </div>
                        }
                        {(elementType === "Switch") &&
                            <div className='hintLabel'>
                                <span className='hintLabelUse'>* Leave empty for "else" case</span>
                            </div>
                        }
                    </div>

                }

                {(elementType === "Lookup") &&
                    <div>
                        <Divider />
                        <div className='lookup_wraper'>
                            <div className="paragraph">
                                <label className="paragraph_label">Lookup</label>
                            </div>
                            <div className='lookup_button'><IconButton color="primary" onClick={()=>{ LookupButtonClick() }} ><TableViewIcon /></IconButton></div>
                            <div className='lookup_size_label'>{lookupTableSize}</div>
                        </div>
                    </div>

                }

                {(elementType === "Input" || elementType === "Actions" || elementType === "Rule" || elementType === "Output" || elementType === "Lookup") &&
                    <div>
                        <Divider />
                        <div className="paragraph_container">
                            <label className="paragraph">Actions</label>
                        </div>
                        <DragDropContext onDragEnd={onDragEndActions} onDragUpdate={onDragUpdate}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {actions.map((action, index,row) => (
                                            <Draggable key={index} draggableId={'action' + index} index={index} isDragDisabled={index + 1 === row.length || compareMode}>
                                                {(provided, snapshot) => (
                                                    <div>
                                                    <div className="FunctionBigBotton" onClick={() => FunctionButtonClick('action', index)}> <FunctionsIcon /></div>
                                                    <div className={`prop_textarea ${index + 1 === row.length ? "add_area" : compareMode ? "compare_add_area" : "" }`}
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                         style={getItemStyle(
                                                             snapshot.isDragging,
                                                             provided.draggableProps.style
                                                         )}>

                                                            <AutocomplateTextarea
                                                                    placeholder="+ Add Action"
                                                                    value = {action.value}
                                                                    style={snapshot.isDragging ? {border: '1px solid #cecece',width: '100%'+ 45 , resize: 'none' } : { width: '100%' , resize: 'none' }}
                                                                    onType = { e => onAutoCompleteType(e, index) }
                                                                    onChange={e => onActionTextChange(e, index)}
                                                                    onAddType = { handleAddedType }
                                                                    DataFields = {props.DataFields}
                                                                    disabled={compareMode}
                                                            >
                                                            </AutocomplateTextarea>
                                                            <DragIndicatorIcon  style={snapshot.isDragging || compareMode ? {display: 'none' } : { display : 'block' }} />
                                                            <div className="functionButton" onClick={() => FunctionButtonClick('action', index)} style={snapshot.isDragging ? {display: 'none' } : { display : 'block' }}> <FunctionsIcon /></div>
                                                            <div className="close_area" style={snapshot.isDragging || compareMode ? {display: 'none' } : { display : 'block' }}><CloseIcon onClick={() => RemoveActionsArea(index)} /></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}


                                        {provided.placeholder}
                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}
                                        <div style={{
                                            position: "absolute",
                                            top: placeholderProps.clientY,
                                            left: placeholderProps.clientX,
                                            //height: placeholderProps.clientHeight,
                                            //width: placeholderProps.clientWidth,
                                            width: '1px',
                                            height: '1px'
                                        }}/>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {(elementType === "Lookup") &&
                            <div className='hintLabel'>
                                <span className='hintLabelUse'>* Use @@ to access filtered table data <span
                                    className='hintLabelExample'>example: @totalRows := count(@@) </span></span>
                                <span className='hintLabelUse'>* Use @_.  to access first row of filtered data <span
                                    className='hintLabelExample'>example: @_.percent or @_.rate </span></span>
                            </div>
                        }
                    </div>

                }
                {(elementType === "Link") &&
                    <div>
                        <Divider />
                        <div className="link">
                            <FormControl fullWidth>
                                <InputLabel id="link-style">Link</InputLabel>
                                <Select
                                    labelId="link-style"
                                    id="link-d"
                                    value={link || ""}
                                    onChange={(e)=>{
                                        setLink(e.target.value)
                                    }}
                                    disabled={compareMode}
                                >
                                    {linkArr.map((linkar,i) =>
                                        <MenuItem key={i} value={linkar.code || ''}> <LinkIcon /> {linkar.code || ''} - ( {linkar.title || ''} )</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            {link &&
                                <IconButton onClick={()=>{
                                    if (link && selectedLinkId) {
                                        window.open('/diagram/'+ link + "/" + selectedLinkId, '_blank') }}
                                }>
                                    <EditRoadIcon />
                                </IconButton>
                            }
                        </div>
                    </div>
                }


            </div>



            <Dialog PaperProps={{ className: 'FunctionPopup' }} onClose={FunctionPopupClose} open={FunctionPopupOpen} disableEnforceFocus>
                <div style={{position: 'releative'}}>
                    <DialogTitle>∑ Formula {FunctionTitle} (type: {FunctionType}) </DialogTitle>
                    <CloseIcon onClick={()=> { FunctionPopupClose() }} className='FunctionPopup_close_button'/>
                </div>
                <Divider />
                <div className="FunctionPopupContent">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '33ch',marginTop: '15px' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className={`toolbar-wrapper`} style={compareMode ? {display : 'none'} : null}>
                            <div className="toolbar-col">
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Պարամետր" data-tool="@">@</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Գումարած" data-tool="+">+</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Հանած" data-tool="-">-</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Բազմապատկած" data-tool="*">*</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Բաժանած" data-tool="/">/</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Տոկոս" data-tool="%">%</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Փակագծի բացում" data-tool="(">(</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Փակագծի փակում" data-tool=")">)</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Սոտորակետ" data-tool=",">,</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Չակերտ" data-tool="'">'</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Չակերտ" data-tool='"'>"</a>

                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" style={{width: '55px'}} title="True"
                                   data-tool='true'>True</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" style={{width: '55px'}} title="False"
                                   data-tool='false'>False</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" style={{width: '55px'}} title="Null"
                                   data-tool='null'>Null</a>
                            </div>
                            <div className="toolbar-col">
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Մեծ քան..." data-tool='>'>></a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Փոքր քան..." data-tool='<'>{`<`}

                                </a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Մեծ է կամ հավասար" data-tool='>='>>=</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Փոքր է կամ հավասար" data-tool='<='>
                                    {`<=`}</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Հավասար է" data-tool='='>=</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Հավասար չէ" data-tool='<>'>{`<>`}
                                </a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="և" data-tool='&&'>&&</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Կամ" data-tool='||'>||</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Ժխտում" data-tool='!'>!</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Bitwise And" data-tool='&'>&</a>
                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="Bitwise Or" data-tool='|'>|</a>

                                <a href="/" onClick={(e)=>{ToolbarButtonClick(e)}} className="toolbar-button" title="N-րդ էլեմենտ"  data-tool='[x]'>{`[x]`}</a>

                            </div>

                        </div>
                        <div className="FunctionWrapper">
                            <div className="FunctionContent">
                                <AutocomplateTextarea
                                    value = {FunctionValue}
                                    onType = { e => onAutoCompleteType(e) }
                                    onChange = {e =>  setFunctionValue(e)}
                                    onAddType = { handleAddedType }
                                    DataFields = {props.DataFields}
                                    disabled={compareMode}
                                />

                            </div>
                            <div className="FunctionFunctionList">
                                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    <div className="functionListHeader" >Function List</div>
                                    <Divider />
                                    <nav aria-label="main mailbox folders">
                                        <List>
                                            {FunctionsList.length > 0 ?
                                                FunctionsList.map((FunctionItem,index) =>
                                                <Tooltip key={index} title={FunctionItem.description} placement="left">
                                                <ListItem  disablePadding key={index}>
                                                    <ListItemButton disabled={compareMode} onDoubleClick={(e)=>{
                                                        FuncListDblClick(FunctionItem)
                                                    }}>
                                                        <ListItemIcon>
                                                            <FunctionsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={FunctionItem.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                                </Tooltip>
                                            )
                                                :
                                                <div>
                                                    {Array(10).fill(1).map((el, i) =>
                                                        <div key={i} style={{padding: '10px 10px 0px 10px'}}>
                                                            <Skeleton variant="text" />
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </List>
                                    </nav>
                                </Box>
                            </div>
                        </div>


                        <Button className="FunctionCancel_button" onClick={()=>{FunctionPopupClose()}}><CloseIcon className="icons_margin" /> Cancel</Button>
                        <Button className="FunctionOk_button" onClick={()=>{FunctionReturnResult()}}><DoneIcon className="icons_margin" /> OK</Button>
                    </Box>

                </div>
            </Dialog>




            <Dialog PaperProps={{ className: 'LookupPopup' }} className="LookupDialogWindow" onClose={LookupPopupClose} open={LookupPopupOpen}
                    PaperComponent={PaperComponent}
                    maxWidth={false}
                    disableEnforceFocus>


                    <ResizableBox
                        width={900} height={700}
                        minConstraints={[900, 700]}
                        className="LookupDialogWindow"
                    >
                        <div className="LookupWrapper">
                        <div style={{position: 'releative'}} >
                            <DialogTitle style={{ cursor: 'grab' }} id="lookup-dialog-title">Lookup Table - {LookupTitle} </DialogTitle>
                            <CloseIcon onClick={()=> { LookupPopupClose() }} className='FunctionPopup_close_button'/>
                        </div>
                        <Divider />
                        <div className="LookupPopupContent">
                            <Box
                                component="form"
                                sx={{ height: 'calc(100% - 90px)', width: '100%' }}
                                noValidate
                                autoComplete="off"
                            >

                                <div className='Lookup_Controls'>
                                    {!FixedDataExpression ?
                                        <>
                                        <span style={{ display: 'inline' , width: '100%' , alignSelf : 'center', maxWidth: 'fit-content'}}>Add New Column</span>
                                    <TextField
                                        className="colName"
                                        placeholder="Column Name"
                                        variant="standard"
                                        value={lookupColName}
                                        onChange={(e) => {setlookupColName(e.target.value)}}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        disabled={compareMode}
                                    />

                                    <div className='type'>
                                        <FormControl sx={{ m: 1 }}  size="small">
                                            <Select
                                                labelId="TypeSelect"
                                                id="select-type"
                                                value={lookupColType}
                                                onChange={(e)=>setlookupColType(e.target.value)}
                                                startAdornment={
                                                    <InputAdornment  position="start">
                                                        <AbcIcon />
                                                    </InputAdornment>
                                                }
                                                placeholder="Type"
                                                displayEmpty={true}
                                                disabled={compareMode}
                                                // defaultValue=""
                                                renderValue={value => value?.length ? value : 'Select Type...'}
                                            >
                                                <MenuItem value="Number"><NumberIcon/> Number</MenuItem>
                                                <MenuItem value="String"><TextIcon /> String</MenuItem>
                                                <MenuItem value="Date"><DateIcon /> Date</MenuItem>
                                                <MenuItem value="Boolean"><BooleanIcon /> Boolean</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>


                                    <LookupValidateStyle
                                        open={tooltipIsOpen}
                                        onOpen={() => setTooltipIsOpen(true)}
                                        onClose={() => {setTooltipIsOpen(false); setTooltipValidationText(''); }}
                                        title={tooltipValidationText}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 0 }}
                                        arrow
                                    >
                                    <Button className="addCol_button" disabled={compareMode} onClick={()=>{LookupAddColumn()}}><ColumnsIcon className="icons_margin" /> Add Column</Button>
                                    </LookupValidateStyle>
                                    <span style={{borderLeft: '1px solid #a8a3db'}}></span>
                                    <div>
                                        <Button className="addRow_button" disabled={compareMode} onClick={()=>{LookupAddRow()}}><RowsIcon className="icons_margin" /> Add Row</Button>
                                    </div>
                                    <div className="FilterButton">
                                        <Tooltip title="Search Ctrl+F">
                                            <IconButton onClick={(e) =>  filterPopupOpen(e)}> <SearchIcon /> </IconButton>
                                        </Tooltip>
                                    </div>
                                    </>
                                        :
                                        <>
                                        <div style={{alignSelf: 'center', fontSize: '15px', display: 'flex', width: 'calc(100% - 29px)'}} >
                                            <div style={{alignSelf: 'center', fontSize: '15px',width: '180px'}} >Data from expression</div>
                                            <TextField
                                                className="FixedDataExpPath"
                                                variant="standard"
                                                value={FixedDataExpression}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                disabled={compareMode}
                                            />
                                        </div>
                                        </>}

                                    <div style={{position: 'absolute', right: '0', top: '-4px', height: '0', display: compareMode ? 'none' : null }}>
                                        <div className="moreActions">
                                            <Tooltip title="More Actions">
                                                <IconButton onClick={(e) =>  moreActionsHandleClick(e)}> <MoreVertIcon /> </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <Menu
                                        anchorEl={moreActionsAnchorEl}
                                        id="moreAction-menu"
                                        open={moreActionsOpen}
                                        onClose={moreActionsHandleClose}
                                        onClick={moreActionsHandleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >

                           <MenuItem>

                        <span className="moreActionMenuItemText">
                            	<label htmlFor="upload_from_excel" id="upload-from-excel-label">
                         <ListItemIcon>
                            <SwipeDownAltIcon fontSize="small" />
                        </ListItemIcon>
                                    <span>Import From Excell</span>
                                </label>
	                            <input type="file" id="upload_from_excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                        </span>
                                        </MenuItem>


                         <MenuItem onClick={()=> exportToExcel(lookupRows)} disabled={lookupRows.length <= 0}>
                        <span className="moreActionMenuItemText">
                            	<label htmlFor="upload_to_excel" id="export-to-excel-label">
                         <ListItemIcon>
                            <SwipeUpAltIcon fontSize="small" />
                        </ListItemIcon>
                                    <span>Export to Excell</span>
                                </label>
	                            {/*<input type="file" id="upload_to_excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                                {/*/>*/}
                        </span>
                        </MenuItem>

                        <MenuItem onClick ={(e)=> {
                            e.preventDefault();
                            setFromInputDataAnchorEl(e.currentTarget)
                        }}>
                        <span className="moreActionMenuItemText">
                            	<label htmlFor="from_input_data" id="from_input_data_label">
                         <ListItemIcon>
                            <InputDataIcon fontSize="small" />
                        </ListItemIcon>
                                    <span>From Input data</span>
                                </label>
                        </span>
                        </MenuItem>

                          <MenuItem>

                        <span className="moreActionMenuItemText" onClick={()=>{setConfirmclearRowsDialog(true)}}>
                            	<label htmlFor="diagram-file-input" id="diagram-file-input-label">
                         <ListItemIcon>
                            <ClearAllIcon fontSize="small" />
                        </ListItemIcon>
                                    <span>Clear Rows</span>
                                </label>

                        </span>
                                        </MenuItem>

                                        <MenuItem onClick={()=>{setConfirmclearAllDialog(true)}}>
                                            <ListItemIcon>
                                                <RemoveRoadIcon fontSize="small" />
                                            </ListItemIcon>
                                            <span className="moreActionMenuItemText">Reset</span>
                                        </MenuItem>

                                    </Menu>

                                </div>
                                <DataGrid
                                    // ref = {ref}
                                    onCellEditCommit={onCellEditCommit}
                                    rows={lookupRows}
                                    columns={lookupColumns}
                                    density='compact'
                                    pagination
                                    pageSize={pageSize}
                                    hideFooterSelectedRowCount={true}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[25, 50, 100]}
                                    localeText={{
                                        toolbarColumns: "Columns",
                                        // toolbarFilters: "my filters",
                                    }}
                                    components={{
                                        ColumnMenu: CustomColumnMenu,
                                        Toolbar: CustomToolbar,
                                        // Pagination: () => <div>vvvvvv </div>,
                                        NoRowsOverlay: (FixedDataExpression && !inputDataExpRegexMatch(FixedDataExpression)) ? UnableToSHowOverlay : CustomNoRowsOverlay ,
                                    }}

                                />
                            </Box>
                            <div className='control_buttons'>
                                <Button className="LookupCancel_button" onClick={()=>{LookupPopupClose()}}><CloseIcon className="icons_margin" /> Cancel</Button>
                                <Button className="LookupOk_button" onClick={()=>{LookupReturnResult()}}><DoneIcon className="icons_margin" /> OK</Button>
                            </div>

                        </div>
                        </div>
                    </ResizableBox>
            </Dialog>

            <Menu
                id="from-input-data-context"
                className={`MuiPaper-root MuiPaper-elevation ${ExpErr ? 'expErr' : '' }`}
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={document.getElementById('root')}
                open={Boolean(FromInputDataAnchorEl)}
                onClose={() => FromInputDatahandleClose()}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >

                <LookupValidateStyle
                    open={ExpErr}
                    // onOpen={() => setExpErr(true)}
                    // onClose={() => {setExpErr(false); setTooltipValidationText(''); }}
                    title="Invalid data format at specific path"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 0 }}
                    arrow
                >



                <div className='form_input_data_Controls' >
                    <AutocomplateTextarea
                        placeholder="Input Data Expression"
                        style={{border: '1px solid #cecece',width: '100%'+ 45 , resize: 'none' }}
                        value = {DataExpression}
                        onType = { e => onAutoCompleteType(e) }
                        onChange = {e =>  {
                            setExpErr(false);
                            setDataExpression(e);
                            }
                        }
                        // onAddType = { handleAddedType }
                        DataFields = {props.DataFields}
                    />

                    <div className="form_input_data_Actions">
                        <IconButton className="OkIcon" onClick={() => {
                          LoadFromDebugValue();
                        }}><OkIcon /></IconButton>
                        <IconButton className="CancelIcon" onClick={() => {
                            if (ExpErr) {
                                setDataExpression('');
                                setExpErr(false);
                            }
                            setFromInputDataAnchorEl( null )}
                        }><CancelIcon /></IconButton>
                    </div>
                </div>

                </LookupValidateStyle>
            </Menu>

            <Portal>
                <ConfirmDialog open={confirmclearAllDialog} onClose={(e) => { confirmclearAllDialogClose(e)  }}
                               title="Warning"
                               text= {["Are you sure you want to", <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, "clear All data?"]}
                />
                <ConfirmDialog open={confirmclearRowsDialog} onClose={(e) => { confirmclearRowsDialogClose(e)  }}
                               title="Warning"
                               text= {["Are you sure you want", <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, "to clear Rows?"]}
                />


                <ConfirmDialog open={confirmRowDeleteDialog} onClose={(e) => { confirmRowDeleteDialogClose(e)  }}
                               title="Warning"
                               text= {["Are you sure you want", <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, "delete row?"]}
                />

                <ConfirmDialog open={confirmColumnDeleteDialog} onClose={(e) => { confirmColumnDeleteDialogClose(e)  }}
                               title="Warning"
                               text= {["Are you sure you want", <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, `delete column "${deletableCurrentColumn ? deletableCurrentColumn.headerName : ""}" (type - ${deletableCurrentColumn ? deletableCurrentColumn.type : ""}) ?`]}
                />
            <Snackbar open={infoShow} autoHideDuration={10000} style={{zIndex : "999999 !important"}} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {
                        infoMessage.split("|").map((message,index) =>
                            <span style={{display: 'block'}} key={index}>{infoMessage.split("|").length > 1 ? index + " - ": ''} {message} </span>
                        )
                    }
                </Alert>
            </Snackbar>
            </Portal>
        </Box>
    );


    return (
        <div>
            {loading &&
                <Loading fullscreen />
            }
            <React.Fragment key='properties'>
                <Drawer
                    disableEnforceFocus
                    style={{zIndex: '4 important'}}
                    anchor='right'
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                    //${compareMode ? 'compare-properties' : ''}
                    className={`Properties ${compareMode ? 'compare-properties' : ''} ${!compareProperties? 'right-propertys' : ''} `}
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}