import * as joint from 'jointjs';
import {  shapes, linkTools } from 'jointjs';
import { lineAttributes,
    ShapeTypes,
} from './diagram.common';


window.joint = joint;


let RectangleSourceArrowhead = linkTools.SourceArrowhead.extend({
    tagName: 'rect',
    attributes: {
        'x': -5,
        'y': -5,
        'width': 10,
        'height': 10,
        'fill': '#26c30f',
        'fill-opacity': 0.3,
        'stroke': '#26c30f',
        'stroke-width': 1,
        'cursor': 'move',
        'class': 'target-arrowhead'
    }
});

let CircleTargetArrowhead = linkTools.TargetArrowhead.extend({
    tagName: 'circle',
    attributes: {
        'x': -15,
        'y': -15,
        'r': 10,
        'fill': '#26c30f',
        'fill-opacity': 0.3,
        'stroke': '#26c30f',
        'stroke-width': 1,
        'cursor': 'move',
        'class': 'target-arrowhead'
    }
});

const Link = shapes.standard.Link.define(ShapeTypes.LINK, {
    attrs: {
        line: {
            ...lineAttributes
        }
    },
    z: 2
}, {
    getTools() {
        return [
            new linkTools.Vertices(),
            new linkTools.Remove(),
            new RectangleSourceArrowhead(),
            new CircleTargetArrowhead()
        ];
    }
});

export {Link}