import * as React from 'react';
import "../Compare/Compare.css"
import '../../utils/CompareTool/compare_tool.css'
import {useParams} from "react-router-dom";
import {useEffect,useState} from "react";
import Diagram from "../Diagram/impl/diagram";
import Request from "../../utils/request";
import Loading from "../Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import {Alert, Slide, styled, Tooltip, tooltipClasses} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import $ from 'jquery';
import CompareIcon from '@mui/icons-material/Compare';
import MaximizeIcon from '@mui/icons-material/WebAsset';
import MinimizeIcon from '@mui/icons-material/Minimize';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowIcon from '@mui/icons-material/TrendingFlatOutlined';
import DraggableRct from "react-draggable";
import Properties from "../Properties/Properties";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import {getSelectedCompanyCfg, getToken, setDRBadgeCount} from "../../utils/Common";
import {useCallHolder} from "react-callforward";
import CommentBox from "../CommentBox/CommentBox";
import compareModels from "../../utils/CompareTool/compare_models";
import {Rnd} from "react-rnd";
import Fade from "@mui/material/Fade";



const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}  />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        top: '9px',
        backgroundColor: '#FFFFFF',
        color: '#676f7a',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: '12px',
        boxShadow: '5px 5px 10px 0px rgb(171 171 171 / 63%)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    }
}));


let diagram = null;
let curSourceData = null;
let curTargetData = null;
let curRequestCheckSum = null;

const token = getToken();
let company = 0;
if (token){
    const userInfo = jwt_decode(getToken());
    if (getSelectedCompanyCfg()) company = getSelectedCompanyCfg()[userInfo.sub];
}

function CompareTrees(props) {

   // const userInfo = jwt_decode(getToken());
    const { sourceId , targetId ,drId } = useParams();

    const [loading, setLoading] = useState(false);
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');

    const [SourceData, setSourceData] = useState(null);
    const [TargetData, setTargetData] = useState(null);

    const [dataState, setDataState] = useState('source');

    const [minimize, setMinimize] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });

    const [OpenProperties, SetOpenProperties] = useState(false);
    const [DiagtamItemProperties, SetDiagtamItemProperties] = useState({ rules: { items: [] }, actions: [], table: { columns: [], rows: [] , dataExpression: null} });

    const [DeployManageAction, setDeployManageAction] = useState(null);

    const [commentBox, setCommentBox] = useState(false);
    const [CommentTxt, setCommentTxt] = useState('');

    const [changesCount , setChangesCount] = useState(0)
    const [nodConsoleClicked , setNodConsoleClocked] = useState(null);


    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }



    const getDRBadgeCount = () => {
        if (company !==0){
            Request(window._env_.REACT_APP_API_TREE_LIST + 'DeployRequest/Unread?companyId=' + company).get()
                .then((data) => {
                        if (data) {
                            // setDrBadgeCount(drBadgeCount + data.result+1)
                            setDRBadgeCount(data.result)
                        } else {
                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error')
                            }
                            if (data.message){
                                ShowInfo(data.message, 'warning')
                            }
                        }
                    }
                ).catch(() => {
                console.log('Cannot Load Deploy Request Badge Count')
            })

        }
    }

    const handleInfoClose = (event, reason) => {
        //console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const onCommentBoxClose = () => {
        setCommentBox(false);
    };

    const CommentText = (result) => {
        if (result){
            setCommentTxt(result);
        }
        setCommentBox(false);

    }



    useEffect(() => {
        $('.MuiDrawer-root.MuiDrawer-modal.Properties').css('display', OpenProperties ? 'block' : 'none');
    },[OpenProperties])


    useEffect(() => {
            $('.compare-console').css('width', fullscreen ? 'unset' : '100%').css('display', minimize ? 'none' : 'block');
           // $('.compare-console-box').css('height', minimize ? 'unset' : 'calc(100% - 53px)');
            $('.App').css('min-width', fullscreen ? '1000px' : '395px');
    },[minimize,fullscreen])


    useEffect(() => {

       // console.log (userInfo.security_claim)

        // eslint-disable-next-line
           diagram = new Diagram('paper');
           diagram.init();

        if (sourceId && targetId){
            setLoading(true);
            let srcID = sourceId > 0 ? sourceId : drId;
            let uri = sourceId > 0 ? 'Tree/byid' : 'deployrequest';
            Request(window._env_.REACT_APP_API_TREE_LIST + uri +'/' + srcID).get()
                .then((data) => {
                    setLoading(false);
                    if (data.errors){
                        ShowInfo("Source - " + data.errors[0].message, 'error')
                    } else if (data.message){
                        ShowInfo("Source - " + data.message, 'error')
                    } else {
                        if (data) {
                            let MyData = null;
                            if (sourceId > 0) {
                                MyData = data;
                            }
                            else
                            {
                                MyData = data.sourceTree;
                                MyData.title = data.title;
                            }

                            curRequestCheckSum = data.checksum;
                            setSourceData(MyData);
                            curSourceData = MyData;
                            diagram.fromJson(MyData.displayData);
                            diagram.triggerOnChange({target:'diagram', event: 'load'});
                            props.DeployManageActionsStatus(data.status);
                            if (targetId > 0) {
                                // TARGET REQUEST PART
                                Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/byid/' + targetId).get()
                                    .then((data) => {
                                        setLoading(false);
                                        if (data.errors){
                                            ShowInfo("Target - " + data.errors[0].message, 'error')
                                        } else if (data.message){
                                            ShowInfo("Target - " + data.message, 'error')
                                        } else {
                                            if (data) {
                                                setTargetData(data);
                                                curTargetData = data;
                                            }
                                        }

                                    }).catch(() => {
                                    setLoading(false);
                                    ShowInfo('Cannot Load Target Data', 'error')
                                })
                                // TARGET REQUEST PART END
                            }
                        }
                    }

                }).catch(() => {
                setLoading(false);
                ShowInfo('Cannot Load Source Data', 'error')
            })
        }

        diagram.onZoom = function(e) {
            $('#zoom-text').text(Math.round(e.scale * 100) + '%');
        };
        $('#zoombox').on('click', '.zoom-btn', function(e) {
            e.preventDefault();
            let action = $(this).data("action");
            if (action === "zoomin") {
                diagram.zoom(1);
            } else if (action === "zoomout") {
                diagram.zoom(-1);
            } else if (action === "scalefit") {
                diagram.scaleContentToFit({ padding: { top: 20, right: 50, bottom: 70, left: 20 } });
            }

        });





        $('#compare_btn').on('mousedown', '.compare_button', function(e) {
            e.preventDefault();
            SetOpenProperties(false);
            let action = $(this).data("action");
            if (action === "compare") {
                setDataState('target');
                diagram.fromJson(curTargetData.displayData);
                // diagram.triggerOnChange({target:'diagram', event: 'load'});
                setDragStartPos({ x: 0, y: 0 });
            }
        }).on('mouseup', '.compare_button', function(e) {
            e.preventDefault();
            setDataState('source');
            diagram.fromJson(curSourceData.displayData);
                // diagram.triggerOnChange({target:'diagram', event: 'load'});
        })



        diagram.onElementDblClick = function(cellview, evt, x, y) {
            let dataModel = $.extend(true, {},  cellview.model.prop('dm'));
            SetDiagtamItemProperties(dataModel);
            SetOpenProperties(true);
            diagram.unhighlightAll();
        };
        // eslint-disable-next-line
    },[sourceId , targetId]);



    let DRActionModel = null;

    useCallHolder((e) => {
        setDeployManageAction(e);
        setCommentBox(true);
        setCommentTxt('');
    }, props.DeployManageActionsClicked);

    useEffect(()=>{
        // eslint-disable-next-line
         DRActionModel = {
            "id" : drId,
            "status" : DeployManageAction,
            "checksum" : curRequestCheckSum,
            "folderID" : 0,
            "notes" : CommentTxt
        }
    },[sourceId,DeployManageAction,curSourceData,CommentTxt])

    useEffect(()=>{
        if (CommentTxt) {
            setLoading(true);
            Request(window._env_.REACT_APP_API_TREE_LIST + 'deployrequest').patch(DRActionModel)
                .then((data) => {
                    setLoading(false);
                    if (data) {
                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error');
                            return false;
                        }
                        if (data.message){
                            ShowInfo(data.message, 'warning');
                            return false;
                        }
                    }
                    ShowInfo('Successfuly ' + DeployManageAction, 'success');
                    props.DeployManageActionsStatus(null);
                    getDRBadgeCount();
                }).catch(() => {
                ShowInfo(DeployManageAction + ' Problem', 'warning');
                setLoading(false);
            })

        }
        // eslint-disable-next-line
    },[CommentTxt])


    // eslint-disable-next-line
    const FullScreen_Click = () => {
        setFullscreen(!fullscreen);
    }

    const Minimize_Click = () => {
        setMinimize(!minimize);
    }

    const compare_but_lock_click = () => {
        SetOpenProperties(false);
        setDataState("source")
        diagram.fromJson(curSourceData.displayData);
    }

    const ReturnedProperties = (e) => {
       // console.log('ReturnedProperties', e);
        diagram.unhighlightAll();
        e.rules.items = e.rules.items.filter(item => item.value && item.value !=='' && item.value !==null );
        e.actions = e.actions.filter(action => action.value && action.value !=='' && action.value !==null );
        let cell = diagram.getCell(e.id);
        if ( !_.isEqual(cell.prop('dm'), e)) {
            cell.attributes.dm = e;
            cell.prop({ dm : e });
            cell.attr('label/text',e.Title);
            setTimeout(function (){
                diagram.triggerOnChange({target:'node', event: 'node data change'});
            },0);
        }
    }



    $('.compare-node').bind('click',(e)=>{
        e.preventDefault();
        e.stopPropagation();
        setNodConsoleClocked(e);
    })

    useEffect(() => {
        if (nodConsoleClicked){
            let node = diagram.getCell(nodConsoleClicked.target.dataset.nodeid);
            if (node){
                let dataModel = $.extend(true, {},  node.prop('dm'));
                SetDiagtamItemProperties(dataModel);
                SetOpenProperties(true);
                diagram.showSearch([nodConsoleClicked.target.dataset.nodeid], 'search')
            }
        }
    }, [nodConsoleClicked]);



    useEffect(()=>{
        if (SourceData !== null  && TargetData !== null){
            let gg = compareModels(TargetData.model, SourceData.model , false);
            if (gg) {
                setChangesCount(gg.length);
                let container = $('.compare-console')
                gg.forEach(element => {
                     container.append('<div>' + element + '</div>');
                });
            }
        }
    },[SourceData,TargetData])




  // const style = {
  //     left: "auto",
  //     right: "10px",
  //     bottom: "10px",
  //     top: "auto",
  // };

    // let initialHeight = window.innerHeight - 275;
    // let initialWith = 300;
    // const [RND, SetRND] = useState({ width: initialWith, height: initialHeight });
    // const [position, SetPosition] = useState({ x: 10, y: 10 });


    let initialHeight = window.innerHeight - 265;
    let initialWith = 300;
    const [RND, SetRND] = useState({ width: initialWith, height: initialHeight });
    const [position, SetPosition] = useState({ x: 10, y: 65 });


    const changeSize = (e, direction, ref, delta, position) => {
        SetRND({
            width: ref.style.width,
            height: ref.style.height,
        });
        SetPosition(position)
    };


    return (

        <div>
            {loading &&
                <Loading fullscreen />
            }
            <div id="compare-document-wrapper">
                <div id="compare-paper-wrapper">
                    <div id="paper" />
                    <div id='diagramFooter' className='diagramFooter'>
                        <div style={{display : "flex"}}>
                                <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={SourceData && TargetData ? dataState === 'source' ? SourceData.checksum : TargetData.checksum : 'Not available'} >
                                    <div>
                                        <span className='footerLabel'>Checksum:</span>
                                        <span className='footerText'>{SourceData && TargetData ? dataState === 'source' ? SourceData.checksum : TargetData.checksum : 'Not available'}</span>
                                    </div>
                                </HtmlTooltip>
                            <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={SourceData && TargetData ? dataState === 'source' ? SourceData.code : TargetData.code : 'Not available'} >
                                    <div style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                        <span className='footerLabel'>Code:</span>
                                        <span className='footerText'>{SourceData && TargetData ? dataState === 'source' ? SourceData.code : TargetData.code : 'Not available'}</span>
                                    </div>
                            </HtmlTooltip>


                            <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={SourceData && TargetData ? dataState === 'source' ? SourceData.title : TargetData.title : 'Not available'} >
                                <div style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                    <span className='footerLabel'>Title:</span>
                                    <span className='footerText'>{SourceData && TargetData ? dataState === 'source' ? SourceData.title : TargetData.title : 'Not available'}</span>
                                </div>
                            </HtmlTooltip>

                            <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={SourceData && TargetData ? dataState === 'source' ? SourceData.modifiedBy : TargetData.modifiedBy : 'Not available'} >
                                <div style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                    <span className='footerLabel'>Modified By:</span>
                                    <span className='footerText'>{SourceData && TargetData ? dataState === 'source' ? SourceData.modifiedBy : TargetData.modifiedBy : 'Not available'}</span>
                                </div>
                            </HtmlTooltip>
                        </div>
                    </div>
                </div>
                <div id="zoombox">
                    <a href='/#' className="zoom-btn plus" data-action="zoomin"><AddIcon /></a>
                    <span id="zoom-text">100%</span>
                    <a href='/#' className="zoom-btn minus" data-action="zoomout"><RemoveIcon /></a>
                    <a href='/#' className="zoom-btn reset" data-action="scalefit"><FullscreenIcon /></a>
                </div>


                    <div style={{display: !TargetData ? 'none' : 'block'}}>
                        <div id="current-diagram">
                            {SourceData && TargetData ?
                                dataState === 'source' ? drId ? "Changed" : SourceData.code + " - Ver. " + SourceData.abVersion : drId ? "Current" : TargetData.code + " - Ver. " + TargetData.version
                                : 'Loading....'}
                        </div>

                        <DraggableRct
                            axis='x'
                            bounds={{left: 0, top: 0, right: 64, bottom: 0}}
                            defaultPosition={{x: 0, y: 0}}
                            position={dragStartPos}
                            handle="#compare_btn"
                            cancel=".compare_but_lock">

                            <Tooltip title="Compare" className="ToolTipStyle"  arrow placement="top">
                                <div id="compare_btn" className={dataState === 'source' ? "" : "clicked" }>
                                    <span href='/#' className="compare_button" data-action="compare"><CompareIcon /></span>
                                </div>
                            </Tooltip>
                        </DraggableRct>

                        <div className="compare_arrow">
                            <ArrowIcon />
                        </div>

                        <div className="compare_but_lock">
                            <span onClick={()=>{compare_but_lock_click()}}><LockOutlinedIcon /></span>
                        </div>
                    </div>




                    <div className='rightSide' style={{zIndex: 0}}>
                        <Properties
                            compareMode = {true}
                            compareProperties = {!!curTargetData}
                            OpenProperties={OpenProperties}
                            OpenPropertiesClose={SetOpenProperties}
                            DiagtamItemProperties={DiagtamItemProperties}
                            ReturnedProperties={ReturnedProperties}
                        />


                    </div>


                <div style={{ position: "absolute", top: 0, left: 0 ,zIndex: '9999999 !important'}}>

                    <Rnd

                        // default={{x:200 , y: 0}}
                        position={position}
                        dragHandleClassName='compare-title-box'
                        cancel='#compare-document-wrapper'
                        bounds='#paper'
                        size={{ width: RND.width, height: RND.height }}
                        onResizeStop={changeSize}
                        className="compare-console-box"
                        maxHeight={!minimize ? initialHeight : 0}
                        // maxWidth={'100%'}
                        onDragStop={(e, d) => {
                            SetPosition({ x: d.x, y: d.y });
                        }}
                    >

                        <div className="compare-title-box">
                            <span className="compare-title" style={{marginRight : 'auto'}}>Change List</span>
                            <span className="compare-controls" onClick={()=>{Minimize_Click()}}>{ minimize ? <MaximizeIcon/> : <MinimizeIcon /> }</span>
                            {/*<span className="compare-controls" onClick={()=>{FullScreen_Click()}}>{ fullscreen ? <CloseFullscreenIcon/> : <OpenInFullIcon /> } </span>*/}
                        </div>
                        <div id='compare-box' className="compare-console">
                        </div>
                        <div className="compare-footer-box">
                            <span> Found </span> <span className="diff_cnt">{changesCount}</span> <span>difference</span>
                        </div>
                    </Rnd>
                </div>
                <CommentBox
                    open={commentBox}
                    required
                    requiredText= {`Comment for ${DeployManageAction} is Required`}
                    onClose={(e) => { onCommentBoxClose(e)}}
                    CommentText ={(e) => { CommentText(e)}}
                />

                <Snackbar open={infoShow}  onClose={handleInfoClose}
                          TransitionComponent={Fade}
                          key={SlideTransition.name}
                >
                    <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                        {
                            infoMessage.split("|").map((message,index) =>
                                <span style={{display: 'block'}} key={index}>{infoMessage.split("|").length > 1 ? index + " - ": ''} {message} </span>
                            )
                        }
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}

export default CompareTrees;