import * as joint from 'jointjs';
import {  linkTools, connectors, g , elementTools } from 'jointjs';
import {
    connectToolAttributes,
    labelAttributes,
    ShapeTypes,
    bodyAttributes,
} from './diagram.common';
import {BaseShape} from './diagram.shape'

const {  Ellipse } = g;
const { TangentDirections } = connectors.curve;

window.joint = joint;

function toRad(deg, over360) {

    over360 = over360 || false;
    deg = over360 ? deg : (deg % 360);
    return deg * Math.PI / 180;
};

const EllipseShape = BaseShape.define(ShapeTypes.ELLIPSE, {
    size: { width: 70, height: 70 },
    attrs: {
        root: {
            highlighterSelector: 'body'
        },
        body: {
            cx: 'calc(.5*w)',
            cy: 'calc(.5*h)',
            rx: 'calc(.5*w)',
            ry: 'calc(.5*h)',
            ...bodyAttributes.Default
        },
        label: {
            text: 'Ellipse',
            ...labelAttributes.Default
        }
    }
}, {
    markup: [{
        tagName: 'ellipse',
        selector: 'body'
    }, {
        tagName: 'text',
        selector: 'label'
    }],

    getConnectToolMarkup() {
        const { width, height } = this.size();
        return [{
            tagName: 'ellipse',
            attributes: {
                'rx': width / 2,
                'ry': height / 2,
                'cx': width / 2,
                'cy': height / 2,
                ...connectToolAttributes
            }
        }];
    },

    getCurveDirection() {
        return TangentDirections.OUTWARDS;
    },

    getClosestBoundaryPoint(bbox, point) {
        const ellipse = Ellipse.fromRect(bbox);
        return ellipse.intersectionWithLineFromCenterToPoint(point);
    },

    getTools() {
        var bbox = this.getBBox();
        var ellipseRadius = (1 - Math.cos(toRad(45)));
        var offset = this.attr(['pointers', 'pointerShape']) === 'ellipse'
            ? { x: -ellipseRadius * bbox.width / 2, y: ellipseRadius * bbox.height / 2 }
            : { x: -10, y: 10 };

        return [
            new linkTools.Connect({
                focusOpacity: 0,
                markup: this.getConnectToolMarkup()
            }),
            new elementTools.Remove({
                useModelGeometry: true,
                y: '0%',
                x: '100%',
                offset: offset
            })
        ];
    }

});

export {EllipseShape}