import * as joint from 'jointjs';
import { dia, connectors } from 'jointjs';
import {Link} from './diagram.link';
import {RhombusShape} from './diagram.rhombus';
import {EllipseShape} from './diagram.ellipse';
import {RectangleShape}from './diagram.rectangle';

import $ from 'jquery';
import {
    highlighterAttributes,
    ShapeTypes,
    bodyAttributes, ToolTypes, labelAttributes,
} from './diagram.common';
import {SelectionShape} from "./diagram.selection";
const { TangentDirections } = connectors.curve;

window.joint = joint;

function Diagram(canvasElementID) {

    this.namespace = joint.shapes;
    this.graph = null;
    this.paper = null;

    let that = this;

    let historyCurrentIndex = -1;
    let history = [];
    const historyMaxCount = 20;

    let element = document.getElementById(canvasElementID);

    this.onZoom = null;

    this.DiagramIsChange = null;

    this.onElementDblClick = null;

    this.onChange = function (e) {
        // console.log("diagram.onChange", e);
        // console.log("diagram.onChange histindex", historyCurrentIndex);

        if (historyCurrentIndex>-1) {
            history = history.slice(0, historyCurrentIndex+1);
        }
        if (e.event === 'load') {
            historyCurrentIndex = -1;
            history = [];
        }
        if (history.length > historyMaxCount) {
            history.shift();
        }
        history.push({ data: that.toJson(), action: e.event });
        historyCurrentIndex = history.length - 1;
        if (this.DiagramIsChange) {
            this.DiagramIsChange(historyCurrentIndex > 0);
        }


    };

    document.getElementById("paper").addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });


    this.init = function() {

        let clonningNode = false;
        let clonedNode = null;
        let cloningOffset = null;

        let lineMode = false;
        // eslint-disable-next-line
        let currentLine = null;


        that.graph = new dia.Graph({}, { cellNamespace: that.namespace });
    
        that.paper = new dia.Paper({
            el: element,
            width: '100%',
            height: '100%',
            model: that.graph,
            cellViewNamespace: that.namespace,
            background: { color: '#e9edf5' },
            
            clickThreshold: 5,
            /*
            gridSize: 1,
            drawGrid: false,
            */
            gridSize: 20,
            drawGrid: {
                name: 'doubleMesh',
                args: [
                        { color: '#d7e0f5', thickness: 1 }, // settings for the primary mesh
                        { color: '#d7e0f5', scaleFactor: 5, thickness: 2 } //settings for the secondary mesh
                ]
            },
            async: true,
            sorting: dia.Paper.sorting.APPROX,
            snapLinks: true,
            highlighting: {
                default: {
                    name: 'mask',
                    options: {
                        attrs: {
                            ...highlighterAttributes
                        }
                    }
                }
            },
            interactive: function(cellView, method) {
                // console.log("inter", arguments);
                return !lineMode && !clonningNode; // Only allow interaction with joint.dia.LinkView instances.
            },
            defaultConnectionPoint: { name: 'anchor' },
            connectionStrategy: function(end, view, _, coords) {
                const { x, y } = view.model.getBoundaryPoint(coords);
                end.anchor = {
                    name: 'topLeft',
                    args: {
                        dx: x,
                        dy: y,
                        rotate: true
                    }
                };
            },
            defaultConnector: function(sourcePoint, targetPoint, route, _, linkView) {
                const { model: link } = linkView;
                const targetElement = link.getTargetElement();
                const sourceElement = link.getSourceElement();
                const options = {
                    targetDirection: targetElement ? targetElement.getCurveDirection(targetPoint) : TangentDirections.AUTO,
                    sourceDirection:  sourceElement ? sourceElement.getCurveDirection(sourcePoint) : TangentDirections.AUTO,
                };
                return connectors.curve(sourcePoint, targetPoint, route, options, linkView);
            },
            defaultLink: () => new Link()
        });

        var pointerX;
        var pointerY;

        var changedLink = false;
        var changedLinkPath = false;
        var lasso = null;
        var lassoInitial = null;
        var lassoSelection = null;
        var selectionElement = null;
        var selectionCells = [];
        var selectionDragging = false;
        var selectionDeleting = false;
        var copiedCells = {
            items: [],
            targetPos: {
                x: 0,
                y: 0
            },
            boundary: {
                x: 0,
                y: 0
            }
        };

        let downX = 0;
        let downY = 0;
        let downOnElement = false;

        that.onElementClick = function(cellView, evt, x, y) {
            if (!evt.ctrlKey) {
                that.deselect();
            }

            downOnElement = false;
            var bbox = cellView.getBBox({ useModelGeometry : true });
            bbox = that.paper.paperToLocalRect(bbox);

          var  bboxOffsetX = bbox.x;
          var  bboxOffsetY = bbox.y;
            if (selectionElement == null)
            {
                selectionElement = that.newNode(bbox.x, bbox.y, 'selection', null, bbox.width,bbox.height);
                that.addElement(selectionElement);
                selectionElement.toFront();
                selectionCells = [cellView];
            }
            else
            {
                var selectionView = selectionElement.findView(that.paper);
                var expandedBox = selectionView.getBBox({ useModelGeometry : true });
                expandedBox = that.paper.paperToLocalRect(expandedBox);
                expandedBox.x1 = expandedBox.x;
                expandedBox.y1 = expandedBox.y;
                expandedBox.x2 = expandedBox.x1 + expandedBox.width;
                expandedBox.y2 = expandedBox.y1 + expandedBox.height;
                expandedBox.x1 = Math.min(expandedBox.x1, bbox.x);
                expandedBox.y1 = Math.min(expandedBox.y1, bbox.y);
                expandedBox.x2 = Math.max(expandedBox.x2, bbox.x + bbox.width);
                expandedBox.y2 = Math.max(expandedBox.y2, bbox.y + bbox.height);

                selectionElement.remove();
                selectionElement = null;

                selectionElement = that.newNode(expandedBox.x1, expandedBox.y1, 'selection', null, expandedBox.x2 - expandedBox.x1, expandedBox.y2 - expandedBox.y1);
                that.addElement(selectionElement);
                selectionElement.toFront();

                selectionCells.push(cellView);

                bboxOffsetX = expandedBox.x1;
                bboxOffsetY = expandedBox.y1;
            }

            selectionCells.forEach(view => {
                var pos = view.model.position();
                view.model.prop('soffset', {
                    x: pos.x - bboxOffsetX,
                    y: pos.y - bboxOffsetY
                });
            });
        };

        that.elementDblClick = function(cellview, evt, x, y) {
            if (that.onElementDblClick) {
                var el = cellview;
                if (cellview.model.attributes.type === ShapeTypes.SELECTION) {
                    if (selectionCells === null || selectionCells.length>1) {
                        return;
                    }
                    el = selectionCells[0];
                }

                that.onElementDblClick(el, evt, x, y)
            }
        };

        that.deselect = function () {
            if (selectionElement !== null) {
                selectionElement.remove();
                selectionElement = null;
            }
            if (lasso!==null) {
                lasso.remove();
                lasso = null;
            }

            lassoInitial = null;
            lassoSelection = null;
            selectionCells = [];
            selectionDragging = false;
        };

        that.deleteSelected = function () {
            if (selectionElement === null) {
                return;
            }
            selectionDeleting = true;
            for(let idx = 0; idx< selectionCells.length ; idx ++ ) {
                let links = that.graph.getConnectedLinks(selectionCells[idx].model);
                selectionCells[idx].model.remove();
                if (links && links.length>0) {
                    for(let j = 0; j < links.length ; j ++) {
                        links[j].remove();
                    }
                }
                // 
            }
            that.deselect();
            that.onChange({target:'diagram', event: 'Selection delete'});
            selectionDeleting = false;
        };

        that.copySelection = function() {
            if (selectionElement === null) {
                return;
            }

            var ids = selectionCells.map( o => o.model.id);
            copiedCells.items = [];
            copiedCells.boundary.x = 100000000;
            copiedCells.boundary.y = 100000000;

            for(let idx = 0; idx< selectionCells.length ; idx ++ ) {
                let links = that.graph.getConnectedLinks(selectionCells[idx].model, { inbound : true });
                if (links && links.length>0) {
                    for(let j = 0; j < links.length ; j ++) {
                        let sourceCell = links[j].getSourceCell();
                        if (sourceCell != null && ids.indexOf(sourceCell.id)>-1) {
                            copiedCells.items.push(links[j]);
                        }
                    }
                }
                //var bbox = selectionCells[idx].getBBox({ useModelGeometry : true });
                //bbox = that.paper.paperToLocalRect(bbox);

                var copyNode = selectionCells[idx].model;
                var pos = copyNode.position();

                copiedCells.items.push(copyNode);
                copiedCells.boundary.x = Math.min(copiedCells.boundary.x, pos.x);
                copiedCells.boundary.y = Math.min(copiedCells.boundary.y, pos.y);
            }
            console.log('copied ',copiedCells);
        };

        that.pasteSelection = function() {
            if (copiedCells.items.length === 0) {
                return;
            }
            that.deselect();

            let targetPoint = that.paper.clientToLocalPoint(copiedCells.targetPos.x, copiedCells.targetPos.y);
            let clonedNodes = that.graph.cloneCells(copiedCells.items);
            var boundingBox = {
                x1: 100000000,
                y1: 100000000,
                x2: 0,
                y2: 0
            };
            var views = [];
            for (var oldId in clonedNodes) {
                if (clonedNodes.hasOwnProperty(oldId)) {
                    var newModel = clonedNodes[oldId];
                    if (newModel.isLink()) {
                        var sourceEl = newModel.getSourceElement();
                        var targetEl = newModel.getTargetElement();
                        if (sourceEl && clonedNodes.hasOwnProperty(sourceEl.id)) {
                            var newSource = clonedNodes[sourceEl.id];
                            newModel.source(newSource);
                        }
                        if (targetEl && clonedNodes.hasOwnProperty(targetEl.id)) {
                            var newTaget = clonedNodes[targetEl.id];
                            newModel.target(newTaget);
                        }
                    }

                    newModel.translate(targetPoint.x - copiedCells.boundary.x, targetPoint.y - copiedCells.boundary.y);
                    that.graph.addCell(newModel);
                    if (!newModel.isLink()) {
                        let dm = $.extend(true , {}, newModel.prop('dm'));
                        dm.id = newModel.id;
                        newModel.attributes.dm = dm;
                        newModel.prop({ dm : dm });

                        var nodeView = newModel.findView(that.paper);
                        views.push(nodeView);
                        var bbox = nodeView.getBBox({ useModelGeometry : true });
                        bbox = that.paper.paperToLocalRect(bbox);
                        boundingBox.x1 = Math.min(boundingBox.x1, bbox.x);
                        boundingBox.y1 = Math.min(boundingBox.y1, bbox.y);
                        boundingBox.x2 = Math.max(boundingBox.x2, bbox.x + bbox.width);
                        boundingBox.y2 = Math.max(boundingBox.y2, bbox.y + bbox.height);
                    }
                }
            }

            selectionElement = that.newNode(boundingBox.x1, boundingBox.y1, 'selection', null, boundingBox.x2 - boundingBox.x1, boundingBox.y2 - boundingBox.y1);
            that.addElement(selectionElement);
            selectionElement.toFront();

            views.forEach(view => {
                var pos = view.model.position();
                view.model.prop('soffset', {
                    x: pos.x - boundingBox.x1,
                    y: pos.y - boundingBox.y1
                });
            });

            selectionCells = views;
            that.onChange({target:'diagram', event: 'Paste Selection'});
        };


        //dia.Paper.prototype.defineMarker
        var handleLassoSelection = function(evt) {
            lasso.remove();
            lasso = null;
            if (lassoSelection && (lassoSelection.w > 0 || lassoSelection.h > 0)) {
                var lassoRect = that.paper.paperToLocalRect(lassoSelection.x, lassoSelection.y, lassoSelection.w, lassoSelection.h);
                var views = that.paper.findViewsInArea(lassoRect, { strict: false });

                /*
                                selectionElement = that.newNode(lassoRect.x, lassoRect.y, 'selection', null, lassoRect.width,lassoRect.height);
                                that.addElement(selectionElement);
                                selectionElement.toFront();
                */

                if (views && views.length>0) {
                    var boundingBox = {
                        x1: 100000000,
                        y1: 100000000,
                        x2: 0,
                        y2: 0
                    };
                    //var isExpanding = false;
                    if (evt.ctrlKey && selectionElement != null)
                    {
                       // isExpanding = true;
                        var selectionView = selectionElement.findView(that.paper);
                        var expandedBox = selectionView.getBBox({ useModelGeometry : true });
                        expandedBox = that.paper.paperToLocalRect(expandedBox);
                        boundingBox.x1 = expandedBox.x;
                        boundingBox.y1 = expandedBox.y;
                        boundingBox.x2 = boundingBox.x1 + expandedBox.width;
                        boundingBox.y2 = boundingBox.y1 + expandedBox.height;
                        selectionElement.remove();
                        selectionElement = null;
                    }
                    else
                    {
                        selectionCells = [];
                    }

                    views.forEach(view => {
                        var bbox = view.getBBox({ useModelGeometry : true });
                        bbox = that.paper.paperToLocalRect(bbox);
                        boundingBox.x1 = Math.min(boundingBox.x1, bbox.x);
                        boundingBox.y1 = Math.min(boundingBox.y1, bbox.y);
                        boundingBox.x2 = Math.max(boundingBox.x2, bbox.x + bbox.width);
                        boundingBox.y2 = Math.max(boundingBox.y2, bbox.y + bbox.height);
                    });

                    selectionElement = that.newNode(boundingBox.x1, boundingBox.y1, 'selection', null, boundingBox.x2 - boundingBox.x1, boundingBox.y2 - boundingBox.y1);
                    that.addElement(selectionElement);
                    selectionElement.toFront();

                    views.forEach( v=> {
                        selectionCells.push(v);
                    })

                    selectionCells.forEach(view => {
                        var pos = view.model.position();
                        view.model.prop('soffset', {
                            x: pos.x - boundingBox.x1,
                            y: pos.y - boundingBox.y1
                        });
                    });
                }

            }
            lassoInitial = null;
            lassoSelection = null;
        };

        that.paper.on({
            'cell:mouseenter': (view) => {
                addTools(view);
            },
            'cell:mouseleave': (view) => {
                removeTools(view);
            },
            'element:magnet:pointerclick':  () => {
                // console.log('element:magnet:pointerclick');
            },

            'cell:pointerup': () => {
                //console.log('cell:pointerup');
                if (lasso!=null) {
                    handleLassoSelection( { ctrlKey: false });
                }
            },
            'link:pointerup': () => {
                if (changedLink) {
                    changedLink = false;
                    that.onChange({target:'link', event: 'Link change'});
                }
                // console.log('link:pointerup');
            },


            'cell:pointerdown': () => {
                // console.log('cell:pointerdown');


            },
            'link:pointerdown': () => {
                // console.log('link:pointerdown');
            },
            'blank:pointerdown': function(evt, x, y) {
                // console.log('blank:pointerdown');
                let data = evt.data = {};
                let cell;
                if (evt.shiftKey) {
                    cell = new Link();
                    cell.source({ x: x, y: y });
                    cell.target({ x: x, y: y });
                    cell.addTo(this.model);
                    data.cell = cell;
                    return;
                }

                if (evt.ctrlKey === false && selectionElement != null) {
                    selectionElement.remove();
                    selectionElement = null;
                }

                if (lasso == null)
                {
                    lasso = $('<div id="graph-lasso"></div>');
                    lassoInitial = {
                        x: evt.offsetX,
                        y: evt.offsetY
                    };
                    $(that.paper.el).prepend(lasso);
                    lasso.css({
                        width: 1,
                        height: 1,
                        left: evt.offsetX,
                        top: evt.offsetY
                    });
                    lassoSelection = {
                        x: evt.offsetX,
                        y: evt.offsetY,
                        w: 0,
                        h: 0
                    }
                }
            },
            'blank:pointermove': function(evt, x, y) {
                // console.log("blank:pointermove");
                let cell = evt.data.cell;
                if (cell && cell.isLink()) {
                    cell.target({ x: x, y: y });
                }

            },
            'blank:pointerup': function(evt,x,y) {
                // console.log('blank:pointerup');
                let cell = evt.data.cell;
                clonningNode = false;
                lineMode = false;
                currentLine = null;
                clonedNode = null;
                downOnElement = false;
                changedLink = false;
                changedLinkPath = false;
                selectionDragging = false;
                if (lasso!=null) {
                    handleLassoSelection(evt);
                }
                if (cell && cell.isLink()) return;

                copiedCells.targetPos.x = evt.clientX;
                copiedCells.targetPos.y = evt.clientY;
            },

            'element:pointerdblclick': function(cellview, evt, x, y) {
                that.elementDblClick(cellview, evt, x, y);
            },
            'element:pointermove': function(cellview, evt, x, y) {
                // console.log("element:pointermove");
                if (selectionDragging && cellview.model.attributes.type === ShapeTypes.SELECTION) {

                    var current = cellview.model.position();
                    selectionCells.forEach(view => {
                        var offset = view.model.prop('soffset');
                        view.model.position(current.x  + offset.x , current.y + offset.y);
                    });
                }
            },
            'element:pointerdown': function(cellview, evt, x, y) {
                // console.log('element:pointerdown');
                pointerX = x;
                pointerY = y;
                if (evt.ctrlKey && cellview.model.attributes.type !== ShapeTypes.SELECTION && selectionElement==null) {
                    let sourceNodePos = cellview.model.position();
                    clonningNode = true;
                    clonedNode = cellview.model.clone();
                    cloningOffset = { x: x - sourceNodePos.x, y: y - sourceNodePos.y };
                    clonedNode.addTo(that.graph);
                    clonedNode.position(sourceNodePos.x,sourceNodePos.y);
                    let dm = $.extend(true , {}, clonedNode.prop('dm'));
                    dm.id = clonedNode.id;
                    clonedNode.attributes.dm = dm;
                    clonedNode.prop({ dm : dm });
                }
                if (cellview.model.attributes.type === ShapeTypes.SELECTION) {
                    selectionDragging = true;
                } else if (selectionElement != null) {
                    //selectionElement.remove();
                    //selectionElement = null;
                }
                if (selectionDragging === false) {
                    downOnElement = true;
                    downX = x;
                    downY = y;
                }
                /* else if (evt.shiftKey) {
                    lineMode = true;
                    cell = new Link();
                    cell.source(cellview.model, {
                        selector: 'body'
                    });
                    cell.target({ x: x, y: y });
                    cell.addTo(this.model);
                    currentLine = cell;
                } */
                return;
            },
            'element:pointerup': function(cellview, evt, x, y) {
                if (pointerX !== x || pointerY !== y) {
                    that.onChange({target:'element', event: clonningNode ? 'Node clone' : 'Node move'});
                }
                //console.log('element:pointerup');
                clonningNode = false;
                lineMode = false;
                currentLine = null;
                clonedNode = null;
                selectionDragging = false;
                if (downOnElement === true) {
                    if (Math.abs(downX - x)<3 &&
                        Math.abs(downY - y)<3) {
                            that.onElementClick(cellview, evt, x, y);
                    }
                }
                downOnElement = false;
                return;
            },
            'link:connect':function (){
                // console.log('link:connect')
            },
            'link:disconnect':function (){
                // console.log('link:disconnect')
            },
            'link:snap:connect': function (){
                // console.log('link:snap:connect')
            },
            'link:snap:disconnect': function (){
                // console.log('link:snap:disconnect')
            },
            'link:pointerdblclick': function(evt) {
                // that.onChange({target:'link', event: 'removeLinkSplit'});
            }


    });

        that.graph.on({
            'change:position': () => {
                // console.log('change:position');
            },
            'change:z': () => {
                // console.log('change:z');
                // this.onChange({target: 'element', event: 'Z level change'});
            },
            'change:source': () => {
                // console.log('change:source');
                changedLink = true;
                //this.onChange({target: 'link', event: 'Link source change'});
            },
            'change:target': () => {
                // console.log('change:target');
                changedLink = true;
                //this.onChange({target: 'link', event: 'Link target change'});
            },
            'change:vertices': () => {
                // console.log('change:vertices');
                changedLinkPath = true;
                //this.onChange({target: 'link', event: 'Link path change'});
            },
            'add': (cell) => {
                addTools(cell.findView(that.paper));
            },
            'remove': (cell, collection, opt) => {
                if (selectionDeleting===false) {
                    if (cell.isLink()) {
                        this.onChange({target: 'link', event: 'Link removed'});
                    } else {
                        if (cell.attributes.type !== ShapeTypes.SELECTION) {
                            this.onChange({target: 'element', event: 'Element removed'});
                        }
                    }
                }
            }
        })
        // zoom
        const el = document.querySelector('#' + canvasElementID);
        let isDragging = false;
        let startPoint = null; 
        el.onwheel = function(evt) {
            let delta = -1 * Math.sign(evt.deltaY);
            let point = that.paper.clientToLocalPoint(evt.clientX, evt.clientY);
            const oldscale = that.paper.scale().sx;
            const newscale = oldscale + 0.2 * delta * oldscale;
            if (newscale>0.2 && newscale<5) {
                that.paper.scale(newscale, newscale, 0, 0);
                that.paper.translate(-point.x*newscale+evt.offsetX,-point.y*newscale+evt.offsetY);
                if (this.onZoom) {
                    this.onZoom({scale: newscale});
                }
            }
        };

        // pan
        el.addEventListener('mousedown', function(e) {
            if (e.button !== 0) {
                let currentTranslate = that.paper.translate();
                startPoint = { x : e.clientX - currentTranslate.tx, y : e.clientY - currentTranslate.ty};
                isDragging = true;
                el.style.cursor = "grab";
            }
        });

        el.addEventListener('mousemove', function(evt) {
            if (isDragging === true) {
                let ox = evt.clientX - startPoint.x;
                let oy = evt.clientY - startPoint.y;
                // eslint-disable-next-line
                let scale = that.paper.scale().sx;
                that.paper.translate(ox,oy);
            } else if (clonningNode) {
                let point = that.paper.clientToLocalPoint(evt.clientX, evt.clientY);
                clonedNode.position(point.x - cloningOffset.x,point.y - cloningOffset.y);
            } else if (lasso != null) {
                var lassoW = evt.offsetX - lassoInitial.x;
                var lassoH = evt.offsetY - lassoInitial.y;

                var lassoProps = {
                    left: lassoInitial.x,
                    top: lassoInitial.y,
                    width: lassoW,
                    height: lassoH
                };
                if (lassoW < 0) {
                    lassoProps.width = lassoW * -1;
                    lassoProps.left = lassoInitial.x - lassoProps.width;
                }
                if (lassoH < 0) {
                    lassoProps.height = lassoH * -1;
                    lassoProps.top = lassoInitial.y - lassoProps.height;
                }
                lassoSelection = {
                    x: lassoProps.left,
                    y: lassoProps.top,
                    w: lassoProps.width,
                    h: lassoProps.height
                };
                lasso.css(lassoProps);
            }
            /* else if (lineMode) {
                var point = diagram.paper.clientToLocalPoint(evt.clientX, evt.clientY);
                currentLine.target(point);
            } */
        });


        // el.addEventListener('mousedown', function(evt) {
        //
        //         if($('.joint-link')[0]){
        //             $('.joint-link')[0].on ('click', function (){
        //                 console.log ('ff')
        //             })
        //
        //         }
        //
        //
        // })

        window.addEventListener('mouseup', function(e) {
            // console.log("window up");
            isDragging = false;
            clonningNode = false;
            lineMode = false;
            currentLine = null;
            clonedNode = null;
            changedLink = false;
            downOnElement = false;
            if (changedLinkPath) {
                that.onChange({target: 'link', event: 'Link path changed'});
            }
            changedLinkPath = false;
            selectionDragging = false;
            el.style.cursor = "default";
            if (lasso != null) {
                handleLassoSelection(e);
            }
        });

        that.onChange({target: 'diagram', event: 'Init'});


        /*
        that.paper.on("blank:mousewheel", function(evt, x, y, delta) {
            evt.preventDefault();
            const oldscale = that.paper.scale().sx;
            const newscale = oldscale + 0.2 * delta * oldscale
            console.log("delta", delta);
            if (newscale>0.2 && newscale<5) {
                that.paper.scale(newscale, newscale, 0, 0);
                that.paper.translate(-x*newscale+evt.originalEvent.offsetX,-y*newscale+evt.originalEvent.offsetY);
            }
        });*/
    };
    

    this.newNode = function(x, y, type, text, width, height) {
        if (type === "If") {
            return new RhombusShape({
                position: { x: x, y: y },
                label: {
                    text: text
                },
                attrs: {
                    label: {
                        text: text
                    }
                }
            });
        } else if (type === "State") {
            return new RectangleShape({
                position: { x: x, y: y },
                label: {
                    text: text
                },
                attrs: {
                    label: {
                        text: text
                    }
                }
            });
        } else if (type === "selection") {
            return new SelectionShape({
                position: {
                    x: x,
                    y: y
                },
                size: {
                    width: width,
                    height: height
                }
            });
        } else {
            return new EllipseShape({
                position: { x: x, y: y },
                label: {
                    text: text
                },
                attrs: {
                    body: {
                        fill: bodyAttributes[type].fill
                    },
                    label: {
                        text: text,
                        fill: labelAttributes[type].fill
                    }
                }
            });
        }
        
    };

    this.newLink = function(source, target) {
        return new Link({
            source: {
                id: source.id,
                anchor: {
                    name: 'topLeft',
                    args: {
                        rotate: true
                    }
                }
            },
            target: {
                id: target.id,
                anchor: {
                    name: 'topLeft',
                    args: {
                        rotate: true
                    }
                }
            }
        });
    }
    
    this.addElement = function(node) {
        node.addTo(that.graph);
        if (node.attributes.type !== ShapeTypes.SELECTION) {
            this.onChange({target: 'element', event: 'Added new node'});
        }
    };

    this.toJson = function() {
        return this.graph.toJSON();
    };

    this.fromJson = function(obj) {
        this.graph.fromJSON(obj);
    };

    this.export = function() {
        this.deselect();



        let allCells = this.graph.getCells();
        allCells.forEach(c => {
            if (!c.isLink()) {
                if (c.attributes.type === ShapeTypes.SELECTION) {
                    c.remove();
                }
                else
                {
                    let originalDM = c.prop('dm');
                    if (originalDM.id !== c.id) {
                        console.warn("found invalid node, fixing..",originalDM);
                        let dm = $.extend(true , {}, originalDM);
                        dm.id = c.id;
                        c.attributes.dm = dm;
                        c.prop({ dm : dm });
                    }
                }
            }
        });


        let viewJson = this.graph.toJSON();
        //console.log('json', JSON.stringify(viewJson));
        let mapper = {};
        if (viewJson.cells) {
            viewJson.cells.forEach(element => {
               // console.log(element)
                let dataModel = $.extend(true, {}, element.dm);
                if (element.type === ShapeTypes.LINK) {
                    return;
                }
                if (dataModel === undefined || dataModel==null) {
                    console.error("No Datamodel defined for [" + element.type + "] shape with id",element.id);
                    return;
                }
                dataModel.id = element.id;
                dataModel.nodes = [];
                if (dataModel.type === ToolTypes.RULE || dataModel.type === ToolTypes.ACTIONS || dataModel.type === ToolTypes.LINK || dataModel.type === ToolTypes.LOOKUP) {
                    dataModel.IgnoreRules = true;
                }
                let tableData = null;
                if (dataModel.type === ToolTypes.LOOKUP && dataModel.table.columns && dataModel.table.rows) {
                    let cols = dataModel.table.columns.map(function (it) {
                        return {
                            Name: it.field,
                            Type: it.type
                        }
                    });
                    tableData = { columns: cols, rows: dataModel.table.rows , dataExpression : { value: dataModel.table.dataExpression } };
                }
                dataModel.table = tableData;
                mapper[element.id] = dataModel;
            });
            
            viewJson.cells.forEach(element => {
                if (element.type === ShapeTypes.LINK) {
                    // here is link
                    if (element.source && element.source.id && element.target && element.target.id) {
                        let source = mapper[element.source.id];
                        source.nodes.push(element.target.id);
                    } else {
                        console.warn("Broken link found", element.id);
                    }
                }
            });
        }

        return { view: viewJson, data: Object.values(mapper) };
    };

    this.zoom = function(factor) {
        if (factor === undefined) {
            return that.paper.scale().sx;
        }
        let w = $(window).width() / 2;
        let h = $(window).height() / 2;
        let offsetX = w-100;
        let offsetY = h - 47;
        let delta = Math.sign(factor);
        let point = that.paper.clientToLocalPoint(w, h);
        const oldscale = that.paper.scale().sx;
        const newscale = oldscale + 0.2 * delta * oldscale;
        if (newscale>0.2 && newscale<5) {
            that.paper.scale(newscale, newscale, 0, 0);
            that.paper.translate(-point.x*newscale+offsetX,-point.y*newscale+offsetY);
            if (this.onZoom) {
                this.onZoom({ scale: newscale });
            }
        }
    };

    this.scaleContentToFit = function(opt) {
        let curScale = that.paper.scale().sx;
        opt.maxScaleX = curScale;
        opt.maxScaleY = curScale;
        that.paper.scaleContentToFit(opt);
    };

    const myHighlighter = {
        highlighter: {
            name: 'addClass',
            options: {
                className: 'highlighted'
            }
        }
    };

    const highlighters = {
        red:  {
            highlighter: {
                name: 'addClass',
                options: {
                    className: 'highlight_red'
                }
            }
        },
        search:  {
            highlighter: {
                name: 'addClass',
                options: {
                    className: 'highlight_search'
                }
            }
        }
    };

    let highlightedViews = null;

    this.highlightPath = function(path) {
        let cells = that.graph.getCells();
        highlightedViews = [];
        cells.forEach(function(item, index) {
            var view = null;
            if (!item.isLink()) {
                if (path.indexOf(item.id)>-1) {
                    view = that.paper.findViewByModel(item);
                }
                //console.log('item not link', item);
                //item.attr('label/text', item.id);
            } else {
                if ( (item.attributes.source && item.attributes.source.id && path.indexOf(item.attributes.source.id)>-1)
                    && (item.attributes.target && item.attributes.target.id && path.indexOf(item.attributes.target.id)>-1) ) {
                    view = that.paper.findViewByModel(item);
                }
            }
            if (view) {
                view.highlight(null, myHighlighter);
                highlightedViews.push(view);
                // view.highlight();
                /*
                joint.highlighters.mask.add(view, { selector: 'root' }, 'my-element-highlight', {
                    deep: true,
                    attrs: {
                        'stroke': '#FF4365',
                        'stroke-width': 3,
                        filter: {
                            name: 'highlight',
                            args: {
                                color: 'red',
                                width: 2,
                                opacity: 0.5,
                                blur: 5
                            }
                        }
                    }

                });
                joint.highlighters.stroke.add(view, 'body', 'my-highlighter-id', {
                    padding: 10,
                    rx: 5,
                    ry: 5,
                    useFirstSubpath: true,
                    attrs: {
                        'stroke-width': 3,
                        'stroke': '#FF0000'
                    }
                });*/
            }
        });

    };

    this.unhighlightAll = function() {
        if (highlightedViews != null) {
            highlightedViews.forEach(function(view) {
                view.unhighlight(null, myHighlighter);
                view.unhighlight(null, highlighters.red);
                view.unhighlight(null, highlighters.search);
            });
            highlightedViews = null;
        }
    };

    this.showElement = function(id, highlighter) {
        this.unhighlightAll();
        highlightedViews = [];
        let cell = that.graph.getCell(id);
        if (cell) {
            let view = that.paper.findViewByModel(cell);
            view.highlight(null, highlighters[highlighter]);
            highlightedViews.push(view);
        }
    };

    this.showSearch = function(ids, highlighter) {
         this.unhighlightAll();
         highlightedViews = [];
            ids.forEach(id => {
                let cell = that.graph.getCell(id);
                if (cell) {
                    let view = that.paper.findViewByModel(cell);
                    view.highlight(null, highlighters[highlighter]);
                    highlightedViews.push(view);
                }
            });
    };


    this.getCell = function (id) {
        return that.graph.getCell(id);
    };

    this.triggerOnChange = function (e) {
        this.onChange(e);
    };


    this.undo = function () {
        historyCurrentIndex = historyCurrentIndex - 1;
        if (historyCurrentIndex < 0) {
            historyCurrentIndex = -1;
            return null;
        }

        if (historyCurrentIndex === 0) {
            this.DiagramIsChange(false);
        }

        that.fromJson(history[historyCurrentIndex].data);
        return history[historyCurrentIndex];
    };

    this.redo = function () {
        historyCurrentIndex = historyCurrentIndex + 1;
        if (historyCurrentIndex > history.length-1) {
            historyCurrentIndex = history.length -1;
            return null;
        }
        that.fromJson(history[historyCurrentIndex].data);
        return history[historyCurrentIndex];
    };



    // Tools
    function addTools(view) {
        const { paper, model } = view;
        paper.removeTools();
        const tools = new dia.ToolsView({ tools: model.getTools() });
        view.el.classList.add('active');
        view.addTools(tools);
    };

    function removeTools(view) {
        view.el.classList.remove('active');
        view.removeTools();
    };

    

}
export default Diagram;