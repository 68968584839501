import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../DeployRequest/DeployRequest.css'
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NoRowsOverlay from "../NoRowsOverlay/NoRowsOverlay";
import {makeStyles} from "@mui/styles";
import {
    Alert,
    FormControl,
    IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Skeleton, Slide,
    styled, Switch, TextField,
    Tooltip, tooltipClasses
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {
    getDrState,
    getRowsPerPage,
    getSelectedCompanyCfg, getTimeZoneCfg,
    getToken, setDRBadgeCount, setDrState,
    setRowsPerPage
} from "../../utils/Common";
import jwt_decode from "jwt-decode";
import RejectIcon from '@mui/icons-material/DoNotDisturbAlt';
import CancleIcon from '@mui/icons-material/HighlightOffRounded';
import ApproveIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import Request from "../../utils/request";
import Snackbar from "@mui/material/Snackbar";
import Loading from "../Loading/Loading";
import {useCallHolder} from "react-callforward";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import CommentBox from "../CommentBox/CommentBox";
import _ from "lodash";
import Fade from "@mui/material/Fade";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        top: '9px',
        backgroundColor: '#FFFFFF',
        color: '#676f7a',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: '15px',
        boxShadow: '5px 5px 10px 0px rgb(171 171 171 / 63%)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    }
}));

function DeployRequest(props) {
    //Pagination style
    const useStyles = makeStyles({
        root: {
            display: 'flex',
        },
    });

    function SlideTransition(props) {
        return <Slide {...props} direction="up"/>;
    }

    function capitalize(word) {
        return word[0].toUpperCase() + word.substring(1);
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const navigate = useNavigate();

    function CustomPagination() {
        const classes = useStyles();
        return (
            <div className="GridFooter">


                <div className="pageSelect">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="pageSelect-label">Rows per page</InputLabel>
                        <Select
                            id="pageSelect"
                            value={pageSize}
                            label="Rows per page"
                            onChange={(e) => handlePageSizeChange(e.target.value)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="Pages">
                    <Pagination
                        className={classes.root}
                        color="primary"
                        variant="outlined"
                        count={Math.ceil(rowCount / pageSize) || 1}
                        page={page}
                        onChange={(event, value) => handlePageChange(value)}
                    />
                </div>
            </div>

        );
    }
    const handlePageChange = (newPage) => {
        setPage(newPage);
        GetDRList(Math.ceil(newPage === 1 ? 0 : (newPage-1) * pageSize, (newPage + 1) * pageSize))
    };

    const handlePageSizeChange = (count) => {
        setPageSize(count)
        setRowsPerPage(count)
    }

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    }

    useCallHolder((e) => {
        setDeployRequestStatus(e);
    },props.DrGridStatused);

    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }

    const handleInfoClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

   const handleCompare = (actionType,params) => {
       const requestId = params.row.id;
       const currentId = params.row.currentId || 0;
       const sourceId = 0;
       let navLink = "/compare/" + sourceId + '/' + currentId + '/' + requestId;
       navigate(navLink);
   }

    const userInfo = jwt_decode(getToken());

    const today = moment();
    const clonedDate = today.clone();
    const previousMonth = clonedDate.subtract(1, 'month');
    const todayDate = today.toDate();
    const previousMonthDate = previousMonth.toDate();

    const columns = [
        {
            field: 'subAct',
            headerName: '',
            width: 40,
            headerAlign: 'center',
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            // hide: true,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={20} animation="wave" />
                        :
                        <>
                        {params.row.currentId ?
                            <IconButton onClick={()=>{handleCompare ('compare',params)}}><CompareArrowsIcon /></IconButton> :

                            <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title="Not Comparable → There is no current version to compare" >
                                   <IconButton onClick={()=>{handleCompare ('view',params)}}><VisibilityOutlinedIcon /></IconButton>
                            </HtmlTooltip>
                        }
                        </>
                    }
                </div>
            )
        },
        {  field: 'id',
            headerName: 'ID',
            width: 40,
            type: "number",
            headerAlign: 'left',
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
           // hide: true,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={20} animation="wave" />
                        :
                        <>{params.row.id}</>

                    }
                </div>
            )
         },
        {
            field: 'code',
            headerName: 'Code',
            description: 'Code name of tree',
            minWidth: 190,
            width: 140,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden', width:'100%', display: 'flex', flexDirection: 'column'}}>
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        <>
                        <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={params.row.code ? params.row.code : params.row.sourceTreeCode} >
                            <span className="table-cell-trucate">
                                {params.row.code ? params.row.code : params.row.sourceTreeCode}
                            </span>
                        </HtmlTooltip>
                        <span className="table-cell-trucate drGrid-title">{params.row.title ? params.row.title : params.row.sourceTitle}</span>

                        </>
                    }
                </div>
            )
        },
        {
            field: 'notes',
            headerName: 'Comment',
            description: 'Comment for Deploy Request',
            minWidth: 290,
            flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: false,
            filterable: true,
            sortable: false,
            width: 290,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        params.row.notes.split("\n").reverse().map((note,index) =>
                        <HtmlTooltip  className="ToolTipStyle" key={index} arrow placement="right-end" title={note ? note : ''} >
                                <span className="table-cell-trucate" style={{display: 'block',padding: 0, fontSize: '12px'}} key={index}> {note} </span>
                        </HtmlTooltip>
                            )
                    }
                </div>
            ),

        },
        {
            field: 'created',
            headerName: 'Created',
            description: 'Created',
            minWidth: 150,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                            <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title={params.row.createdBy ? params.row.createdBy : ''} >
                               <div>
                                   <span style={{display: 'block',padding: 0, fontSize: '12px' , textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden', width: '135px'}} > {params.row.createdBy}</span>
                                   <span style={{display: 'block',padding: 0, fontSize: '10px', color: '#747474', fontStyle: 'italic'}} >
                                {params.row.created ? moment(new Date(moment(params.row.created, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss') : ''}
                                </span>
                               </div>
                           </HtmlTooltip>

                    }
                </div>
            )
        },
        {
            field: 'modified',
            headerName: 'Modified',
            description: 'Modified',
            width: 150,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title={params.row.modifiedBy ? params.row.modifiedBy : ''} >
                            <div>
                                <span style={{display: 'block',padding: 0, fontSize: '12px' , textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden', width: '135px'}} > {params.row.modifiedBy} </span>
                                <span style={{display: 'block',padding: 0, fontSize: '10px', color: '#747474', fontStyle: 'italic'}} >
                                {params.row.modified ? moment(new Date(moment(params.row.modified, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss') : ''}
                                </span>
                            </div>
                        </HtmlTooltip>

                    }
                </div>
            )
        },
        {
            field: 'openDate',
            headerName: 'Start date',
            description: 'Start date',
            type: 'date',
            width: 150,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <span className="table-cell-trucate">
                        { params.row.openDate ? moment(new Date(moment(params.row.openDate, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')
                        : '' }
                        </span>
                    }
                </div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'Status of request',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden'}} >
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={params.row.status ? params.row.status : ''} >
                            <span className="table-cell-trucate">{params.row.status}</span>

                        </HtmlTooltip>
                    }
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 150,
            // flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            width: 160,
            headerAlign: "center",
            headerClassName: 'JustifyCenter',
            align: 'center',
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden'}} >
                    { loading ? <Skeleton width={110} animation="wave" /> :
                // eslint-disable-next-line
                params.row.status !== "New" &&
                // eslint-disable-next-line
                        (params.row.status === "Approved" ?
                 <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title={`Deployed as ${params.row.deployedTreeCode} with version ${params.row.deployedAbVersion}`} >
                    <div>
                        <span className="table-cell-trucate" style={{display: 'block',padding: 0, fontSize: '11px', color: '#747474', fontStyle: 'italic'}} >
                       <a href="/#"
                       onClick={(e)=>{
                           e.preventDefault();
                           e.stopPropagation();
                          // console.log ('/diagram/'+params.row.deployedTreeCode+'/'+params.row.deployedId)
                          navigate('/diagram/'+params.row.deployedTreeCode+'/'+params.row.deployedId)
                       }}
                       >
                           {params.row.deployedTreeCode && params.row.deployedAbVersion ? params.row.deployedTreeCode + " v"+params.row.deployedAbVersion :  ''}
                       </a>
                        </span>
                    </div>
                </HtmlTooltip>
                            // eslint-disable-next-line
                            : <div className="actions"> <LinearScaleIcon /> </div>) ||
                <div>
                    <span>{!acceptActionsEnabled}</span>
                    <span className={`actions approve ${!acceptActionsEnabled ? 'defaultCursor' : ''} `}>
                        <HtmlTooltip className="ToolTipStyle" arrow placement="top" title="Approve" >
                            <span>
                                <IconButton disabled={!acceptActionsEnabled}  onClick={(e)=>{ handleAcceptAction(e , params, 'Approved') }}>
                                   <ApproveIcon />
                                </IconButton>
                            </span>

                        </HtmlTooltip>
                    </span>

                            <span className={`actions cancel ${!acceptActionsEnabled ? 'defaultCursor' : ''} `}>
                         <HtmlTooltip className="ToolTipStyle" arrow placement="top" title="Cancel" >
                             <span>
                                <IconButton disabled={!acceptActionsEnabled} onClick={(e)=>{ handleAcceptAction(e , params, 'Canceled') }}>
                                    <CancleIcon />
                                </IconButton>
                             </span>
                         </HtmlTooltip>
                    </span>

                            <span className={`actions reject ${!acceptActionsEnabled ? 'defaultCursor' : ''} `}>
                         <HtmlTooltip className="ToolTipStyle" arrow placement="top" title="Reject" >
                             <span>
                                 <IconButton disabled={!acceptActionsEnabled} onClick={(e)=>{ handleAcceptAction(e, params, 'Rejected') }}>
                                   <RejectIcon />
                                 </IconButton>
                             </span>

                        </HtmlTooltip>
                    </span>

                        </div>

                    }
                </div>
            ),
        }
    ];

    const [company] = useState(getSelectedCompanyCfg() ? getSelectedCompanyCfg()[userInfo.sub] : null);
    const [,setCompanyList] = useState(props.CompanyList) || [];
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(undefined);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(getRowsPerPage());
    const [loading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }]);
    const [DeployRequestStatus, setDeployRequestStatus] = useState(getDrState() ? getDrState() : 'New');

    const [delay, setDelay] = useState(0);
    const [CodeSearchValue, setCodeSearchValue] = useState('');

    const [DateFromSearchValue, setDateFromSearchValue] = useState(previousMonthDate);
    const [DateToSearchValue, setDateToSearchValue] = useState(todayDate);


    const [commentBox, setCommentBox] = useState(false);
    const [CommentTxt, setCommentTxt] = useState('');
    const [DeployManageAction, setDeployManageAction] = useState(null);
    const [CurRowParams, setCurRowParams] = useState(null);

    const [acceptActionsEnabled, setAcceptActionsEnabled] = useState(false);



    const prevCodeSearchValue = usePrevious(CodeSearchValue);

        useEffect(()=>{
            setCompanyList(props.CompanyList);
            // eslint-disable-next-line
        },[props.CompanyList])

    useEffect(()=>{
        setLoading(true);
        if (getDrState()) { setDeployRequestStatus(getDrState()) }



        if (userInfo){
            let clime = userInfo.security_claim;
            if (!Array.isArray(clime)) {
                clime = [clime];
            }
            for (let i = 0; i < clime.length; i++) {
                if (clime[i] === 'dr.accept'){
                    setAcceptActionsEnabled (true);
                    break ;
                }


            }
        }


        if(prevCodeSearchValue !== CodeSearchValue) {
            const timeoutId = setTimeout(() =>{
                GetDRList();
            }, delay);
            return () => {
                clearTimeout(timeoutId)
                if (delay === 0 ) {setDelay(2000)}
            };
        } else {
            if (company !==0)
                GetDRList();
        }


        //if (company !==0) GetDRList();

        // eslint-disable-next-line
    },[pageSize,sortModel,company,DeployRequestStatus,CodeSearchValue, delay , DateFromSearchValue , DateToSearchValue])

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setDelay(0);
        }
    }


    let GridFilters = [
        {
            "Field": "CompanyId",
            "Value": company,
            "Operator": "Equals"
        },
        {
            "Field": "CreatedDate",
            "Value": moment(DateFromSearchValue).format("DD/MM/YYYY") ,
            "Operator": "GreaterThanOrEqual"
        },
        {
            "Field": "CreatedDate",
            "Value": moment(DateToSearchValue).format("DD/MM/YYYY"),
            "Operator": "LessThanOrEqual"
        },
        {
            "Operator": "Or",
            "Filters": [
                {
                    "Field": "Code",
                    "Value": CodeSearchValue,
                    "Operator": "Contains"
                },
                {
                    "Field": "Title",
                    "Value": CodeSearchValue,
                    "Operator": "Contains"
                },
            ]
        }

    ];

    if (DeployRequestStatus === "New") {
        GridFilters.push(
            {
                "Field": "Status",
                "Value": "New",
                "Operator": "Equals"
            }
        )
    }

    const GetDRList = (start = 0 , limit = pageSize) => {
        let DRListReqBody = {
            "StartIndex": start,
            "RowCount": limit,
            "SortBy": [
                {
                    "Field": sortModel[0] ? capitalize(sortModel[0].field) : "id",
                    "Direction": sortModel[0] ? sortModel[0].sort : "Desc"
                }
            ],
            "Filter": {
                "Operator": "And",
                "Filters": GridFilters
            }
        };

        // console.log ('today', DateToSearchValue)
        // console.log ('previus', DateFromSearchValue)

        setLoading(true);


           if (company){
               Request(window._env_.REACT_APP_API_TREE_LIST + 'DeployRequest/list').post(DRListReqBody)
                   .then((data) => {
                           setLoading(false);
                           console.log (data)
                           if (data) {
                               if (data && data.status !== 403) {
                                   if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                                       const userInfo = jwt_decode(getToken());
                                       ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                                   }
                               } else if (data.errors){
                                   ShowInfo(data.errors[0].message, 'error')
                               } else if (data.message){
                                   ShowInfo(data.message, 'warning')
                               }
                               setRows(data.rows.length > 0 ? data.rows : []);
                               setRowCount(data.total)
                           }
                       }
                   ).catch(() => {
                   setRows([])
                   setRowCount(0)
                   setLoading(false);
                   ShowInfo('Cannot Load Deploy Requests\'s', 'error')
               })
           } else {
               setLoading(false);
               ShowInfo('You dont have access to Company , so you can not see deploy requestss', 'error')
           }

       }

    // const handleRowClick = (params) => {
    //     if (params.row.currentId !== null) {
    //         console.log(params.row)
    //         navigate('/compare/'+ params.row.sourceId + '/' + (params.row.status === "Approved" ?  params.row.deployedId : params.row.currentId) + '/' + (params.row.status !== "Approved" ? params.row.id : ''))
    //     }
    // };

    const getDRBadgeCount = () => {
        if (company !==0){
            Request(window._env_.REACT_APP_API_TREE_LIST + 'DeployRequest/Unread?companyId=' + company).get()
                .then((data) => {
                        if (data) {
                            // setDrBadgeCount(drBadgeCount + data.result+1)
                            setDRBadgeCount(data.result)
                        } else {
                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error')
                            }
                            if (data.message){
                                ShowInfo(data.message, 'warning')
                            }
                        }
                    }
                ).catch(() => {
                console.log('Cannot Load Deploy Request Badge Count')
            })

        }
    }

    const handleAcceptAction = (e,params,action) => {
        e.stopPropagation();
        setCurRowParams(params);
        setDeployManageAction(action);
        setCommentBox(true);
        setCommentTxt('');
    }

    const onCommentBoxClose = () => {
        setCommentBox(false);
    };

    const CommentText = (result) => {
        if (result){
            setCommentTxt(result);
        }
        setCommentBox(false);

    }



    let DRActionModel = null;

    useEffect(()=>{
        // eslint-disable-next-line
        DRActionModel = {
            "id" : CurRowParams ? CurRowParams.row.id : null,
            "status" : DeployManageAction,
            "checksum" : CurRowParams ? CurRowParams.row.checksum : null,
            "folderID" : 0,
            "notes" : CommentTxt
        }
    },[DeployManageAction,CommentTxt])

    useEffect(()=>{
        if (CommentTxt) {
            setLoading(true);
            Request(window._env_.REACT_APP_API_TREE_LIST + 'deployrequest').patch(DRActionModel)
                .then((data) => {
                    setLoading(false);
                    if (data) {
                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error');
                            return false;
                        }
                        if (data.message){
                            ShowInfo(data.message, 'warning');
                            return false;
                        }
                    }
                    ShowInfo('Successfuly ' + DeployManageAction, 'success');
                    GetDRList();
                    getDRBadgeCount();
                }).catch(() => {
                ShowInfo(DeployManageAction + ' Problem', 'warning');
                setLoading(false);
            })

        }
        // eslint-disable-next-line
    },[CommentTxt])

    return (
        <div>
            {(loading && rows.length <= 0) &&
                <Loading fullscreen />
            }
            <div className="ControlsWrapper">
                <div className="filter-controls">
                    <TextField
                        variant="outlined"
                        className="CodeSearch"
                        value={CodeSearchValue}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={()=>{ setCodeSearchValue(''); }}>
                                    {CodeSearchValue ?  <CloseIcon /> : <SearchIcon />}
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Search"
                        onChange={(e) => { setCodeSearchValue(e.target.value)  }}
                        onKeyDown={_handleKeyDown}
                    />

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            value={DateFromSearchValue || null}
                            disableFuture
                            onChange={(e)=>{e ? setDateFromSearchValue (e) : setDateFromSearchValue(new Date())}}
                            className='DateInput'
                            renderInput={(params) => (
                                <div className="DateInputOver">
                                    <span className="DatePickerLabel">From</span>
                                    <TextField
                                        disabled
                                        value={moment(DateFromSearchValue).format('DD/MM/YYYY')}
                                    /><TextField  {...params} />
                                </div>
                            )}

                        />


                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            value={DateToSearchValue || null}
                            disableFuture
                            onChange={(e)=>{e ? setDateToSearchValue (e) : setDateToSearchValue(new Date())}}
                            className='DateInput'
                            renderInput={(params) => (
                                <div className="DateInputOver">
                                    <span className="DatePickerLabel">To</span>
                                    <TextField
                                        disabled
                                        value={moment(DateToSearchValue).format('DD/MM/YYYY')}
                                    /><TextField  {...params} />
                                </div>
                            )}
                        />
                    </LocalizationProvider>



                        <div className="DrStatus">
                            <span>All</span>
                            <Switch
                                inputProps={{'aria-label': 'Switch B'}}
                                checked={DeployRequestStatus === "New"}
                                onChange={(e) => {
                                    let Stat = e.target.checked ? "New" : "None";
                                    setDrState(Stat);
                                    setDeployRequestStatus(Stat);
                                }}
                            />
                            <span>New</span>
                        </div>



                </div>
            </div>
            <div className="GridWrapper">
                <div className="DeployRequestGrid" >
                        <DataGrid
                            sx={{ m: 2 }}
                            getRowHeight={() => 'auto'}
                            rows={rows}
                            columns={columns}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            hideFooterSelectedRowCount={true}
                            showCellRightBorder={false}
                            disableSelectionOnClick
                            checkboxSelection = {false}
                            pagination
                            getRowClassName={(params)=>{return params.row.currentId === null ? 'not-clickable-row' : ''}}
                            // onRowClick={handleRowClick}

                            components={{
                                ColumnUnsortedIcon: () => <ArrowDropDownIcon />,
                                Pagination: CustomPagination,
                                NoRowsOverlay: () => <NoRowsOverlay />,
                            }}
                        />
                </div>
            </div>

            <CommentBox
                open={commentBox}
                required
                requiredText= {`Comment for ${DeployManageAction} is Required`}
                onClose={(e) => { onCommentBoxClose(e)}}
                CommentText ={(e) => { CommentText(e)}}
            />

            <Snackbar open={infoShow} autoHideDuration={15000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {infoMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}


export default DeployRequest;