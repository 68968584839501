import * as joint from 'jointjs';
import { g } from 'jointjs';
import {BaseShape} from './diagram.shape';
import {
    connectToolAttributes,
    labelAttributes,
    ShapeTypes,
    bodyAttributes,
 } from './diagram.common';

const { Polygon, toDeg } = g;

window.joint = joint;
const RhombusShape = BaseShape.define(ShapeTypes.RHOMBUS, {
    size: { width: 80, height: 80 },
    attrs: {
        root: {
            highlighterSelector: 'body'
        },
        body: {
            d: 'M calc(.5*w) 0 calc(w) calc(.5*h) calc(.5*w) calc(h) 0 calc(.5*h) Z',
            ...bodyAttributes.Default
        },
        label: {
            text: 'Rhombus',
            ...labelAttributes.Default
        }
    }
}, {
    markup: [{
        tagName: 'path',
        selector: 'body'
    }, {
        tagName: 'text',
        selector: 'label'
    }],

    getConnectToolMarkup() {
        const { width, height } = this.size();
        return [{
            tagName: 'path',
            attributes: {
                d: `M ${width/2} 0 ${width} ${height/2} ${width/2} ${height} 0 ${height/2} Z`,
                ...connectToolAttributes
            }
        }];
    },

    getCurveDirection(point) {
        const bbox = this.getBBox();
        const angle = bbox.center().angleBetween(point, bbox.topMiddle());
        if (angle % 90 === 0) {
            return 'auto';
        }
        let ratio = bbox.height / bbox.width;
        if ((angle % 180) < 90) {
            ratio = 1 / ratio;
        }
        return 360 - Math.floor(angle / 90) * 90 + toDeg(Math.atan(ratio));
    },

    getClosestBoundaryPoint(bbox, point) {
        const rhombus = new Polygon([
            bbox.topMiddle(),
            bbox.rightMiddle(),
            bbox.bottomMiddle(),
            bbox.leftMiddle(),
        ]);
        return rhombus.closestPoint(point);
    }
});

export {RhombusShape}