import React, { createRef } from "react";
import PropTypes from "prop-types";
import getCaretCoordinates from "./textareaCaret";
import getInputSelection, { setCaretPosition } from "get-input-selection";
import BodyEnd from "./BodyEnd";
import "./index.css";
import {Box, IconButton, InputAdornment, MenuItem, TextareaAutosize} from "@mui/material";
import Menu from "@mui/material/Menu";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AbcIcon from "@mui/icons-material/Abc";
import NumberIcon from "@mui/icons-material/Filter1";
import TextIcon from "@mui/icons-material/TextFormat";
import BooleanIcon from "@mui/icons-material/CompareArrows";
import DateIcon from "@mui/icons-material/CalendarMonth";
import ObjectIcon from "@mui/icons-material/DataObject";
import ArrayIcon from "@mui/icons-material/DataArray";
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/HighlightOff';

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_RETURN = 13;
const KEY_ENTER = 14;
const KEY_ESCAPE = 27;
const KEY_TAB = 9;

const propTypes = {
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  maxOptions: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onType: PropTypes.func,
  onKeyDown: PropTypes.func,
  onAddType: PropTypes.func,
  DataFields:PropTypes.arrayOf(PropTypes.object),
  onRequestOptions: PropTypes.func,
  onSelect: PropTypes.func,
  changeOnSelect: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  regex: PropTypes.string,
  matchAny: PropTypes.bool,
  minChars: PropTypes.number,
  requestOnlyIfNoOptions: PropTypes.bool,
  spaceRemovers: PropTypes.arrayOf(PropTypes.string),
  spacer: PropTypes.string,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  value: PropTypes.string,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  passThroughEnter: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

const defaultProps = {
  Component: "textarea",
  defaultValue: "",
  disabled: false,
  maxOptions: 100,
  onBlur: () => { },
  onChange: () => { },
  onKeyDown: () => { },
  onAddType: () => { },
  onRequestOptions: () => { },
  onSelect: () => { },
  changeOnSelect: (trigger, slug) => trigger + slug,
  onType: ()=> {},
  options: [],
  regex: "^[A-Za-z0-9-_@.]+$",
  matchAny: false,
  minChars: 0,
  requestOnlyIfNoOptions: true,
  spaceRemovers: [",", ".", "!", "?"],
  spacer: "",
  trigger: "@",
  offsetX: 0,
  offsetY: 0,
  value: null,
  passThroughEnter: false,
  fullscreen: false,
};

class ReactAutocompleteTextarea extends React.Component {
  constructor(props) {
    super(props);

    this.getMatch = this.getMatch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnType = this.handleOnType.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.updateCaretPosition = this.updateCaretPosition.bind(this);
    this.updateHelper = this.updateHelper.bind(this);
    this.resetHelper = this.resetHelper.bind(this);
    this.renderAutocompleteList = this.renderAutocompleteList.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.state = {
      helperVisible: false,
      left: 0,
      trigger: null,
      matchLength: 0,
      matchStart: 0,
      options: [],
      selection: 0,
      top: 0,
      value: null,
      anchorEl: null,
      typeItem : {name : "" , type: ""},
      targetTypeElement : null,
      reserved_type_element: []
    };

    this.recentValue = props.defaultValue;
    this.enableSpaceRemovers = false;
    this.refInput = createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    const { caret } = this.state;

    if (options.length !== prevProps.options.length) {
      this.updateHelper(this.recentValue, caret, options, null);
    }

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }


  getMatch(str, caret, providedOptions, e) {
    const { matchAny, regex } = this.props;
    const re = new RegExp(regex);
    let slugData = null;

    for (let i = caret - 1; i >= 0; i -= 1) {
      const substr = str.substring(i, caret);
      const match = substr.match(re);
      let matchStart = -1;

      if (match && i > 0) {
        // find first non-matching character or begin of input
        continue;
      }
      matchStart = i === 0 && match ? 0 : i + 1;

      if (caret - matchStart === 0 && e.forceOpen !== true) {
        // matched slug is empty
        break;
      }

      if (matchStart >= 0) {
        const matchedSlug = str.substring(matchStart, caret);

        const triggerOptions = this.handleOnType({ slug: matchedSlug, caret: caret });
        if (triggerOptions == null) {
          continue;
        }



        const options = triggerOptions.filter((slug) => {
          const idx = slug.toLowerCase().indexOf(matchedSlug.toLowerCase());
          return idx !== -1 && (matchAny || idx === 0);
        });

        const currTrigger = '';
        const matchLength = matchedSlug.length;

        if (slugData === null) {
          slugData = {
            trigger: currTrigger,
            matchStart,
            matchLength,
            options
          };
          break;
        }
      }
    }

    if (e.forceOpen === true && slugData === null) {
      const forceOptions = this.handleOnType({ slug: '', caret: caret });
      slugData = {
        trigger: '',
        matchStart: 0,
        matchLength: 1,
        options: forceOptions
      };
    }

    return slugData;
  }

  getMatchOld(str, caret, providedOptions) {

    const { trigger, matchAny, regex } = this.props;
    const re = new RegExp(regex);

    let triggers = trigger;
    if (!Array.isArray(triggers)) {
      triggers = new Array(trigger);
    }
    triggers.sort();

    const providedOptionsObject = providedOptions;
    if (Array.isArray(providedOptions)) {
      triggers.forEach((triggerStr) => {
        providedOptionsObject[triggerStr] = providedOptions;
      });
    }

    const triggersMatch = this.arrayTriggerMatch(triggers, re);
    let slugData = null;

    for (
      let triggersIndex = 0;
      triggersIndex < triggersMatch.length;
      triggersIndex += 1
    ) {
      const { triggerStr, triggerMatch, triggerLength } = triggersMatch[
        triggersIndex
      ];

      for (let i = caret - 1; i >= 0; i -= 1) {
        const substr = str.substring(i, caret);
        const match = substr.match(re);
        let matchStart = -1;

        if (triggerLength > 0) {
          const triggerIdx = triggerMatch ? i : i - triggerLength + 1;

          if (triggerIdx < 0) {
            // out of input
            break;
          }

          if (this.isTrigger(triggerStr, str, triggerIdx)) {
            matchStart = triggerIdx + triggerLength;
          }

          if (!match && matchStart < 0) {
            break;
          }
        } else {
          if (match && i > 0) {
            // find first non-matching character or begin of input
            continue;
          }
          matchStart = i === 0 && match ? 0 : i + 1;

          if (caret - matchStart === 0) {
            // matched slug is empty
            break;
          }
        }

        if (matchStart >= 0) {
          const triggerOptions = providedOptionsObject[triggerStr];
          if (triggerOptions == null) {
            continue;
          }

          const matchedSlug = str.substring(matchStart, caret);

          const options = triggerOptions.filter((slug) => {
            const idx = slug.toLowerCase().indexOf(matchedSlug.toLowerCase());
            return idx !== -1 && (matchAny || idx === 0);
          });

          const currTrigger = triggerStr;
          const matchLength = matchedSlug.length;

          if (slugData === null) {
            slugData = {
              trigger: currTrigger,
              matchStart,
              matchLength,
              options
            };
          } else {
            slugData = {
              ...slugData,
              trigger: currTrigger,
              matchStart,
              matchLength,
              options
            };
          }
        }
      }
    }

    return slugData;
  }

  arrayTriggerMatch = (triggers, re) => {
    const triggersMatch = triggers.map((trigger) => ({
      triggerStr: trigger,
      triggerMatch: trigger.match(re),
      triggerLength: trigger.length
    }));

    return triggersMatch;
  };

  isTrigger = (trigger, str, i) => {
    if (!trigger || !trigger.length) {
      return true;
    }

    if (str.substr(i, trigger.length) === trigger) {
      return true;
    }

    return false;
  };

  handleOnType(e) {
    const { onType } = this.props;
    return onType(e);
  }

  handleChange(e) {
    const { onChange, options, spaceRemovers, spacer, value } = this.props;

    const old = this.recentValue;
    const str = e.target.value;
    const caret = getInputSelection(e.target).end;

    if (!str.length && e.forceOpen!==true) {
      this.setState({ helperVisible: false });
    }

    this.recentValue = str;

    this.setState({ caret, value: e.target.value });

    if ((!str.length || !caret) && e.forceOpen!==true) {
      return onChange(e.target.value);
    }

    // '@wonderjenny ,|' -> '@wonderjenny, |'
    if (
      this.enableSpaceRemovers &&
      spaceRemovers.length &&
      str.length > 2 &&
      spacer.length
    ) {
      for (let i = 0; i < Math.max(old.length, str.length); i += 1) {
        if (old[i] !== str[i]) {
          if (
            i >= 2 &&
            str[i - 1] === spacer &&
            spaceRemovers.indexOf(str[i - 2]) === -1 &&
            spaceRemovers.indexOf(str[i]) !== -1 &&
            this.getMatch(str.substring(0, i - 2), caret - 3, options, e)
          ) {
            const newValue = `${str.slice(0, i - 1)}${str.slice(
              i,
              i + 1
            )}${str.slice(i - 1, i)}${str.slice(i + 1)}`;

            this.updateCaretPosition(i + 1);
            this.refInput.current.value = newValue;

            if (!value) {
              this.setState({ value: newValue });
            }

            return onChange(newValue);
          }

          break;
        }
      }

      this.enableSpaceRemovers = false;
    }

    this.updateHelper(str, caret, options, e);

    if (!value) {
      this.setState({ value: e.target.value });
    }

    return onChange(e.target.value);
  }




  handleKeyDown(event) {
    const { helperVisible, options, selection } = this.state;
    const { onKeyDown, passThroughEnter, maxOptions } = this.props;
    const maxSelection = Math.min(maxOptions, options.length); // GR: Fix error key_up and key_up

    if (helperVisible) {
      switch (event.keyCode) {
        case KEY_ESCAPE:
          event.preventDefault();
          event.stopPropagation();
          this.resetHelper();
          break;
        case KEY_UP:
          event.preventDefault();
          this.setState({
            selection: (maxSelection + selection - 1) % maxSelection
          });
          document.getElementsByClassName('react-autocomplete-input-li-active')[0].scrollIntoView({block: 'center'});
          break;
        case KEY_DOWN:
          event.preventDefault();
          this.setState({ selection: (selection + 1) % maxSelection });
          document.getElementsByClassName('react-autocomplete-input-li-active')[0].scrollIntoView({block: 'center'});
          break;
        case KEY_ENTER:
        case KEY_RETURN:
          if (!passThroughEnter) {
            event.preventDefault();
          }
          this.handleSelection(selection);
          break;
        case KEY_TAB:
          this.handleSelection(selection);
          break;
        default:
          onKeyDown(event);
          break;
      }
    } else {
      if (event.ctrlKey && event.keyCode === 32) {
        event.forceOpen = true;
        this.handleChange(event);
      } else {
        onKeyDown(event);
      }

    }
  }

  handleResize() {
    this.setState({ helperVisible: false });
  }

  handleSelection(idx) {
    const { spacer, onSelect, changeOnSelect } = this.props;
    const { matchStart, matchLength, options, trigger } = this.state;

    const slug = options[idx];
    const value = this.recentValue;
    const part1 = value.substring(0, matchStart - trigger.length);
    const part2 = value.substring(matchStart + matchLength);

    const event = { target: this.refInput.current };
    const changedStr = changeOnSelect(trigger, slug);

    event.target.value = `${part1}${changedStr}${spacer}${part2}`;
    this.handleChange(event);
    onSelect(event.target.value);

    this.resetHelper();

    this.updateCaretPosition(part1.length + changedStr.length + 1);

    this.enableSpaceRemovers = true;
  }

  updateCaretPosition(caret) {
    this.setState({ caret }, () =>
      setCaretPosition(this.refInput.current, caret)
    );
  }

  updateHelper(str, caret, options, e) {
    const input = this.refInput.current;

    const slug = this.getMatch(str, caret, options, e);

    if (slug) {
      const caretPos = getCaretCoordinates(input, caret - 1, { scroll: true }); // GR: Fix line break tooltip when text is not newline
      const rect = input.getBoundingClientRect();

      const top = rect.top + caretPos.top + window.scrollY - input.scrollTop; // GR: Use top for tooltip
      const left = rect.left + caretPos.left; // GR: Use left for tooltip

      const { minChars, onRequestOptions, requestOnlyIfNoOptions } = this.props;
      if (
        slug.matchLength >= minChars &&
        (slug.options.length > 1 ||
          (slug.options.length === 1 &&
            slug.options[0].length !== slug.matchLength))
      ) {
        this.setState({
          helperVisible: true,
          top,
          left,
          ...slug
        });
      } else {
        if (!requestOnlyIfNoOptions || !slug.options.length) {
          onRequestOptions(str.substr(slug.matchStart, slug.matchLength));
        }

        this.resetHelper();
      }
    } else {
      this.resetHelper();
    }
  }

  resetHelper() {
    this.setState({ helperVisible: false, selection: 0 });
  }


  handleBlur(e) {
    const {DataFields} = this.props;
    let found = e.target.value.match(/^@([a-zA-Z0-9.]+)\s*:=/);

    if (found && DataFields.findIndex(x => x.name === found[1]) === -1 && this.state.reserved_type_element.indexOf(found[1]) === -1) {
      this.setState({anchorEl : e.currentTarget});
      this.setState({targetTypeElement : found[1]});
    }

    if (found){

      this.setState(prevState => ({
        reserved_type_element: [...prevState.reserved_type_element, found[1]]
      }))
    }
  }



   handleClose  () {
    this.setState({anchorEl : null});
  };

   AddType ()  {
    const {onAddType} = this.props;

    if (this.state.typeItem && this.state.typeItem.name && this.state.typeItem.type) {
      onAddType (this.state.typeItem);
      this.setState({anchorEl : null});
    }
  }


  renderAutocompleteList() {
    const {
      helperVisible,
      left,
      matchStart,
      matchLength,
      options,
      selection,
      top,
      value,
    } = this.state;

    if (!helperVisible) {
      return null;
    }

    const { maxOptions, offsetX, offsetY } = this.props;

    if (options.length === 0) {
      return null;
    }

    if (selection >= options.length) {
      this.setState({ selection: 0 });

      return null;
    }





    const optionNumber = maxOptions === 0 ? options.length : maxOptions;

    const helperOptions = options.slice(0, optionNumber).map((val, idx) => {
      const highlightStart = val
        .toLowerCase()
        .indexOf(value.substr(matchStart, matchLength).toLowerCase());

      return (
        <li
          className={
            idx === selection ? "react-autocomplete-input-li-active" : null
          }
          key={val}
          onClick={() => {
            this.handleSelection(idx);
          }}
          onMouseEnter={() => {
            this.setState({ selection: idx });
          }}
        >
          {val.slice(0, highlightStart)}
          <strong>{val.substr(highlightStart, matchLength)}</strong>
          {val.slice(highlightStart + matchLength)}
        </li>
      );
    });

    return (
      <BodyEnd>
        <ul
          className="react-autocomplete-input"
          style={{
            left: left + offsetX,
            top: top + offsetY,
            overflow: 'auto',
            maxHeight: '250px'
          }}
        >
          {helperOptions}
        </ul>
        <div
          className="react-autocomplete-backdrop"
          onClick={this.handleResize}
        />
      </BodyEnd>
    );
  }

  render() {
    const {
      Component,
      defaultValue,
      disabled,
      onBlur,
      value,
      fullscreen,
      ...rest
    } = this.props;

    const { value: stateValue } = this.state;

    const propagated = Object.assign({}, rest);
    Object.keys(propTypes).forEach((k) => {
      delete propagated[k];
    });

    let val = "";

    if (typeof value !== "undefined" && value !== null) {
      val = value;
    } else if (stateValue) {
      val = stateValue;
    } else if (defaultValue) {
      val = defaultValue;
    }

    const style = {

      minHeight: "38px",
      resize: "none",
      padding: "9px",
      boxSizing: "border-box",
      fontSize: "15px"
    };






    return (
      <div style={{width: "100%"}}>
        <TextareaAutosize
            style={style}
            aria-label="minimum height"
            minRows={3}
            spellCheck="false"
          disabled={disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          ref={this.refInput}
          value={val}
          {...propagated}
          className={`react-autocomplete-textArea ${fullscreen ? 'fullscreen' : ''}`}
        />
        {this.renderAutocompleteList()}

        <Menu
            id="type-add-context"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
          <span className="typeName"> {this.state.targetTypeElement}</span>

          <div className="typeListQuickAdd">
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <div className="typeControls">
            <div className='type'>
              <FormControl sx={{ m: 1 }}  size="small">
                <Select
                    labelId="TypeSelect"
                    id="select-type-quick"
                    value={this.state.typeItem.type}
                    onChange={(e)=>this.setState({typeItem : {name : this.state.targetTypeElement , type : e.target.value}})}
                    startAdornment={
                      <InputAdornment  position="start">
                        <AbcIcon />
                      </InputAdornment>
                    }
                    placeholder="Type"
                    displayEmpty={true}
                    renderValue={value => value?.length ? value : 'Select Type...'}
                >
                  <MenuItem className="quick-type" value="Number"><NumberIcon/> Number</MenuItem>
                  <MenuItem className="quick-type"  value="String"><TextIcon /> String</MenuItem>
                  <MenuItem className="quick-type"  value="Boolean"><BooleanIcon /> Boolean</MenuItem>
                  <MenuItem className="quick-type"  value="Date"><DateIcon /> Date</MenuItem>
                  <MenuItem className="quick-type"  value="Object"><ObjectIcon /> Object</MenuItem>
                  <MenuItem className="quick-type"  value="Array"><ArrayIcon /> Array</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
            </Box>
          </div>
          <div className="QuickTypeActions">
            <IconButton className="OkIcon" onClick={() => this.AddType()}><OkIcon /></IconButton>
            <IconButton className="CancelIcon" onClick={() => { this.setState( {anchorEl : null} )}}><CancelIcon /></IconButton>
          </div>


        </Menu>

      </div>
    );
  }
}

ReactAutocompleteTextarea.propTypes = propTypes;
ReactAutocompleteTextarea.defaultProps = defaultProps;

export default ReactAutocompleteTextarea;
export { ReactAutocompleteTextarea, BodyEnd };