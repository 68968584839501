export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

export const getToken = () => {
    return sessionStorage.getItem('access_token') || null;
}

export const setUserSession = (access_token,user) => {
    sessionStorage.setItem("access_token",access_token);
    sessionStorage.setItem("user",JSON.stringify(user));
}

export const removeUserSession = () => {
     sessionStorage.removeItem("access_token");
     sessionStorage.removeItem("user");
}

export const setLanguage = (lang) => {
    localStorage.setItem("language",lang);
}

export const getLanguage = () => {
    return localStorage.getItem('language') || null
}

export const setRowsPerPage = (count) => {
    return localStorage.setItem('RowsPerPage' , count) || 25
}
export const getRowsPerPage = () => {
    return localStorage.getItem('RowsPerPage') || 25
}


export const setUsersRowsPerPage = (count) => {
    return localStorage.setItem('UsersRowsPerPage' , count) || 25
}
export const getUsersRowsPerPage = () => {
    return localStorage.getItem('UsersRowsPerPage') || 25
}


export const setSelectedFoler = (folderInfo) => {
    return sessionStorage.setItem('slectedFolderInfo' , JSON.stringify(folderInfo))
}
export const getSelectedFoler = () => {
    return JSON.parse(sessionStorage.getItem('slectedFolderInfo'))
}
export const removeSelectedFoler = () => {
    return sessionStorage.removeItem('slectedFolderInfo')
}


//

export const setBreadCrumps = (Info) => {
    return sessionStorage.setItem('BreadCrumps' , JSON.stringify(Info))
}
export const getBreadCrumps = () => {
    return JSON.parse(sessionStorage.getItem('BreadCrumps'))
}
export const removeBreadCrumps = () => {
    return sessionStorage.removeItem('BreadCrumps')
}

///



export const setdeployDraftState = (state) => {
    return localStorage.setItem('deployDraftState' ,state);
}
export const getdeployDraftState = () => {
    return localStorage.getItem('deployDraftState');
}


export const setDrState = (state) => {
    return localStorage.setItem('DRState' ,state);
}
export const getDrState = () => {
    return localStorage.getItem('DRState');
}

export const setDRBadgeCount = (Info) => {
    return sessionStorage.setItem('DRBadgeCount',Info)
}
export const getDRBadgeCount = () => {
    return sessionStorage.getItem('DRBadgeCount')
}



export const setTimeZoneCfg = (timezone) => {
    return localStorage.setItem('TimeZone' , JSON.stringify(timezone))
}
export const getTimeZoneCfg = () => {
    return JSON.parse(localStorage.getItem('TimeZone'))
}

export const removeTimeZoneCfg = () => {
    return localStorage.removeItem('TimeZone')
}

export const setSelectedCompanyCfg = (compList) => {
    return localStorage.setItem('company' , JSON.stringify(compList))
}
export const getSelectedCompanyCfg = () => {
    return JSON.parse(localStorage.getItem('company')) || null
}



export const waitForLocalStorage = (key, cb, timer) => {
    if ( ! localStorage.getItem( key ) ) {
        return timer = setTimeout(
            waitForLocalStorage.bind( null, key, cb ),
            100
        )
    }
    clearTimeout( timer )
    if ( typeof cb !== 'function' ) {
        return localStorage.getItem( key )
    }
    return cb( localStorage.getItem( key ) )
}


export const waitForSessionStorage = (key, cb, timer) => {
    if ( ! sessionStorage.getItem( key ) ) {
        return timer = setTimeout(
            waitForSessionStorage.bind( null, key, cb ),
            100
        )
    }
    clearTimeout( timer )
    if ( typeof cb !== 'function' ) {
        return sessionStorage.getItem( key )
    }
    return cb( sessionStorage.getItem( key ) )
}
