import PropTypes from 'prop-types';
import React from 'react';

import './Loading.css';

export default function Loading({ fullScreen }) {
    return (
        <div className={`loading${fullScreen ? ' loading--fullScreen' : ''}`}>
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

Loading.propTypes = {
    fullScreen: PropTypes.bool,
    withLogo: PropTypes.bool,
};
Loading.defaultProps = {
    fullScreen: false,
    withLogo: false,
};
