import * as joint from 'jointjs';
import { dia } from 'jointjs';

import {ShapeTypes, bodyAttributes} from './diagram.common';

    window.joint = joint;


const SelectionShape = dia.Element.define(ShapeTypes.SELECTION, {
    z: 1000000,
    size: { width: 80, height: 60 },
    attrs: {
        root: {
            highlighterSelector: null
        },
        body: {
            width: 'calc(w)',
            height: 'calc(h)',
            strokeWidth: 1,
            strokeDasharray: [5 , 6],
            ...bodyAttributes.Selection
        }
    }
}, {
    getTools() {
        return [];
    },
    markup: [{
        tagName: 'rect',
        selector: 'body',
    }]
});

export {SelectionShape}