// import { dia, shapes, linkTools, connectors, g } from 'jointjs';
//
// const { Polygon, Ellipse, Rect, toDeg } = g;
// const { TangentDirections } = connectors.curve;

// Theme
const highlighterAttributes = {
    'stroke': '#4666E5',
    'stroke-width': 2,
    'stroke-linecap': 'butt',
    'stroke-linejoin': 'miter',
};


const connectToolAttributes = {
    'fill': 'none',
    'stroke-width': 10,
    'stroke-opacity': 0.4,
    'stroke': '#4666E5',
    'cursor': 'cell',
};

const lineAttributes = {
    stroke: '#333333',
    strokeWidth: 1,
};

const labelAttributes = {
    Default: {
    textVerticalAnchor: 'middle',
    textAnchor: 'middle',
    x: 'calc(.5*w)',
    y: 'calc(.5*h)',
    fill: '#9b9b9b',
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    fontVariant: 'small-caps',
    pointerEvents: 'none'
    },
    Input: {
        fill: '#6c6c6c'
    },
    Switch: {
        fill: '#5e5e5e'
    },
    Rule: {
        fill: '#ffffff'
    },
    Link: {
        fill: '#ffffff'
    },
    Actions: {
        fill: '#ffffff'
    },
    Output: {
        fill: '#656565'
    },
    Lookup: {
        fill: '#000000',
    }

};



const ShapeTypes = {
    BASE: 'Base',
    RHOMBUS: 'Rhombus',
    RECTANGLE: 'Rectangle',
    ELLIPSE: 'Ellipse',
    LINK: 'Link',
    SELECTION: 'Selection'
};

const bodyAttributes = {
    Default: {
        fill: '#ffda00',
        stroke: '#a8aebb',
        strokeWidth: 1,
        cursor: 'grab'
    },
    Selection: {
        fill: '#697ebb21',
        stroke: '#4c5364',
        strokeWidth: 1,
        cursor: 'move'
    },
    Input: {
        fill: '#aada00'
    },
    Switch: {
        fill: '#ffda00'
    },
    Rule: {
        fill: '#649de3'
    },
    Link: {
        fill: '#cf6ee7'
    },
    Actions: {
        fill: '#ff7070'
    },
    Output: {
        fill: '#d0d0d0'
    },
    Lookup: {
        fill: 'rgb(9 206 255)',
    }
};

const ToolTypes = {
    INPUT: 'Input',
    SWITCH: 'Switch',
    RULE: 'Rule',
    LINK: 'Link',
    ACTIONS: 'Actions',
    OUTPUT: 'Output',
    LOOKUP: 'Lookup',
    // PARALLEL: 'Parallel'
};

export {
    highlighterAttributes,
    connectToolAttributes,
    lineAttributes,
    labelAttributes,
    ShapeTypes,
    bodyAttributes,
    ToolTypes
}