import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dictionary from "../../utils/dictionary";
import './ConfirmDialog.css';

export default function ConfirmDialog(props) {

    const handleClickOutside = (event,reason) => {
        if (reason === 'backdropClick'){
            return;
        }
    }


    const [ConfirmDialogFormIsValid , setConfirmDialogFormIsValid] = useState(true);

    useEffect(() => {
        setConfirmDialogFormIsValid(true)
    }, [props.open]);


    return (
        <div className='ConfirmWindow'>
            <Dialog className='ConfirmDialog'
                    disableEnforceFocus
                    open={props.open}
                    onClose={(event, reason)=> handleClickOutside(event,reason)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent  dividers>
                    <div> {props.text} </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        if (ConfirmDialogFormIsValid){
                            props.onClose(true)
                        }}
                    }
                            color="primary" autoFocus>
                        {dictionary('yes')}
                    </Button>
                    <Button onClick={() => {
                        props.onClose(false);
                    }} color="primary">
                        {dictionary('no')}
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
