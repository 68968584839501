import {getLanguage} from "./Common";
let lang = getLanguage();
if (!lang) {
    lang = "en";
}

let dictionary = (key) => {
    let dict = {
        //Global
        yes: [ "Yes" ,'Да'],
        no: ["No", "Нет"],
        error: ['Error :' , "Ошибка"],
        networkError: ['You don\'t have internet connection', "Нет Интернет соеденения"],
        //Login
        login_title: ["Enter the username you use to login to LogiX.", "Введите имя пользователья для входа в LogiX", "Մուտքագրեք Ձեր մուտքանունը LogiX համակարգ մուտք գործելու համար"],
        login_username: ["Username", "Логин","Մուտքանուն"],
        login_password: ["Password", "Пароль","Գաղտնաբառ"],
        login_login: ["Login", "Вход","Մուտք"],
        activation_activation: ["Activation", "Активация","Ակտիվացում"],
        activation_title: ["Set password for acivate LogiX user.", "Введите пароль для активации пользователья LogiX", "Մուտքագրեք գաղտնաբառ LogiX օգտատիրոջը ակտիվացնելու համար"],
        activation_password: ["Password", "Пароль","Գաղտնաբառ"],
        activation_rptPassword: ["Repeat Password", "Повтарить пароль","Կրկնել գաղտնաբառը"],
        activation_btn: ["Activate", "Активация","Ակտիվացեում"],
        recover_recover: ["Recover password", "Восстоновить пароль","Վերականգնել գաղտնաբառը"],
        recover_title: ["Enter your E-mail for recover password on LogiX system.", "Введите Ваш E-mail для восстоновления паролья системы LogiX", "Մուտքագրեք Ձեր E-mail-ը LogiX համակարգում գաղտնաբառը վերականգնելու համար"],
        recover_email: ["E-mail for recover password","E-mail для восстоновления паролья", "E-mail գաղտնաբառը վերականգնելու համար"],

        resetpass_resetpass: ["Set Password", "Установить Пароль","Սահմանել Գաղտնաբառ"],
        resetpass_set_btn: ["Set Password", "Установить Пароль","Սահմանել Գաղտնաբառ"],
        resetpass_title: ["Set password for LogiX user.", "Введите пароль для пользователья LogiX", "Մուտքագրեք գաղտնաբառ LogiX օգտատիրոջ համար"],

    };
return (dict[key][lang === 'en' ? 0 : lang === 'ru' ? 1 : 2 ])
}
export default dictionary;