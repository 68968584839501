import {getToken, removeUserSession} from "./Common";

const Request = endpoint => {
    const defaultHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': "Bearer " + getToken()
    };

    const headerNoToken = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    const customFetch = (
        url,
        method = "GET",
        body = false,
        headers = false,
        noToken = false

    ) => {
        const options = {
            method
        };
        if (body) options.body = JSON.stringify(body);
        if (headers) {
            options.headers = {
                ...headers,
                ...noToken? headerNoToken : defaultHeader
            }
        }else {
            options.headers = noToken? headerNoToken : defaultHeader
        }
        return fetch(url, options)
            .then(handleErrors)
            .then(json => {
                if (json.status === 401) {
                    removeUserSession();
                    window.location.reload();
                }
                // if (json.status === 403) {
                //     throw new Error("mmmmmmmmmmmmsgggggg");
                // }
                const data = json.status === 204  ? null : json.json();
                if (!data) {
                    return {status:'ok',body: 'empty'}
                } else {
                    return data;
                }
            })
            .catch(() => {return false});
    };

    const get = params => {
        const url = `${endpoint}${params ? `${params}` : ""}`;
        return customFetch(url);
    };

    const post = (body = false, headers = false , noToken = false) => {
        if (!body) throw new Error("to make a post you must provide a body");
        return customFetch(endpoint, "POST", body , headers , noToken);
    };

    const patch = (body = false) => {
        if (!body) throw new Error("to make a put you must provide a body");
        return customFetch(endpoint, "PATCH", body);
    };

    const del = (id = false) => {
        if (!id)
            throw new Error("to make a delete you must provide the id");
        const url = `${endpoint}/${id}`;
        return customFetch(url, "DELETE");
    };

    const put = (body = false) => {
        if (!body)
            throw new Error("to make a put you must provide the body");
        const url = `${endpoint}`;
        return customFetch(url, "PUT", body);
    };


    return {
        get,
        post,
        patch,
        del,
        put
    };
};



function handleErrors(response) {

    if (!response.ok) {
        response.clone().json()
            .then((err)=>{
                return (err)
            })
    }
    return response;
}
export default Request
