import './UserActivate.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useEffect, useState} from 'react';
import  Request from "../../utils/request";
import Loading from "../Loading/Loading";
import {setLanguage} from "../../utils/Common";
import Snackbar from '@mui/material/Snackbar';
import Logo from "../../Assets/logo.png";
import {Alert, Link, Slide, Tooltip} from "@mui/material";
import dictionary from "../../utils/dictionary";
import * as React from "react";
import imglogo from '../../Assets/imglogo.png'
import Fade from "@mui/material/Fade";
import {useParams} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

const ValidateStyle = withStyles({
    arrow: {
        fontSize: 20,
        "&::before": {
            backgroundColor: "rgba(243,62,62,0.8)",
        }
    },
    tooltip: {
        color: "white",
        backgroundColor: "rgba(243,62,62,0.8)",
        // height: "20px",
        textAlign: 'center',
        lineHeight: "20px"
    }
})(Tooltip);
const UserActivate = () => {

    const [password, setPassword] = useState('');
    const [rptPassword, setRptPassword] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [rptPasswordError, setRptPasswordError] = useState('');

    const [loading, setLoading] = useState(false);
    const [smallscreen, setSmallscreen] = useState(false);
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');

    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }



    const { userid } = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    const isResetPass = Boolean(window.location.pathname.split('/').find((element) => element === 'resetpassword'));
    const handleActivate = (password) => {
        passwordFieldValidate();


        const data = {
            password,
            "id": userid || null,
            "token": token || null,
        };



        setTimeout(()=>{
            let REQ , ErrMessage , SuccessMessage
            if (isResetPass) {
                ErrMessage = 'Problem with Set password';
                SuccessMessage = 'Set password successfully';
            } else {
                ErrMessage = 'Problem with activate this user';
                SuccessMessage = 'User Successfuly Activated';
            }

            if (passwordError === '' && rptPasswordError === '') {
                if (userid && token){

                    setLoading(true);
                    isResetPass ?
                        REQ = Request(window._env_.REACT_APP_API_TREE_LIST + 'user/password-reset-confirm').post(data,null,true)
                        :
                        REQ = Request(window._env_.REACT_APP_API_TREE_LIST + 'user/activate').post(data,null,true)
                    REQ.then((data) => {
                            setLoading(false);
                            if (data === false){
                                ShowInfo(ErrMessage,'error')
                                return;
                            }
                            if (data){
                                if (data.errors){
                                    ShowInfo(data.errors[0].message, 'error')
                                    return;
                                }
                                if (data.message){
                                    ShowInfo(data.message,'warning')
                                    return;
                                }
                                if (data){
                                    ShowInfo(SuccessMessage, 'success');
                                    setTimeout(()=>{
                                        window.location.href = '/';
                                    },2000)
                                }
                            }
                        })
                } else {
                    ShowInfo(ErrMessage, 'error')
                }
            }
        },0)
    }

    const handleInfoClose = (event, reason) => {
        // console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    const changeLang = (e,lang) => {
        e.preventDefault();
        setLanguage(lang);
        window.location.reload(false);
    }

    useEffect(() => {
        //Unsuported Device
        let mediaQueryList = window.matchMedia('(max-width: 484px)');
        setSmallscreen (mediaQueryList.matches)
        mediaQueryList.addEventListener("change", (e) => {
            setSmallscreen(e.matches)
        });
    },[]);

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleActivate(password ,rptPassword);
        }
    }

    const passwordFieldValidate = () => {
        setPasswordError(!password ? 'Password Required' : '');
        setRptPasswordError(!rptPassword ? 'Password Required' : '');

        if (password && rptPassword) {
            if (password !== rptPassword) {
                setRptPasswordError('Passwords don\'t match');
            }
        }
    }

    return (
        <div className={`StyledBoxWrapper ${smallscreen ? "unSupportScreen" : ""}`}>
            {smallscreen && <div className='unsuportedContainer'>
                <div className='unsuportLogo'>
                    <img src={Logo} alt='logo'/>
                </div>
                <div className='unsuportText'>
                    Unsuported Device
                </div>
            </div>
            }
            {loading &&
                <Loading fullscreen />
            }
            <div className="StyledBox">
                <Box>
                    <span className="StyledBox">{isResetPass ? dictionary("resetpass_resetpass") : dictionary("activation_activation")}</span>
                    {/*<span className="StyledHeading">*/}
                    {/*    Welcome to <div className="StyledSpan">LogiX</div>*/}
                    {/*</span>*/}
                </Box>
                <span className="StyledInfo">
                   {isResetPass ? dictionary("resetpass_title") : dictionary("activation_title")}
                </span>
                <form className="StyledForm">
                    <Box sx={{ position: 'relative', marginBottom: '30px' }}>
                        <ValidateStyle
                            placement='right'
                            open={!!passwordError}
                            title={passwordError ? passwordError : ''}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 0 }}
                            arrow
                        >
                        <TextField
                            error = {!!passwordError}
                            label={dictionary("activation_password")}
                            value={password || ''}
                            className={passwordError ? 'txtErr':''}
                            onKeyDown={(e)=>handleKeyDown(e)}
                            onChange={(e) => setPassword(e.target.value)}
                            type={'password'}
                            placeholder="Enter Your Password"
                            fullWidth
                            onBlur={()=>{passwordFieldValidate()}}
                            inputProps={{
                                autoComplete: "new-password",
                            }}
                        />
                        </ValidateStyle>
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <ValidateStyle
                            placement='right'
                            open={!!rptPasswordError}
                            title={rptPasswordError ? rptPasswordError : ''}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 0 }}
                            arrow
                        >
                        <TextField
                            error = {!!rptPasswordError}
                            label={dictionary("activation_rptPassword")}
                            className={rptPasswordError ? 'txtErr':''}
                            value={rptPassword || ''}
                            onKeyDown={(e)=>handleKeyDown(e)}
                            onChange={(e) => setRptPassword(e.target.value)}
                            type={'password'}
                            placeholder="Repeat Your Password"
                            fullWidth
                            onBlur={()=>{passwordFieldValidate()}}
                            inputProps={{
                                autoComplete: "new-password",
                            }}
                        />
                        </ValidateStyle>
                    </Box>

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handleActivate(password,rptPassword)}
                    >
                        {isResetPass ? dictionary("resetpass_set_btn") : dictionary("activation_btn")} <ArrowRightAltIcon sx={{ marginLeft: '5px' }} />
                    </Button>
                </form>
            </div>
            <Box sx={{ backgroundColor: '#334050',display: 'flex', paddingBottom: '25px', justifyContent: 'center', }}>
                <div className="imgbox" style={{position: "absolute" , top: "40%"}}>
                    <img src={imglogo} alt="Decision Maker" style={{width : "200px"}}/>
                    <span className="loginLogoTitle">Decision Maker</span>
                </div>
                <Link href="#" onClick={(e) => changeLang (e,'en')}>English</Link>
                <Link href="#" onClick={(e) => changeLang (e,'ru')}>Русский</Link>
                <Link href="#" onClick={(e) => changeLang (e,'hy')}>Հայերեն</Link>
            </Box>
            <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {infoMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default UserActivate;
