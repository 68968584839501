import './Header.css';
import * as React from 'react';
import logo from '../../Assets/logo.png';
import {
    Alert, Badge,
    Button,
    DialogTitle,
    Divider, FormControl,
    IconButton, InputAdornment, InputLabel,
    ListItemIcon,
    MenuItem, Select, Slide, styled, Tab, Tabs,
    Tooltip, tooltipClasses
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SaveIcon from '@mui/icons-material/Save';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TreeIcon from '@mui/icons-material/AccountTree';
import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';
import {useEffect, useMemo, useState} from "react";
import {Logout, Visibility, VisibilityOff} from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import Navigation from "../Navigation/Navigation";
import {
    getBreadCrumps,
    getSelectedCompanyCfg,
    getSelectedFoler,
    getTimeZoneCfg,
    getToken,
    removeBreadCrumps,
    removeUserSession,
    setBreadCrumps,
    setSelectedCompanyCfg,
    setSelectedFoler,
    setTimeZoneCfg,
} from "../../utils/Common";
import {useNavigate} from "react-router-dom";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import jwt_decode from "jwt-decode";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import DeployIcon from '@mui/icons-material/Send';
import DeployRequestIcon from '@mui/icons-material/SendTimeExtension';
import TextField from "@mui/material/TextField";
import Request from "../../utils/request";
import Loading from "../Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import moment from "moment-timezone";
import uuid from "react-uuid";
import useSessionStorage from "../useSessionStorage/useSessionStorage";
import ApproveIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancleIcon from "@mui/icons-material/HighlightOffRounded";
import RejectIcon from "@mui/icons-material/DoNotDisturbAlt";
import Fade from "@mui/material/Fade";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        top: '9px',
        backgroundColor: '#FFFFFF',
        color: '#676f7a',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: '15px',
        boxShadow: '5px 5px 10px 0px rgb(171 171 171 / 63%)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    }
}));

function Header(props) {
    const [userMenuOpen, setUserMenuOpen] = useState(null);
    const open = Boolean(userMenuOpen);
    const navigate = useNavigate();

    //let tzName = Intl.DateTimeFormat().resolvedOptions().timeZone; vdrug sksav chashxtel
    let tzName = moment.tz.guess();
    let tzOffset = -new Date().getTimezoneOffset();

    const handleClick = (event) => {
        setUserMenuOpen(event.currentTarget);
    };
    const handleClose = () => {
        setUserMenuOpen(null);
    };

    const DeployNowClicked = () => {
        setDeployMenuAnchorEl(null);
        props.DiagramDeployClick("Deploy");
    };

    const DeployRequestClicked = () => {
        setDeployMenuAnchorEl(null);
        props.DiagramDeployClick("DeployRequest");
    };


    const ApproveClicked = () => {
        props.DeployManageActionsClick("Approved");
    };
    const CancelClicked = () => {
        props.DeployManageActionsClick("Canceled");
    };
    const RejectClicked = () => {
        props.DeployManageActionsClick("Rejected");
    };



    const badgeStyle = {
        "& .MuiBadge-badge": {
            backgroundColor: '#ffce00c9',
            color: 'black'
        }
    }

    //const { drId } = useParams();

    const [loading, setLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [SettingsPopupOpen, setSettingsPopupOpen] = useState(false);
    const [ChangePassPopupOpen, setChangePassPopupOpen] = useState(false);
    const [OldPass, setOldPass] = useState('');
    const [Pass, setPass] = useState('');
    const [RptPass, setRptPass] = useState('');

    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');

    const [showOldPassword, setShowOldPassword] = useState(false);
    const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showRptPassword, setShowRptPassword] = useState(false);
    const handleClickShowRptPassword = () => setShowRptPassword(!showRptPassword);
    const handleMouseDownRptPassword = () => setShowRptPassword(!showRptPassword);

    const [Settingsvalue, setSettingsValue] = useState(0);

    const [tz, setTz] = useState(getTimeZoneCfg() ? getTimeZoneCfg().tzName : tzName);
    const [datetime,setDatetime] = useState(moment());

    const [CompanyList, setCompanyList] = useState(props.CompanyList);
    const [selectedCompany, setselectedCompany] = useState(props.selectedCompany);

    const [lnk , setlnk] = useState([]) ;
    const [expanded , setExpanded] = useState([]) ;

    const [deployMenuAnchorEl, setDeployMenuAnchorEl] = React.useState(null);

    const [acceptActionsEnabled, setAcceptActionsEnabled] = useState(false);
    const [DeployActionsEnabled, setDeployActionsEnabled] = useState(false);
    const [DeployRequestActionsEnabled, setDeployRequestActionsEnabled] = useState(false);
    const [TreeSaveEnabled, setTreeSaveEnabled] = useState(false);

    // const [comapreStatus, setComapreStatus] = useState(null);
    //
    //
    // useEffect(()=>{
    //         setComapreStatus(window.comapreStatus);
    //         console.log ('ekav ' + window.comapreStatus)
    //     },[window.comapreStatus])




    const DeployMenuOpen = Boolean(deployMenuAnchorEl);
    const handleDeployClick = (event) => {
        setDeployMenuAnchorEl(event.currentTarget);
    };
    const handledeployMenuClose = () => {
        setDeployMenuAnchorEl(null);
    };

    const setTimezone = (e) => {
        setTz (e.value);
        setDatetime(datetime.tz(e.value));
        setTimeZoneCfg({tzName : e.value , offset: datetime._offset});
    }


    const userInfo = jwt_decode(getToken());


    useMemo(() => {
        if (!getTimeZoneCfg()){
            setTimeZoneCfg(	{"tzName":tzName,"offset":tzOffset})
        }
        setCompanyList(props.CompanyList);
        setselectedCompany(props.selectedCompany);

        setTimeout(waitForBreadCrumps, 0);



        // eslint-disable-next-line
    }, [props]);

    useEffect(() => {
        if (userInfo){
            let clime = userInfo.security_claim;
            if (!Array.isArray(clime)) {
                clime = [clime];
            }
            setAcceptActionsEnabled(clime.find(cl => cl === 'dr.accept'));
            setDeployActionsEnabled(clime.find(cl => cl === 'tree.deploy'));
            setDeployRequestActionsEnabled(clime.find(cl => cl === 'dr.create'));
            setTreeSaveEnabled(clime.find(cl => cl === 'tree.draft.edit') && (props.treeData ? !props.treeData.abVersion > 0 : true));
        }
    }, [userInfo,props]);


    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const handleInfoClose = (event, reason) => {
        //console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }


    const onConfirmDialogClose = (result) => {
        setConfirmDialog(false);
        if (result) {
            handleLogout();
        }
    };

    const handleLogout = () => {
        // removeUserSession();
        localStorage.removeItem('sessionStorage');
        removeUserSession();
        removeBreadCrumps();
        localStorage.removeItem('logout');
        localStorage.setItem('logout', 'true');
        navigate('/');
        window.location.reload(false);
    }




    const ChangePassPopupClose = () => {
        setChangePassPopupOpen(false);
    }

    const SettingsPopupClose = () => {
        setSettingsPopupOpen(false);
    }


    const recursiveSearch = (obj, searchKey, results = []) => {
        const r = results;
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if(key === searchKey && typeof value !== 'object'){
                r.push(value);
            }else if(typeof value === 'object'){
                recursiveSearch(value, searchKey, r);
            }
        });
        return r;
    };

    const ChangePassProcedure = () => {
      let model =  {
            "oldPassword": OldPass,
            "newPassword": Pass,
            "confirmPassword": RptPass
        };

      let custHeaders = {
            "requestUserId": userInfo.sub
        };

      if (OldPass.length > 0 && Pass.length > 0 && RptPass.length > 0 && Pass === RptPass) {
          setChangePassPopupOpen(false);
          setLoading(true);
          Request(window._env_.REACT_APP_API_TREE_LIST + 'user/password-change').post(model,custHeaders)
             .then((data) => {
                   setLoading(false);
                  if (data) {
                      if (data && data.status !== 400) {
                          // if (data.errors[0].message && data.errors[0].field){
                          //     ShowInfo(_.capitalize(data.field) + " - " + data.message, 'warning')
                          // }
                          if (data.hasOwnProperty('errors')){
                              let errors = recursiveSearch(data.errors, 'message');
                              ShowInfo(errors.join('|'),'error');
                              return;
                          } else {
                             if (data.hasOwnProperty('message')) {
                                 ShowInfo(data.message,'error');
                             } else {
                                 ShowInfo("Successfuly Changed Password", 'success')
                             }

                          }

                      }

                  } else {
                      if (data.errors){
                          ShowInfo(data.errors[0].message, 'error')
                      }
                      if (data.message){
                          ShowInfo(data.message, 'warning')
                      }
                  }
              }).catch(() => {
              ShowInfo('Change Password Problem', 'warning');
              setChangePassPopupOpen(false);
              setLoading(false);
           })
      }
    }

    const SettingsProcedure = () => {
        // jamanakavor
        setSettingsPopupOpen(false);
        navigate(0);
    }

    const twoNewPassMatch = () => {
        return (Pass.length > 0 && RptPass.length > 0 && Pass !== RptPass);
    }



    let path = window.location.pathname;
    let regex = /\//g;
    let pathSlashCnt = (path.match(regex).length);

    // for settings tab
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                className='tabpanel'
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const settingsTabCangeChange = (event, newValue) => {
        setSettingsValue(newValue);
    };

    const CompanyListhandleChange  = (e) => {
        setselectedCompany(e.target.value);
        let SelectedCompany = getSelectedCompanyCfg();
        SelectedCompany[userInfo.sub] = e.target.value;
        setSelectedCompanyCfg(SelectedCompany);
        removeBreadCrumps();
        window.location.reload();
    }
    function waitForBreadCrumps() {
        if (getBreadCrumps() && getBreadCrumps().ExpandFolders) {
            folderPathLink();
        } else {
            setTimeout(waitForBreadCrumps, 0);
        }
    }
    const folderPathLink = () => {
        if (props.treeData && getBreadCrumps().ExpandFolders) {
            let lnkStr = props.treeData.folderPath;
            let lnkArr = lnkStr.split('/');
            const ExpandFolders = getBreadCrumps().ExpandFolders.reverse() ;
            setlnk (lnkArr);
            setExpanded (ExpandFolders);
            let LnkObj = {};
            for (let i=0; i<lnkArr.length; ++i) {
                LnkObj[lnkArr[i]] = ExpandFolders[i]  ;
            }
        }
    }

    const setNewBreadCrumps = (name,id) => {
        let arr = expanded;
        let indexToRemove = arr.indexOf(id);
        if (indexToRemove !== -1) {
            arr = arr.slice(0, indexToRemove + 1);
        }

        const tmpBreadCrumps = getBreadCrumps();
        tmpBreadCrumps.ExpandFolders = arr;
        setBreadCrumps(tmpBreadCrumps);
        setSelectedFoler(
            {
                "selectedID": parseInt(id),
                "selectedName": name
            }
        )
        navigate('/');
    }


    // for settings tab
    return (
        <div>
            {loading &&
                <Loading fullscreen />
            }
        <div className="Header">
            <div className="HeaderLeft">
                <Badge badgeContent={parseInt(useSessionStorage('DRBadgeCount')) || 0} sx={badgeStyle}>
                    <Navigation />
                </Badge>

                <div className="logo"><a href='/'> <img src={logo} alt="logo" /> </a> </div>
            </div>

            {(

                !props.treeData  && pathSlashCnt < 2 && !props.hidden) &&
                <div style={{display: 'flex', flex: 'auto', marginLeft: '5px'}}>
                    <FormControl sx={{m: 1, minWidth: 200}} size="small">
                        <InputLabel id="SelectCompany">Company</InputLabel>
                        <Select
                            labelId="SelectCompany"
                            id="companyList"
                            value={selectedCompany || ''}
                            label="Company"
                            onChange={CompanyListhandleChange}
                        >
                            {CompanyList.length > 0 && CompanyList.map((CompItem, index) =>
                                <MenuItem key={index} value={CompItem.id}>{CompItem.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            }

            {(props.treeData && pathSlashCnt > 2) &&
                <div className="treeInfo">
                    {props.treeData.code &&
                        <span className="codeInfo">
                            <TreeIcon />
                            <span className="codename">{props.treeData.code} </span>
                            <span className={`version  ${props.treeData.abVersion !==0 ? '' : 'draft' }`}>{props.treeData.abVersion !==0 ? `( ver. ${props.treeData.abVersion} )` : '( Draft )'}</span>
                        </span>
                    }
                    <span className="pathInfo">
                       {props.treeData.code ? "" : <FolderOpenIcon  style={{marginRight: '5px'}}/>}
                        {lnk.length === 0 && <p>Loading path...</p>}
                        {lnk.map((lk, index) =>
                            <span key={uuid()}>
                                <a href="/#" key={index} onClick={()=> setNewBreadCrumps(lk,expanded[index])}>{lk}</a>
                                <p className="saperator">/</p>
                            </span>
                            )}
                        <a href={`${props.treeData.id}`}>{props.treeData.title}</a>
                    </span>
                    {props.treeData.description &&
                        <span className="addInfo">
                            {props.treeData.description}
                        </span>
                    }
                </div>
            }

            {(!props.treeData && getSelectedFoler() && pathSlashCnt > 1) &&
                <div className="treeInfo">
                    <span className="pathInfo">
                        <FolderOpenIcon  style={{marginRight: '5px'}}/>
                        <p>{getSelectedFoler().selectedName}</p>
                    </span>
                </div>
            }

            <Snackbar open={infoShow} autoHideDuration={10000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {
                        infoMessage.split("|").map((message,index) =>
                            <span style={{display: 'block'}} key={index}>{infoMessage.split("|").length > 1 ? index + " - ": ''} {message} </span>
                        )
                    }
                </Alert>
            </Snackbar>

            <div className="HeaderRight">

                {(props.DeployManageActionsClick && props.DeployManageActionsStat === 'New') &&
                <div className="DeployManageActions">
                    <span className="actions title"> Deploy Actions </span>
                     <span className="actions approve">
                        <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title="Approve" >
                            <span>
                                  <IconButton disabled={!acceptActionsEnabled} onClick={()=>{ ApproveClicked() }}>
                                   <ApproveIcon />
                                </IconButton>
                            </span>
                        </HtmlTooltip>
                    </span>

                    <span className="actions cancel">
                         <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title="Cancel" >
                             <span>
                                <IconButton disabled={!acceptActionsEnabled} onClick={()=>{ CancelClicked() }}>
                                    <CancleIcon />
                                </IconButton>
                             </span>
                         </HtmlTooltip>
                    </span>

                    <span className="actions reject">
                         <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title="Reject" >
                             <span>
                                 <IconButton disabled={!acceptActionsEnabled} onClick={()=>{  RejectClicked() }}>
                                    <RejectIcon />
                                </IconButton>
                             </span>
                        </HtmlTooltip>
                    </span>
                </div>
                }

                {props.DiagramSaveClick &&
                <div className='diagramControls'>
                    <div className="UndoRedoControls">
                        <Tooltip title="Ctrl+Z">
                        <IconButton  size="small" sx={{ ml: 2 }} onClick={() => props.UndoClick()}> <UndoIcon />  </IconButton>
                        </Tooltip>
                        <Tooltip title="Ctrl+Y">
                        <IconButton  size="small" sx={{ ml: 2 }} onClick={() => props.RedoClick()}> <RedoIcon />  </IconButton>
                        </Tooltip>
                    </div>
                    <Tooltip title="Ctrl+R">
                    <div className={`emulate ${props.EmulateErr ? "err" : ""} `} onClick={() => props.EmulateClick()}> <PlayCircleFilledWhiteIcon /></div>
                    </Tooltip>
                    <Tooltip title="Ctrl+S">
                         <span>
                              <Button variant="contained" disabled={!TreeSaveEnabled} className="SaveButton" onClick={() => props.DiagramSaveClick()} ><SaveIcon className="diagram_button_icons" /> Save</Button>
                         </span>
                    </Tooltip>

                        <span>
                            <Button variant="contained" className="DeployButton"
                                    disabled={props.DeployDisabled}
                                    aria-controls={DeployMenuOpen ? 'deploy-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={DeployMenuOpen ? 'true' : undefined}
                                    onClick={handleDeployClick} ><DoubleArrowIcon className="diagram_button_icons" /> Deploy</Button>
                      <Menu
                          id="deploy-menu"
                          MenuListProps={{
                              'aria-labelledby': 'deploy-button',
                          }}
                          anchorEl={deployMenuAnchorEl}
                          open={DeployMenuOpen}
                          onClose={handledeployMenuClose}
                          // TransitionComponent={Fade}
                          PaperProps={{
                              style: {
                                  width: 190,
                              },
                              elevation: 0,
                              sx: {
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                  mt: 1.5,
                                  '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                  },
                                  '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                  },
                              },
                          }}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                          <MenuItem disabled = {!DeployActionsEnabled} onClick={DeployNowClicked}><DeployIcon /><span> Deploy Now</span></MenuItem>
                          <Divider light />
                          <MenuItem disabled = {!DeployRequestActionsEnabled} onClick={DeployRequestClicked}><DeployRequestIcon /> <span> Deploy Request </span></MenuItem>
                      </Menu>
                        </span>


                    <div className="moreActions">
                        <Tooltip title="More Actions">
                        <IconButton onClick={(e) => props.moreActionsClick(e)}> <MoreVertIcon /> </IconButton>
                        </Tooltip>
                    </div>
                </div>
                }
                <div className="avatar">
                    <IconButton
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={handleClick}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        anchorEl={userMenuOpen}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            style: {
                                width: 340,
                            },
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <AccountCircleIcon className="accountIcon" />
                            <div className="userInfo" title={userInfo.full_name}>
                                <span className="userFullName">{userInfo.full_name}</span>
                                <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title={userInfo.user_name ? userInfo.user_name : ''} >
                                <span className="userName">{userInfo.user_name}</span>
                                </HtmlTooltip>
                            </div>

                        </MenuItem>
                        <Divider className="diviverNoMargin" />
                        <MenuItem onClick={() => {setSettingsPopupOpen(true)}}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <span>Settings</span>
                        </MenuItem>
                        <Divider className="diviverNoMargin" />
                        <MenuItem onClick={()=>{setChangePassPopupOpen(true)}}>
                            <ListItemIcon>
                                <KeyIcon fontSize="small" />
                            </ListItemIcon>
                            <span>Change password</span>
                        </MenuItem>
                        <Divider className="diviverNoMargin" />
                        <MenuItem onClick={() => {setConfirmDialog(true)}}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            <span>Logout</span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>

            <Dialog PaperProps={{ className: 'ChangePassPopup' }} onClose={ChangePassPopupClose} open={ChangePassPopupOpen} disableEnforceFocus>
                <div style={{position: 'releative'}}>
                    <DialogTitle style={{display: 'flex'}}> <KeyIcon fontSize="small" /> Change password for - {userInfo.user_name}</DialogTitle>
                    <CloseIcon onClick={()=> { ChangePassPopupClose() }} className='ChangePassPopup_close_button'/>
                </div>
                <Divider />
                <div className="ChangePassPopupContent">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >

                        <div style={{minHeight: '258px'}}>

                            <div className="StyledForm">
                                <Box sx={{ position: 'relative', marginBottom: '30px' }}>
                                    <TextField
                                        label='Old Password'
                                        value={OldPass}
                                        onChange={(e) => setOldPass(e.target.value)}
                                        placeholder="Enter Your Old Password"
                                        type={showOldPassword ? "text" : "password"}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowOldPassword}
                                                        onMouseDown={handleMouseDownOldPassword}
                                                    >
                                                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box sx={{ position: 'relative', marginBottom: '30px' }}>
                                    <TextField
                                        label='Password'
                                        value={Pass}
                                        onChange={(e) => setPass(e.target.value)}
                                        placeholder="Enter Your Password"
                                        type={showPassword ? "text" : "password"}
                                        fullWidth
                                        error = {twoNewPassMatch()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <TextField
                                        label='Repeat Password'
                                        value={RptPass}
                                        onChange={(e) => setRptPass(e.target.value)}
                                        type={showRptPassword ? "text" : "password"}
                                        placeholder="Repeat Your new Password"
                                        fullWidth
                                        helperText={twoNewPassMatch() ? "New password and Repeat password not mtach" : ''}
                                        error = {twoNewPassMatch()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowRptPassword}
                                                        onMouseDown={handleMouseDownRptPassword}
                                                    >
                                                        {showRptPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>

                            </div>
                            <p style={{fontStyle: 'italic', color: '#b8ab9b', fontSize: '12px'}}>* Password should contain at least one number, one special character, one uppercase, one lowercase, from 8 up to 100 symbols
                            </p>
                        </div>

                        <Button className="ChangePassCancel_button" onClick={()=>{ChangePassPopupClose()}}><CloseIcon className="icons_margin" /> Cancel</Button>
                        <Button className="ChangePassOk_button" onClick={()=>{ChangePassProcedure()}}><DoneIcon className="icons_margin" /> Change password</Button>
                    </Box>

                </div>
            </Dialog>



            <Dialog PaperProps={{ className: 'SettingsPopup' }} onClose={SettingsPopupClose} open={SettingsPopupOpen} disableEnforceFocus>
                <div style={{position: 'releative'}}>
                    <DialogTitle style={{display: 'flex'}}> <SettingsIcon fontSize="small" /> Settings</DialogTitle>
                    <CloseIcon onClick={()=> { SettingsPopupClose() }} className='SettingsPopup_close_button'/>
                </div>
                <Divider />
                <div className="SettingsPopupContent">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >

                        <div style={{minHeight: '458px'}}>
                            <div className="StyledForm">

                                <Box
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 458 }}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={Settingsvalue}
                                        onChange={settingsTabCangeChange}
                                        aria-label="Settings"
                                        sx={{ borderRight: 1, borderColor: 'divider' , width: 185}}
                                    >
                                        <Tab label="TimeZone" {...a11yProps(0)} />
                                        <Tab label="Other" {...a11yProps(1)} />
                                    </Tabs>
                                    <TabPanel value={Settingsvalue} index={0}>
                                        <div>
                                            <div className='settingsTitle'>TimeZone Settings</div>
                                            <div className="timezone--wrapper">
                                                <TimezoneSelect
                                                    isSearchable = {true}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'rgba(168,163,219,0.37)',
                                                            primary: '#a8a3db',
                                                        },
                                                    })}
                                                    className="TimeZoneSelectStyle"
                                                    value={tz}
                                                    onChange={(e)=>{ setTimezone (e)}}
                                                    timezones={{
                                                        ...allTimezones,
                                                        "America/Lima": "Pittsburgh",
                                                        "Europe/Berlin": "Frankfurt",
                                                        "Asia/Baku": "Baku, Tbilisi",
                                                        "Asia/Yerevan": "Yerevan"
                                                    }}
                                                />
                                            </div>
                                            <div className="output-wrapper">
                                                <div>
                                                    Current Date / Time in{" "}
                                                    {tz.value ? tz.value.split("/")[2] : tz.split("/")[1]}:{" "}
                                                    <pre>{datetime.format("DD.MM.YY HH:mm")}</pre>
                                                </div>
                                                <div>
                                                    <div>Selected Timezone:</div>
                                                    <pre>{JSON.stringify(tz, null, 2)}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={Settingsvalue} index={1}>
                                        Other Settings
                                    </TabPanel>
                                </Box>

                            </div>
                        </div>

                        <Button className="SettingsCancel_button" onClick={()=>{SettingsPopupClose()}}><CloseIcon className="icons_margin" /> Cancel</Button>
                        <Button className="SettingsOk_button" onClick={()=>{SettingsProcedure()}}><DoneIcon className="icons_margin" /> OK</Button>
                    </Box>

                </div>
            </Dialog>
    <ConfirmDialog open={confirmDialog} onClose={(e) => { onConfirmDialogClose(e)  }}
                   title="Logout"
                   text= {`Do you want to Logout ?`}
    />
 </div>
    );
}

export default Header;