import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import $ from 'jquery';
import Split from "react-split";
import {DataGrid} from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NoRowsOverlay from "../NoRowsOverlay/NoRowsOverlay";
import '../UserManagement/UserManagement.css';
import '../UserManagement/user.css';
import {
    Alert,
    Avatar,
    Button,
    Chip, Dialog, DialogTitle, Divider,
    FormControl, IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Skeleton, Slide,
    styled, Switch, TextField,
    Tooltip,
    tooltipClasses
} from "@mui/material";
import {
    getTimeZoneCfg, getToken, getUsersRowsPerPage,
    setUsersRowsPerPage
} from "../../utils/Common";
import {makeStyles} from "@mui/styles";
import moment from "moment/moment";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import Menu from "@mui/material/Menu";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddNewIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonIcon from '@mui/icons-material/Person';
import User from "./user";
import CompanyTree from "./CompanyTree";
import Request from "../../utils/request";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Loading from "../Loading/Loading";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import uuid from "react-uuid";
import jwt_decode from "jwt-decode";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        top: '9px',
        backgroundColor: '#FFFFFF',
        color: '#676f7a',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: '15px',
        boxShadow: '5px 5px 10px 0px rgb(171 171 171 / 63%)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    }
}));
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    justifyContent: 'flex-end',
    '& .userInfoTitle': {
        width: '100%'
    }
}));
const drawerWidth = 350;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        /*padding: theme.spacing(3),*/
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

function UserManagement(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [curRowData, setCurRowData] = useState(null);
    const ContextOpen = Boolean(anchorEl);
    const [delay, setDelay] = useState(0);
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [userEnabledStatus, setUserEnabledStatus] = useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(undefined);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(getUsersRowsPerPage());
    const [loading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState([{ field: 'created', sort: 'desc' }]);
    const [userWindowOpen, setUserWindowOpen] = useState(false);
    const [companyList , setCompanyList] = useState([]);
    const [selectedId , setSelectedId] = useState('');
    const [, setSelectedName] = useState('');
    const [, setSelectedParentId] = useState(0);
    const [popupOpenMode , setPopupOpenMode] = useState('add');
    const [userActive , setUserActive] = useState(true);
    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

    const [allowUserInvite, setAllowUserInvite] = useState(false);


    const userInfo = jwt_decode(getToken());
    useEffect(()=>{
         if (userInfo){
            let clime = userInfo.security_claim;
            if (!Array.isArray(clime)) {
                clime = [clime];
            }
            for (let i = 0; i < clime.length; i++) {
                if (clime[i] === 'user.invite'){
                    setAllowUserInvite (true);
                    break ;
                }
            }
        }
        },[userInfo])

    const columns = [
        {  field: 'id',
            headerName: 'ID',
            width: 40,
            type: "number",
            headerAlign: 'left',
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            hide: true,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={20} animation="wave" />
                        :
                        <>{params.row.id}</>

                    }
                </div>
            )
        },
        {
            field: 'email',
            headerName: 'Username',
            minWidth: 250,
            width: 250,
            flex:1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: true,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden', width:'100%', display: 'flex', flexDirection: 'row',alignItems: 'center'}}>
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        <>
                        <HtmlTooltip  className="ToolTipStyle"  arrow placement="right" title={params.row.email} >
                            <span className="table-cell-trucate">
                                {params.row.email}
                            </span>
                        </HtmlTooltip>
                        </>
                    }
                </div>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 230,
            width: 230,
            flex:1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: true,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden', width:'100%', display: 'flex', flexDirection: 'column'}}>
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        <>
                            <HtmlTooltip  className="ToolTipStyle"  arrow placement="left" title={params.row.name} >
                            <span className="table-cell-trucate">
                                {params.row.name}
                            </span>
                            </HtmlTooltip>
                        </>
                    }
                </div>
            )
        },
        {
            field: 'created',
            headerName: 'Created',
            minWidth: 240,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: true,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <HtmlTooltip  className="ToolTipStyle" arrow placement="left" title={params.row.createdBy ? params.row.createdBy : ''} >
                            <div>
                                <span style={{display: 'block',padding: 0, fontSize: '12px' , textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden', width: '240px'}} > {params.row.createdBy}</span>
                                <span style={{display: 'block',padding: 0, fontSize: '10px', color: '#747474', fontStyle: 'italic'}} >
                                {params.row.created ? moment(new Date(moment(params.row.created, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss') : ''}
                                </span>
                            </div>
                        </HtmlTooltip>

                    }
                </div>
            )
        },
        {
            field: 'enabled',
            headerName: 'Enabled',
            minWidth: 90,
            // flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            width: 90,
            headerAlign: "center",
            headerClassName: 'JustifyCenter',
            align: 'center',
            renderCell: (params) =>  (
                <div>
                    { loading ? <Skeleton width={90} animation="wave" /> :
                        <div>
                        <span className="actions">
                            <Switch
                                inputProps={{'aria-label': 'Switch A'}}
                                checked={params.row.enabled}
                                onChange={(e) => {
                                    handleEnabledChange (params.row.id, e.target.checked, params.row.email)
                                }}
                            />
                               </span>
                        </div>
                    }
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: '',
            minWidth: 110,
            // flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            width: 110,
            headerAlign: "center",
            headerClassName: 'JustifyCenter',
            align: 'center',
            renderCell: (params) =>  (
                <div>
                    { loading ? <Skeleton width={90} animation="wave" /> :
                        <div>
                    <span className="actions">
                        <IconButton
                            aria-controls={ContextOpen ? 'context-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={ContextOpen ? 'true' : undefined}
                            onClick={(e) => ContexthandleClick(e, params.row)} >
                            <MoreVertTwoToneIcon />
                        </IconButton>
                    </span>
                    <span className="actions">
                         <HtmlTooltip  className="ToolTipStyle" arrow placement="right" title='User information' >
                            <IconButton onClick={()=>{ showUserInfoDetail (params.row) }}>
                               <InfoOutlinedIcon />
                            </IconButton>
                         </HtmlTooltip>
                    </span>
                        </div>
                    }
                </div>
            ),
        }
    ];
    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setDelay(0);
        }
    }
    function capitalize(word) {
        return word[0].toUpperCase() + word.substring(1);
    }
    function SlideTransition(props) {
        return <Slide {...props} direction="up"/>;
    }
    //Pagination
    const useStyles = makeStyles({
        root: {
            display: 'flex',
        },
    });
    function CustomPagination() {
        const classes = useStyles();
        return (
            <div className="GridFooter">
                <div className="pageSelect">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="pageSelect-label">Rows per page</InputLabel>
                        <Select
                            id="pageSelect"
                            value={pageSize}
                            label="Rows per page"
                            onChange={(e) => handlePageSizeChange(e.target.value)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="Pages">
                    <Pagination
                        className={classes.root}
                        color="primary"
                        variant="outlined"
                        count={Math.ceil(rowCount / pageSize) || 1}
                        page={page}
                        onChange={(event, value) => handlePageChange(value)}
                    />
                </div>
            </div>
        );
    }
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevSearchValue = usePrevious(searchValue);
    const handlePageChange = (newPage) => {
        setPage(newPage);
        GetUserList(Math.ceil(newPage === 1 ? 0 : (newPage-1) * pageSize, (newPage + 1) * pageSize))
    };
    const handlePageSizeChange = (count) => {
        setPageSize(count)
        setUsersRowsPerPage(count)
    }
    // End of pagination

    const GetUserList = (start = 0 , limit = pageSize) => {
        let UsersReqBody = {
            "StartIndex": start,
            "RowCount": limit,
            "SortBy": [
                {
                    "Field": sortModel[0] ? capitalize(sortModel[0].field) : "Created",
                    "Direction": sortModel[0] ? sortModel[0].sort : "Desc"
                }
            ],
            "Filter": {
                "Operator": "And",
                "Filters": [
                    {
                        "Field": "IsActive",
                        "Value": userActive,
                        "Operator": "Equals"
                    },
                    {
                        "Field": "companyId",
                        "Value": selectedId,
                        "Operator": "Equals"
                    },
                    {
                        "Field": "email",
                        "Value": searchValue,
                        "Operator": "Contains"
                    },
                ]
            }
        };

        setLoading(true);
        Request(window._env_.REACT_APP_API_TREE_LIST + 'user/list').post(UsersReqBody)
            .then((data) => {
                    setLoading(false);
                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error')
                        } else if (data.message){
                            ShowInfo(data.message, 'warning')
                        } else if (data) {
                            setRows(data.rows)
                            setRowCount(data.total)
                       } else {
                            setRows([])
                            setRowCount(0)
                            setLoading(false);
                            ShowInfo('Cannot Load User\'s', 'error')
                        }
                     }
                )
    }

    useEffect(()=>{
        setLoading(true);
        if (userEnabledStatus === true) {setUserEnabledStatus(false)}

        if(prevSearchValue !== searchValue) {
            const timeoutId = setTimeout(() =>{
                GetUserList();
            }, delay);
            return () => {
                clearTimeout(timeoutId)
                if (delay === 0 ) {setDelay(2000)}
            };
        } else {
                GetUserList();
        }
        // eslint-disable-next-line
    },[searchValue,delay,pageSize,sortModel,selectedId,userActive,userEnabledStatus,userWindowOpen])

    //User Enabled change
    const UserEnabled = (id , status,email) => {
        let UserEnabledReqBody = {
            "id": id,
            "enabled": status
        };

        //   setLoading(true);
        Request(window._env_.REACT_APP_API_TREE_LIST + 'user/enabled').patch(UserEnabledReqBody)
            .then((data) => {
                    setLoading(false);
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    } else if (data.message){
                        ShowInfo(data.message, 'warning')
                    } else if (data) {
                        ShowInfo('User: ' + email + " - STATUS changed", 'success')
                        setUserEnabledStatus(true)
                    } else {
                        ShowInfo('Cannot change User Status', 'error')
                    }
                }
            )
    }
    const DeleteUserProcess = (id) => {
        if (id){
            Request(window._env_.REACT_APP_API_TREE_LIST + 'user').del(id)
                .then((e)=>{
                    if(e && e.status === 'ok') {
                        ShowInfo('User "' + curRowData.name + '" is successfully DELETED', 'success')
                        GetUserList();
                        if (props.onFolderTreeChnage) props.onFolderTreeChnage('delete')
                    } else {
                        ShowInfo('Cannot Delete User "' + curRowData.name, 'error')
                    }
                })
        }
    }
    const handleEnabledChange = (id , status, email) => {
        UserEnabled(id,status,email)
    }
    // User Enabled change End
    const showUserInfoDetail = (info) => {
        setCurRowData(info);
        handleDrawerOpen();
        setTimeout(() => {
            const virtualScroller = $('.MuiDataGrid-virtualScroller');
            const virtualScrollerContent = $('.MuiDataGrid-virtualScrollerContent');
            const totalWidth = virtualScrollerContent[0].scrollWidth;
            virtualScroller.scrollLeft(totalWidth);
        }, 400);
    }
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };
    const handleInfoClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };
    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    }
    const ContexthandleClick = (event, curRow) => {
        setAnchorEl(event.currentTarget);
        setCurRowData(curRow)
    };
    const ContexthandleClose = () => {
        setAnchorEl(null);

    };
    const userPopupClose = () => {
        setUserWindowOpen(false);
       if (popupOpenMode !== 'edit') {
           setUserActive(false);
       }
    }
    const handleResponseInfo = (params) => {
        ShowInfo(params.message , params.type);
    }
    const onDeleteConfirmDialogClose = (result, id) => {
        setDeleteConfirmDialog(false);
        if (result && id) {
            setTimeout(function (){
                DeleteUserProcess(id)
            },0)
        }
    };

    return (
        <div>
            {(loading && rows.length <= 0) &&
                <Loading fullscreen />
            }
            <div className="GridWrapper">
                <Split
                    style = {{display: 'flex' , width: '100%' }}
                    sizes={([20, 80])}
                    cursor='col-resize'
                    minSize={20}
                >
                    <CompanyTree companyList={(cpmList)=>{setCompanyList(cpmList.childs)}}
                                 selectedID={(id) => { setSelectedId (id === '0' ? '' : id)}}
                                 selectedName={(selectedName) => {setSelectedName(selectedName)}}
                                 SelectedParentId={(SelectedParentId) => {setSelectedParentId(SelectedParentId)}
                    }/>
                <div className="UserManagementGrid" >
                    <Box sx={{ display: 'flex' ,height: 'inherit'}}>
                    <Main open={drawerOpen}>
                    <div className="controls">
                        <TextField
                            variant="outlined"
                            value={searchValue}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={()=>{ setSearchValue(''); }}>
                                        {searchValue ?  <CloseIcon /> : <SearchIcon />}
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search by name or username"
                            onChange={(e) => { setSearchValue(e.target.value)  }}
                            onKeyDown={_handleKeyDown}
                        />

                        <div className="UserAvtiveSwitch">
                            <span>Inactive</span>
                            <Switch
                                inputProps={{'aria-label': 'Switch B'}}
                                checked={userActive}
                                onChange={(e) => {
                                    setUserActive(e.target.checked)
                                }}
                            />
                            <span>Active</span>
                        </div>

                        <HtmlTooltip  className="ToolTipStyle" arrow placement="left" title='Add new user' >
                            <Button onClick={()=>{
                                setPopupOpenMode('add');
                                setUserWindowOpen(true);
                            }}> <AddNewIcon /></Button>
                        </HtmlTooltip>
                    </div>
                    <DataGrid
                        sx={{ m: 2 }}
                        rows={rows}
                        columns={columns}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        hideFooterSelectedRowCount={true}
                        showCellRightBorder={false}
                        disableSelectionOnClick
                        checkboxSelection = {false}
                        pagination
                        components={{
                            ColumnUnsortedIcon: () => <ArrowDropDownIcon />,
                            Pagination: CustomPagination,
                            NoRowsOverlay: () => <NoRowsOverlay />,
                        }}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        id="context-menu"
                        open={ContextOpen}
                        onClose={ContexthandleClose}
                        onClick={ContexthandleClose}
                        transitionDuration = {0}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 0.7,
                                width:200,
                                backgroundColor: 'white',

                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        <MenuItem onClick={() => {
                            setPopupOpenMode('edit');
                            setUserWindowOpen(true);
                        }} >
                            <DriveFileRenameOutlineRoundedIcon /> <span className="menuItemText">Edit</span>
                            <Divider />
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setDeleteConfirmDialog(true);
                        }} >
                            <DeleteSweepIcon /> <span className="menuItemText">Delete</span>
                            <Divider />
                        </MenuItem>
                    </Menu>
                    </Main>

                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                position: 'unset',
                                marginLeft: '10px'
                            },
                        }}
                        variant="persistent"
                        anchor="right"
                        open={drawerOpen}
                    >

                        <DrawerHeader>
                            <span className='userInfoTitle'>User information</span>
                            <IconButton onClick={handleDrawerClose}>
                                <CloseIcon />
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <div className='UserInfoDetails'>
                            <div style={{alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Avatar sx={{ bgcolor: 'silver' , width: 65, height: 65}} >
                                    <PersonIcon sx={{fontSize: '35px'}}/>
                                </Avatar>
                            </div>
                            {curRowData &&
                                <div className="UserDetails">
                                    <span>{curRowData.name}</span>
                                    <span style={{fontSize: '12px',color: '#847f7f', marginBottom: '3px'}}>{curRowData.email}</span>
                                    <span style={{fontSize: '12px',color: '#847f7f', marginBottom: '5px'}}>ID: {curRowData.id}</span>
                                    <div className="StatusDetail">
                                        <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={`${curRowData.isActive ? 'Activated user' : 'Invited but not activated user'}`} >
                                            <span className={` ${curRowData.isActive ? 'Active' : 'notActive'}`}>{curRowData.isActive ? 'ACTIVATED' : 'NOT ACTIVE'}</span>
                                        </HtmlTooltip>
                                    </div>
                                    <Divider />
                                    <div className='createdDetails'>
                                        <span className='DetailsLabel'>
                                            CREATED
                                        </span>
                                        <span className='DetailsText'>{moment(new Date(moment(curRowData.created, 'DD/MM/YYYY HH:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')}</span>
                                        <span className='DetailsText'>{curRowData.createdBy}</span>
                                    </div>

                                    <span className='DetailsLabel'>
                                            COMPANIES
                                        </span>
                                    <div className="CompaniesDetail">

                                        {
                                            curRowData.companies.map((cmpItem,index) =>
                                                <Chip key={index} size='small' label={cmpItem.name} />
                                            )
                                        }
                                    </div>

                                    <span className='DetailsLabel'>
                                            CLAIMS
                                        </span>
                                    <div className="ClimesDetail">

                                        {
                                            curRowData.claimGroups.map((clmtem,index) =>
                                                <HtmlTooltip key={index} className="ToolTipStyle"  arrow placement="top" title={clmtem.description} >
                                                     <Chip key={index} size='small' label={clmtem.name} />
                                                </HtmlTooltip>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                    </Drawer>
                    </Box>
                </div>
                </Split>
                {companyList && allowUserInvite &&
                    <Dialog PaperProps={{ className: 'UserPopup' }} onClose={userPopupClose} open={userWindowOpen}>
                        <div style={{position: 'releative'}}>
                            <DialogTitle>{`${popupOpenMode === 'edit' ? 'Edit User': 'New User'}`}</DialogTitle>
                            <CloseIcon onClick={()=> { userPopupClose() }} className='UserPopup_close_button'/>
                        </div>
                        <Divider />
                        <User companyList={companyList} popupOpenMode={popupOpenMode} userPopupClose={userPopupClose} userinfo={curRowData} handleResponseInfo={handleResponseInfo}/>
                    </Dialog>
                }

            </div>

            <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {infoMessage}
                </Alert>
            </Snackbar>
            <ConfirmDialog open={deleteConfirmDialog} onClose={(e) => { onDeleteConfirmDialogClose(e, curRowData.id)  }}
                           title="Warning"
                           text= {[`Delete  user "${curRowData ? curRowData.name : null}"`, <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, "Are you sure ?"]}
            />
        </div>
    )
}

export default UserManagement;