import React from "react";
import ReactDOM from "react-dom";
import App from './Components/App/App';
import {getLanguage, setLanguage} from "./utils/Common";
import {Helmet} from "react-helmet";


let lang = null;
const helmet = <Helmet>
    <title>LogiX</title>
</Helmet>

if (getLanguage() == null ){
    setLanguage( 'en');
}
lang = getLanguage();
ReactDOM.render(<App lang={lang} helmet={helmet}/>, document.getElementById("root"));
