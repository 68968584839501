import { DataGrid } from '@mui/x-data-grid';
import Request from "../../utils/request";
import {useEffect, useRef, useState} from "react";
import Loading from "../Loading/Loading";
import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import {
    Alert,
    Button, Checkbox, Dialog, DialogTitle, Divider, FormControl, FormControlLabel,
    IconButton,
    InputAdornment, InputLabel, MenuItem, Select,
    Skeleton, Slide,
    Switch, Tab, Tabs, TextareaAutosize,
    TextField,
    Tooltip,
    tooltipClasses
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {styled} from "@mui/material";
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ContentCopyIcon from '@mui/icons-material/CopyAll';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import RenameIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useNavigate} from "react-router-dom";
import '../Trees/Trees.css'
import Menu from "@mui/material/Menu";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {Pagination} from "@mui/material";
import {makeStyles} from "@mui/styles";
import NoRowsOverlay from "../NoRowsOverlay/NoRowsOverlay";
import {
    getBreadCrumps,
    getdeployDraftState,
    getRowsPerPage, getSelectedFoler, getTimeZoneCfg, getToken, removeSelectedFoler, setBreadCrumps,
    setdeployDraftState,
    setRowsPerPage,
    setSelectedFoler
} from "../../utils/Common";
import FolderTree from "../FolderTree/FolderTree";
import Split from "react-split";
import Box from "@mui/material/Box";
import  {LocalizationProvider}  from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment/moment";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import {useCallForward, useCallHolder} from "react-callforward";

import DraftIcon from '@mui/icons-material/HighlightAlt';
import CurrentIcon from '@mui/icons-material/CellTower';
import UpcommingIcon from '@mui/icons-material/Alarm';
import AddNewIcon from '@mui/icons-material/Add';
import Fade from "@mui/material/Fade";


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        top: '9px',
        backgroundColor: '#FFFFFF',
        color: '#676f7a',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: '15px',
        boxShadow: '5px 5px 10px 0px rgb(171 171 171 / 63%)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    }
}));

const SelectedTab = {
    draft : 0,
    current : 1,
    upcomming : 2
}

function TreeList(props) {

    //Pagination style
    const useStyles = makeStyles({
        root: {
            display: 'flex',
        },
    });
    function CustomPagination() {
        //  const { state, apiRef } = useGridSlotComponentProps();
        const classes = useStyles();
        return (
            <div className="GridFooter">


            <div className="pageSelect">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="pageSelect-label">Rows per page</InputLabel>
                    <Select
                        id="pageSelect"
                        value={pageSize}
                        label="Rows per page"
                        onChange={(e) => handlePageSizeChange(e.target.value)}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </div>
                <div className="Pages">
                <Pagination
                    className={classes.root}
                    color="primary"
                    variant="outlined"
                    count={Math.ceil(rowCount / pageSize) || 1}
                    page={page}
                    onChange={(event, value) => handlePageChange(value)}
                />
                </div>
            </div>

        );
    }
    const handlePageChange = (newPage) => {
        setPage(newPage);
        GetTreeList(Math.ceil(newPage === 1 ? 0 : (newPage-1) * pageSize, (newPage + 1) * pageSize))
    };

    let Model = {
        "code": "",
        "title": "",
        "description": "",
        "openDate": null,
        "closeDate": null,
        "displayData": {},
        "enabled": true,
        "model": {
            StoreEntity: false,
            ThrowIfNotFinished: true,
            Nodes: []
        },
        // "storeEntity": false,
        "checksum": ''
    }
    const [company,setCompany] = useState(props.selectedCompany);
    const [loading, setLoading] = useState(false);
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [rows, setRows] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [treeEnabledStatus, setTreeEnabledStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [CloneStatus, setCloneStatus] = useState(false);
    const [RenameStatus, setRenameStatus] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const [delay, setDelay] = useState(0);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [curRowData, setCurRowData] = useState(null);
    const ContextOpen = Boolean(anchorEl);

    const [rowCount, setRowCount] = useState(undefined);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(getRowsPerPage());

    const [TreePopupOpen, setTreePopupOpen] = useState(false);
    const [RenameTreePopupOpen, setRenameTreePopupOpen] = useState(false);

    const [clonableTreeData, setClonableTreeData] = useState(Model);

    const [renamableTreeData, setRenamableTreeData] = useState(Model);

    const [selectedFolderId, setSelectedFolderId] = useState(company);
    const [, setSelectedFolderParentId] = useState('');

    const [selectedFolderName, setSelectedFolderName] = useState('root');

    const [ , setFoldersTreeData] = useState('');

    const [deployDraft, setDeployDraft] = useState(SelectedTab.draft);

    const [TitleError, setTitleError] = useState(false);
    const [CodeError, setCodeError] = useState(false);

    const [RenameTitleError, setRenameTitleError] = useState(false);
    const [RenameCodeError, setRenameCodeError] = useState(false);


    const [CompanyList, setCompanyList] = useState(props.CompanyList);
    const [selectedCompany, setselectedCompany] = useState(props.selectedCompany);

    const [ExpandFolders , setExpandFolders] = useState(getBreadCrumps() ? getBreadCrumps().ExpandFolders : []);

    const [FolderTreeClick, FolderTreeClicked] = useCallForward();


    const [ClonestoreEntity , setCloneStoreEntity] = useState(false);
    const [CloneThrowIfNotFinished , setCloneThrowIfNotFinished] = useState(true);


    const [RenamestoreEntity , setRenameStoreEntity] = useState(false);
    const [RenameThrowIfNotFinished , setRenameThrowIfNotFinished] = useState(true);


    const [sortModel, setSortModel] = React.useState([
        { field: 'id', sort: 'desc' },
    ]);


    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 40,
            type: "number",
            headerAlign: 'left',
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={20} animation="wave" />
                        :
                        <span className="table-cell-trucate">{params.row.id}</span>
                    }
                </div>
            )
        },
        {
            field: 'code',
            headerName: 'Code',
            minWidth: 160,
            flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden'}}  title = {params.row.code}>
                    { loading ?
                        <Skeleton width={130} animation="wave" />
                        :
                        <span className="table-cell-trucate">{params.row.code}</span>
                    }
                </div>
            )
        },
        {
            field: 'abVersion',
            headerName: 'Version',
            type: 'number',
            width: 100,
            headerAlign: 'left',
            hide: deployDraft === SelectedTab.draft,
            align: 'left',
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={50} animation="wave" />
                        :
                        <span className="table-cell-trucate">{params.row.abVersion}</span>
                    }
                </div>
            )
        },
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 150,
            flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            width: 160,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden'}}  title = {params.row.description ? params.row.description : ''}>
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        <HtmlTooltip  className="ToolTipStyle"  arrow placement="top" title={params.row.description ? params.row.description : ''} >
                            <span className="table-cell-trucate">{params.row.title}</span>
                        </HtmlTooltip>
                    }
                </div>
            ),

        },
        {
            field: 'folderPath',
            headerName: 'Folder Path',
            minWidth: 150,
            flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            width: 150,
            renderCell: (params) =>  (
                <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    { loading ? <Skeleton width={110} animation="wave" /> :
                        <HtmlTooltip  className="ToolTipStyle" arrow placement="top" title={params.row.folderPath ? params.row.folderPath : ''} >
                            <span className="table-cell-trucate" >{params.row.folderPath}</span>
                        </HtmlTooltip>
                    }
                </div>
            ),

        },
        {
            field: 'modifiedOn',
            headerName: 'Modified On',
            type: 'date',
            width: 155,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (

                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <span className="table-cell-trucate">{
                             moment(new Date(moment(params.row.modifiedOn, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')

                        }</span>
                    }
                </div>
            )
        },
        {
            field: 'modifiedBy',
            headerName: 'Modified By',
            type: 'string',
            minWidth: 140,
            width: 140,
            flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                 <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <HtmlTooltip  className="ToolTipStyle" arrow placement="left-start" title={params.row.modifiedBy ? params.row.modifiedBy : ''} >
                           <span className="table-cell-trucate">{params.row.modifiedBy}</span>
                        </HtmlTooltip>
                    }
                </div>
            )
        },
        {
            field: 'openDate',
            headerName: 'Start date',
            type: 'date',
            width: 170,
            editable: false,
            hide: deployDraft === SelectedTab.draft,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <span className="table-cell-trucate">
                            {params.row.openDate && moment(new Date(moment(params.row.openDate, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')}
                        </span>
                    }
                </div>
            )
        },
        {
            field: 'closeDate',
            headerName: 'End date',
            type: 'date',
            width: 170,
            editable: false,
            hide: deployDraft === SelectedTab.draft,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) =>  (
                <div>
                    { loading ?
                        <Skeleton width={90} animation="wave" />
                        :
                        <span className="table-cell-trucate">
                            {params.row.closeDate && moment(new Date(moment(params.row.closeDate, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')}

                        </span>
                    }
                </div>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 150,
            // flex: 1,
            editable: false,
            disableColumnSelector: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            width: 160,
            headerAlign: "center",
            headerClassName: 'JustifyCenter',
            align: 'center',
            renderCell: (params) =>  (
                <div>
                    { loading ? <Skeleton width={90} animation="wave" /> :
                        <div>
                    <span className="actions">
                        <IconButton onClick={()=>{ navigate ('/diagram/' + params.row.code + "/" + params.row.id)}}>
                           <DriveFileRenameOutlineRoundedIcon />
                        </IconButton>
                    </span>
                            {deployDraft !== SelectedTab.draft &&
                                <span className="actions">
                            <Switch
                                inputProps={{'aria-label': 'Switch A'}}
                                checked={params.row.enabled}
                                onChange={(e) => {
                                    handleEnabledChange(params.row.id, e.target.checked, params.row.checksum)
                                }}
                            />
                               </span>
                            }

                            <span className="actions">
                        <IconButton
                            aria-controls={ContextOpen ? 'context-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={ContextOpen ? 'true' : undefined}
                            onClick={(e) => ContexthandleClick(e, params.row)} >
                            <MoreVertTwoToneIcon />
                        </IconButton>
                    </span>
                        </div>
                    }
                </div>
            ),
        }
    ];


    const ContexthandleClick = (event, curRow) => {
        setAnchorEl(event.currentTarget);
        setCurRowData(curRow)
    };
    const ContexthandleClose = () => {
        setAnchorEl(null);

    };



    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevSearchValue = usePrevious(searchValue);


    useEffect(()=>{
        let BreadCrumps = getBreadCrumps();
        if (BreadCrumps){
            BreadCrumps.ExpandFolders = ExpandFolders;
            setBreadCrumps(BreadCrumps);
        }
        // eslint-disable-next-line
    },[ExpandFolders])


    useEffect(()=>{
        setLoading(true);
        if (treeEnabledStatus === true) {setTreeEnabledStatus(false)}
        if (deleteStatus === true) {setDeleteStatus(false)}
        if (CloneStatus === true) {setCloneStatus(false)}
        if (RenameStatus === true) {setRenameStatus(false)}
        if (getdeployDraftState()) {setDeployDraft(Number(getdeployDraftState()))}

        if(prevSearchValue !== searchValue) {
            const timeoutId = setTimeout(() =>{
              GetTreeList();
            }, delay);
            return () => {
                clearTimeout(timeoutId)
                if (delay === 0 ) {setDelay(2000)}
            };
        } else {
            if (company !==0)
                GetTreeList();
            //console.log ('ddd', selectedFolderName)
        }

        // eslint-disable-next-line
    },[searchValue, delay,treeEnabledStatus,deleteStatus,CloneStatus,RenameStatus,pageSize,sortModel,selectedFolderId,deployDraft,company])

    useEffect(() => {
        setCompany(props.selectedCompany)
        setCompanyList(props.CompanyList);
        setselectedCompany(props.selectedCompany);
        setSelectedFolderId(getSelectedFoler() ? getSelectedFoler().selectedID : company)
        // eslint-disable-next-line
    },[props])




   const handlePageSizeChange = (count) => {
        setPageSize(count)
        setRowsPerPage(count)
    }

    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }


        useCallHolder((e) => {
            console.log (e)
            if (company !==0 && company === e)
                removeSelectedFoler();
                GetTreeList();
        }, FolderTreeClicked);


    const handleEnabledChange = (id , status,checksum) => {
        // const el = document.querySelectorAll('[data-id="'+id+'"]')
        // el[0].innerHTML = "<Skeleton />"
        // console.log (el)
        TreeEnabled(id,status,checksum)
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setDelay(0);
        }
    }

    const handleInfoClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    //Tree Enabled change
    const TreeEnabled = (id , status , checksum) => {
        let TreeEnabledReqBody = {
            "id": id,
            "enabled": status,
            "checksum": checksum
        };

     //   setLoading(true);
      let Resp =  Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/enabled').patch(TreeEnabledReqBody)
        if (Resp){
            //setLoading(false);
            ShowInfo('Tree ID: ' + TreeEnabledReqBody.id + " - STATUS CHANGED", 'success')
            setTreeEnabledStatus(true)
        }
    }
    // Tree Enabled change Enf

    const onConfirmDialogClose = (result) => {
        setConfirmDialog(false);
        if (result) {
            deleteTree(curRowData)
        }
    };

    const TreePopupClose = () => {
        setClonableTreeData({...clonableTreeData,
            code: ''
        })
        setTreePopupOpen(false);
        setSelectedFolderName(getSelectedFoler().selectedName );
        setSelectedFolderId(getSelectedFoler().selectedID);
    }

    const TreeRenameClose = () => {
        setRenamableTreeData({...renamableTreeData,
            code: ''
        })
        setRenameTreePopupOpen(false);
        setSelectedFolderName(getSelectedFoler().selectedName );
        setSelectedFolderId(getSelectedFoler().selectedID);
    }

    function findFolderById(obj, id) {
        if (obj.id === id) {
            return obj;
        }
        for (let i = 0; i < obj.childs.length; i++) {
            const child = obj.childs[i];
            const foundObj = findFolderById(child, id);
            if (foundObj) {
                return foundObj;
            }
        }
        return null;
    }

    function getParentIds(obj,targetObj, ids = []) {
        if (targetObj.parentId) {
            if (targetObj.id) {
                ids.push(targetObj.id);
            }
            const parentObj = findFolderById(obj, targetObj.parentId.toString());
            ids.push(parentObj.id);
            getParentIds(obj,parentObj, ids);
        } else {
            ids.push(targetObj.id);
        }
        return [...new Set(ids)];
    }

    const expandFolderArr = (companyID) => {
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder/tree/' + companyID).get()
            .then((data) => {

                if (data && data.status !== 404) {
                    const targetObj = findFolderById(data, getSelectedFoler().selectedID.toString());

                    if (targetObj) {
                        setExpandFolders(getParentIds(data,targetObj));
                    }
                } else {
                    if (data.status === 404){
                        ShowInfo('Cannot get Folder list', 'error')
                    }
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Cannot get Folder list', 'error')
        })
    }


    const renameTree = (RowData) => {
        let code = RowData.code || null;
        let trId = RowData.id || null;
        let trTitle = RowData.title || null;

        if (code){
            setLoading(true);

            Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/byid/' + trId).get()
                .then((data) => {
                    setLoading(false);
                    if (data && data.status !== 404) {
                        setRenamableTreeData(data);
                        setRenameStoreEntity(data.model.storeEntity);
                        setRenameThrowIfNotFinished(data.model.throwIfNotFinished);

                        RenameCodeFieldValidate(code);
                        RenameTitleFieldValidate(trTitle);
                        const FoldPath = data.folderPath.split('/')
                        const FolderName = FoldPath[FoldPath.length - 1]
                        setSelectedFoler({
                            "selectedID": data.folderId,
                            "selectedName": FolderName
                        })
                        setSelectedFolderName((getSelectedFoler() ? getSelectedFoler().selectedName : FolderName ))
                        setSelectedFolderId((getSelectedFoler() ? getSelectedFoler().selectedID : company ))
                        setRenameTreePopupOpen(true);
                    } else {
                        if (data.status === 404){
                            ShowInfo('Cannot Rename the current Tree -> The Source tree cannot be found', 'error')
                        }

                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error')
                        }
                        if (data.message){
                            ShowInfo(data.message, 'warning')
                        }
                    }
                }).catch(() => {
                ShowInfo('Cannot Rename the current Tree', 'error')
            })
        }

    }

    const treeRenameHandle = () => {
        let cellsModel = {
            Nodes: renamableTreeData.model.nodes
        };

        Model.displayData = renamableTreeData.displayData;
        Model.model = cellsModel;
        Model.code = renamableTreeData.code;
        Model.title = renamableTreeData.title;
        Model.openDate = moment( renamableTreeData.openDate).isValid() ? moment( renamableTreeData.openDate).format("DD/MM/YYYY") : null;
        Model.closeDate = moment( renamableTreeData.closeDate).isValid() ? moment( renamableTreeData.closeDate).format("DD/MM/YYYY") : null;
        Model.description = renamableTreeData.description;
        Model.folderId = selectedFolderId;
        Model.inputData = renamableTreeData.inputData;
        Model.dataFields = renamableTreeData.dataFields;
        Model.storeEntity = RenamestoreEntity;
        Model.ThrowIfNotFinished = RenameThrowIfNotFinished;
        Model.checksum = renamableTreeData.checksum;
        Model.id = renamableTreeData.id;

        if (RenameTitleError || RenameCodeError) {
            ShowInfo("Check 'Code' and 'Title' fields before Rename ", 'error')
        } else {

            setRenameTreePopupOpen(false);

            if (Model.code) {
                setLoading(true);
                Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/rename').post(Model)
                    .then((data) => {
                        setLoading(false);
                        if (data) {
                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error');
                                return;
                            }
                            if (data.message){
                                ShowInfo(data.message, 'warning');
                                return;
                            }

                            if (data.id){
                                setBreadCrumps({
                                    "selectedFolderID": data.folderId,
                                    "treeCode" : data.code,
                                    "treeID" :  data.id,
                                    "companyId" : props.selectedCompany,
                                    "treeVersion" : 0
                                })

                                expandFolderArr(props.selectedCompany);

                                ShowInfo("Successfuly Renamed", 'success')
                                setRenameStatus(true);
                            }

                        }

                    }).catch(() => {
                    ShowInfo('Rename Tree Problem', 'error');
                    setRenameTreePopupOpen(false);
                    setLoading(false);
                })
            } else {
                ShowInfo('Tree CODE is Required *', 'warning');
            }

        }}


    const cloningTree = (RowData) => {
        let code = RowData.code || null;
        let trId =  RowData.id || null;
        let trTitle =  RowData.title || null;

        if (code){
            setLoading(true);

            Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/byid/' + trId).get()
                .then((data) => {
                    setLoading(false);
                    if (data && data.status !== 404) {
                        setClonableTreeData({...data,
                            code: '',
                            openDate: moment(data.openDate, 'DD/MM/YYYY').toDate(),
                            closeDate: moment(data.closeDate, 'DD/MM/YYYY').toDate(),
                        });
                        setCloneStoreEntity(data.model.storeEntity);
                        setCloneThrowIfNotFinished(data.model.throwIfNotFinished);

                        const FoldPath = data.folderPath.split('/')
                        const FolderName = FoldPath[FoldPath.length - 1]
                        setSelectedFoler({
                            "selectedID": data.folderId,
                            "selectedName": FolderName
                        })
                        CodeFieldValidate();
                        TitleFieldValidate(trTitle);
                        setSelectedFolderName((getSelectedFoler() ? getSelectedFoler().selectedName : FolderName ))
                        setSelectedFolderId((getSelectedFoler() ? getSelectedFoler().selectedID : company ))
                        setTreePopupOpen(true);


                    } else {
                        if (data.status === 404){
                            ShowInfo('Cannot clone the current Tree -> The Source tree cannot be found', 'error')
                        }

                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error')
                        }
                        if (data.message){
                            ShowInfo(data.message, 'warning')
                        }
                    }
                }).catch(() => {
                ShowInfo('Cannot clone the current Tree', 'error')
            })
        }
    }

    const treeCloneHandle = () => {

        let cellsModel = {
            StoreEntity: ClonestoreEntity,
            ThrowIfNotFinished : CloneThrowIfNotFinished,
            Nodes: clonableTreeData.model.nodes
        };

        Model.displayData = clonableTreeData.displayData;
        Model.model = cellsModel;
        Model.code = clonableTreeData.code;
        Model.title = clonableTreeData.title;
        Model.openDate = moment( clonableTreeData.openDate).isValid() ? moment( clonableTreeData.openDate).format("DD/MM/YYYY") : null;
        Model.closeDate = moment( clonableTreeData.closeDate).isValid() ? moment( clonableTreeData.closeDate).format("DD/MM/YYYY") : null;
        Model.description = clonableTreeData.description;
        Model.folderId = selectedFolderId;
        Model.inputData = clonableTreeData.inputData;
        Model.dataFields = clonableTreeData.dataFields;
        // Model.storeEntity = clonableTreeData.storeEntity;

        if (TitleError || CodeError) {
            ShowInfo("Check 'Code' and 'Title' fields before Clone ", 'error')
        } else {

        setTreePopupOpen(false);

        if (Model.code) {
            setLoading(true);
            Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree').post(Model)
                .then((data) => {
                    setLoading(false);
                    if (data) {

                        if (data.status !== 200) {
                            if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                                const userInfo = jwt_decode(getToken());
                                ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                                return;
                            }

                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error');
                                return false;
                            }
                            if (data.message){
                                ShowInfo(data.message, 'warning');
                                return false;
                            }
                        }
                        const FoldPath = data.folderPath.split('/')
                        const FolderName = FoldPath[FoldPath.length - 1]
                        setBreadCrumps({
                            "selectedFolderID": data.folderId,
                            "selectedFolderName": FolderName,
                            "treeCode" : data.code,
                            "treeID" :  data.id,
                            "companyId" : data.companyId,
                            "treeVersion" : data.version
                        })

                        expandFolderArr(props.selectedCompany);

                        ShowInfo("Successfuly Cloned", 'success')
                        setCloneStatus(true);
                    }
                }).catch(() => {
                ShowInfo('Cloning Tree Problem', 'error');
                setTreePopupOpen(false);
                setLoading(false);
            })
        } else {
            ShowInfo('Tree CODE is Required *', 'warning');
        }

    }}

    const deleteTree = (RowData) => {
        ///Tree/173
        let rowID = RowData.id || null;
        setLoading(true);
            Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree').del(rowID)
                .then((data) => {
                    setLoading(false);
                    if (data) {
                        if (data && data.status !== 403) {
                            if (data.messageKey && data.messageKey === "gateway:invalid_user") {
                                const userInfo = jwt_decode(getToken());
                                ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                                return;
                            }
                        }

                        ShowInfo('The Tree with ID: ' + rowID + " is successfully DELETED", 'success')
                        setDeleteStatus(true);
                    }
                }).catch(() => {
                ShowInfo('Delete Problem', 'warning');
                setLoading(false);
            })
        }



    function capitalize(word) {
        return word[0].toUpperCase() + word.substring(1);
    }

    const ALPHA_NUMERIC_DASH_UNDERLINE_REGEX = /^[a-zA-Z0-9_-]+$/;

    const CodeFieldValidate = () =>{
        setCodeError(!clonableTreeData.code);
    }

    const TitleFieldValidate = (trTitle) =>{
        if (!clonableTreeData.title){
            setTitleError(!trTitle);
        } else {
            setTitleError(false);
        }

    }

    const RenameCodeFieldValidate = (code) =>{
       // setRenameCodeError(!renamableTreeData.code);

        if (!renamableTreeData.code){
            setRenameCodeError(!code);
        } else {
            setRenameCodeError(false);
        }

    }

    const RenameTitleFieldValidate = (trTitle) =>{
        if (!renamableTreeData.title){
            setRenameTitleError(!trTitle);
        } else {
            setRenameTitleError(false);
        }

    }


    const GetTreeList = (start = 0 , limit = pageSize) => {
        let custFilter = {};
        if (deployDraft === SelectedTab.upcomming) {
            custFilter = {
                "Field": "OpenDate",
                "Value": moment(new Date(moment(new Date(), 'DD/MM/YYYY hh:mm:ss').valueOf() - (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss'),
                "Operator": "GreaterThanOrEqual"
            }
        } else {
            custFilter = {
                "Field": "IsActive",
                "Value": deployDraft !== SelectedTab.draft,
                "Operator": "Equals"
            }
        }

        let TreeReqBody = {
            "StartIndex": start,
            "RowCount": limit,
            "SortBy": [
                {
                    "Field": sortModel[0] ? capitalize(sortModel[0].field) : "id",
                    "Direction": sortModel[0] ? sortModel[0].sort : "Desc"
                }
            ],
            "Filter": {
                "Operator": "And",
                "Filters": [
                    custFilter,
                    {
                        "Field": "Version",
                        "Value": 0,
                        "Operator":  deployDraft !== SelectedTab.draft ? 'NotEquals' : "Equals"
                    },
                    {
                        "Field": "FolderId",
                        "Value": getBreadCrumps() && getBreadCrumps().selectedFolderID ? getSelectedFoler() && getSelectedFoler().selectedID ? getSelectedFoler().selectedID : getBreadCrumps().selectedFolderID : selectedFolderId,
                        "Operator": "Equals"
                    },
                    {
                        "Operator": "Or",
                        "Filters": [
                            {
                                "Field": "Code",
                                "Value": searchValue,
                                "Operator": "Contains"
                            },
                            {
                                "Field": "Title",
                                "Value": searchValue ,
                                "Operator": "Contains"
                            },
                            {
                                "Field": "Description",
                                "Value": searchValue,
                                "Operator": "Contains"
                            }
                        ]
                    }
                ]
            }
        };


        setLoading(true);
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/list').post(TreeReqBody)
            .then((data) => {
                    setLoading(false);
                    if (data) {
                        if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                            const userInfo = jwt_decode(getToken());
                            ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                            return;
                        }
                        setRows(data.rows)
                        setRowCount(data.total)
                    } else {
                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error')
                        }
                        if (data.message){
                            ShowInfo(data.message, 'warning')
                        }
                    }
                }
            ).catch(() => {
            setRows([])
            setRowCount(0)
            setLoading(false);
            ShowInfo('Cannot Load Tree\'s', 'error')
        })
        }

    function SlideTransition(props) {
        return <Slide {...props} direction="up"/>;
    }


   const onFolderTreeChnage = (e)=>{
        if (e === 'delete' || e === 'rename'){
        GetTreeList();
        }
   }

   const selectedID = (selectedID) => {
       setSelectedFolderId(selectedID)
   }
    const selectedName = (selectedName) => {
        setSelectedFolderName(selectedName)
    }

    const SelectedParentId = (SelectedParentId) => {
        setSelectedFolderParentId(SelectedParentId)
    }

   const FolderTreeData = (TreeData) => {
       setFoldersTreeData (TreeData)
   }


   const handleSortModelChange = (newModel) => {
       setSortModel(newModel);
    }



        return (<div>
                {(loading && rows.length <= 0) &&
                    <Loading fullscreen />
                }

                <div className="GridWrapper">
                    <Split
                        style = {{display: 'flex' , width: '100%' }}
                        sizes={([20, 80])}
                        cursor='col-resize'
                        minSize={20}
                    >
                    <FolderTree FolderTreeClick={FolderTreeClick} onFolderTreeChnage = {onFolderTreeChnage}  selectedID={selectedID} SelectedParentId = {SelectedParentId} selectedName={selectedName} FolderTreeData={FolderTreeData} CompanyList={CompanyList} selectedCompany={selectedCompany} />
                    <div className="TreeGrid" >
                        <div className="controls">
                            <TextField
                                variant="outlined"
                                value={searchValue}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={()=>{ setSearchValue(''); }}>
                                            {searchValue ?  <CloseIcon /> : <SearchIcon />}
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Search"
                                onChange={(e) => { setSearchValue(e.target.value)  }}
                                onKeyDown={_handleKeyDown}
                            />

                            <div className="deployDraft">
                            {/*    <span>Draft</span>*/}
                            {/*    <Switch*/}
                            {/*        inputProps={{'aria-label': 'Switch A'}}*/}
                            {/*        checked={deployDraft}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setdeployDraftState(e.target.checked);*/}
                            {/*            setDeployDraft(e.target.checked);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    <span>Deployed</span>*/}
                            {/*</div>*/}

                                <Tabs
                                    value={deployDraft}
                                    onChange={(event, newValue) => {
                                        console.log (newValue)
                                        setdeployDraftState(newValue);
                                        setDeployDraft(newValue);
                                    }}
                                    className='gridTabs'
                                >
                                    <Tab icon={<DraftIcon />}  value={SelectedTab.draft} iconPosition="start" label="DRAFT" />
                                    <Tab icon={<CurrentIcon />} value={SelectedTab.current} iconPosition="start" label="CURRENT" />
                                    <Tab icon={<UpcommingIcon />} value={SelectedTab.upcomming} iconPosition="start" label="UPCOMING" />
                                </Tabs>
                                </div>
                            <Button onClick={()=>{ navigate('/diagram/') }}><AddNewIcon /></Button>
                        </div>
                        <DataGrid
                            sx={{ m: 2 }}
                            // initialState={{
                            //     sorting: {
                            //         sortModel: [{ field: 'id', sort: 'desc' }],
                            //     },
                            // }}
                            rows={rows}
                            columns={columns}
                            // pageSize={20}
                            // rowsPerPageOptions={[20]}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            hideFooterSelectedRowCount={true}
                            showCellRightBorder={false}
                            disableSelectionOnClick
                            checkboxSelection = {false}
                            pagination
                            components={{
                                ColumnUnsortedIcon: () => <ArrowDropDownIcon />,
                                Pagination: CustomPagination,
                                NoRowsOverlay: () => <NoRowsOverlay />,
                            }}
                        />

                        <Menu
                            anchorEl={anchorEl}
                            id="context-menu"
                            open={ContextOpen}
                            onClose={ContexthandleClose}
                            onClick={ContexthandleClose}
                            transitionDuration = {0}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 0.7,
                                    width:200,
                                    backgroundColor: 'white',

                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                                <MenuItem onClick={() => {
                                    renameTree(curRowData)
                                }}>
                                    <RenameIcon/> <span className="menuItemText">Rename</span>
                                    <Divider/>
                                </MenuItem>
                            <MenuItem onClick={() => { cloningTree(curRowData)}} >
                                <ContentCopyIcon /> <span className="menuItemText">Clone</span>
                                <Divider />
                            </MenuItem>
                            <MenuItem onClick={() => {setConfirmDialog(true)}} >
                                <DeleteSweepIcon /> <span className="menuItemText">Delete</span>
                                <Divider />
                            </MenuItem>
                        </Menu>
                    </div>
                    </Split>
                </div>

                <Dialog PaperProps={{ className: 'TreePopup' }} onClose={TreePopupClose} open={TreePopupOpen}>
                    <div style={{position: 'releative'}}>
                        <DialogTitle>Tree info</DialogTitle>
                        <CloseIcon onClick={()=> { TreePopupClose() }} className='TreePopup_close_button'/>
                    </div>
                    <Divider />
                    <div className="TreePopupContent">
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '33ch',marginTop: '15px' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                error = {CodeError}
                                className={CodeError ? 'txtErr':''}
                                id="outlined-treeCode"
                                label="Code*"
                                value={clonableTreeData.code || ''}
                                onChange={(e)=> { setClonableTreeData ({...clonableTreeData, code : e.target.value}) }}
                                onBlur={()=>{CodeFieldValidate()}}
                                onKeyDown={(event) => {
                                    if (!ALPHA_NUMERIC_DASH_UNDERLINE_REGEX.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            <TextField
                                error = {TitleError}
                                className={TitleError ? 'txtErr':''}
                                id="outlined-treeTitle"
                                label="Title*"
                                value={clonableTreeData.title || ''}
                                onChange={(e)=> {setClonableTreeData ({...clonableTreeData, title : e.target.value}) }}
                                onBlur={()=>{TitleFieldValidate()}}
                            />

                            <div style ={{display: 'none'}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Open Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={clonableTreeData.openDate || null}
                                        onChange={(e)=>{setClonableTreeData ({...clonableTreeData, openDate : e}) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />

                                    <DatePicker
                                        label="Close Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={clonableTreeData.closeDate || null}
                                        onChange={(e)=>{setClonableTreeData ({...clonableTreeData, closeDate : e}) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>



                            <div className='TreeSelectWrapper'>
                                <TextField
                                    disabled
                                    className='SelectedFolder'
                                    placeholder='Select Folder'
                                    value={selectedFolderName}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FolderOpenIcon />
                                            </InputAdornment>
                                        ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton className='openTreeList'><ArrowDropDownCircleOutlinedIcon /></IconButton>
                                        </InputAdornment>
                                    )
                                    }}
                                    onClick={(e)=>{
                                        let TreeListElement = document.querySelector('.TreeSelect');
                                        if (TreeListElement.classList.contains('displayShow')){
                                            TreeListElement.classList.remove('displayShow')
                                        } else {
                                            TreeListElement.classList.add('displayShow')
                                        }
                                            // document.querySelector('.TreeSelect')
                                    }}

                                />
                                <div className="TreeSelect">
                                    <div className='folderList'>
                                        <FolderTree onFolderTreeChnage = {onFolderTreeChnage} selectedID={selectedID} SelectedParentId = {SelectedParentId} selectedName={selectedName} FolderTreeData={FolderTreeData} selectedCompany={selectedCompany}/>
                                    </div>
                                </div>
                            </div>

                            <FormControl sx={{ m: 3 }}  className='StoreEntytyCheck' component="fieldset" variant="standard">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ClonestoreEntity} onChange={(e) => setCloneStoreEntity(e.target.checked)}
                                            sx={{
                                                color: '#a8a3db',
                                                '&.Mui-checked': {
                                                    color: '#a8a3db',
                                                },
                                            }}
                                        />
                                    }
                                    label="Store Entity"
                                />
                            </FormControl>

                            <FormControl sx={{ m: 3 }}  className='ThrowIfNotFinishedCheck' component="fieldset" variant="standard">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={CloneThrowIfNotFinished} onChange={(e) => setCloneThrowIfNotFinished(e.target.checked)}
                                            sx={{
                                                color: '#a8a3db',
                                                '&.Mui-checked': {
                                                    color: '#a8a3db',
                                                },
                                            }}
                                        />
                                    }
                                    label="Exception when output node not reached"
                                />
                            </FormControl>

                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={8}
                                placeholder="Description"
                                //style={}
                                value = {clonableTreeData.description || ''}
                                onChange={e => setClonableTreeData ({...clonableTreeData, description : e.target.value}) }
                            />


                            <Button onClick={()=>{treeCloneHandle()}}><ContentCopyIcon className="icons_margin" /> Clone</Button>
                        </Box>

                    </div>
                </Dialog>











                <Dialog PaperProps={{ className: 'TreePopup' }} onClose={TreeRenameClose} open={RenameTreePopupOpen}>
                    <div style={{position: 'releative'}}>
                        <DialogTitle>Rename</DialogTitle>
                        <CloseIcon onClick={()=> { TreeRenameClose() }} className='TreePopup_close_button'/>
                    </div>
                    <Divider />
                    <div className="TreePopupContent">
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '33ch',marginTop: '15px' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                error = {RenameCodeError}
                                className={RenameCodeError ? 'txtErr':''}
                                id="outlined-treeCode"
                                label="Code*"
                                value={renamableTreeData.code || ''}
                                disabled={deployDraft !== SelectedTab.draft}
                                onChange={(e)=> { setRenamableTreeData ({...renamableTreeData, code : e.target.value}) }}
                                onBlur={()=>{RenameCodeFieldValidate()}}
                                onKeyDown={(event) => {
                                    if (!ALPHA_NUMERIC_DASH_UNDERLINE_REGEX.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            <TextField
                                error = {RenameTitleError}
                                className={RenameTitleError ? 'txtErr':''}
                                id="outlined-treeTitle"
                                label="Title*"
                                value={renamableTreeData.title || ''}
                                onChange={(e)=> {setRenamableTreeData ({...renamableTreeData, title : e.target.value}) }}
                                onBlur={()=>{RenameTitleFieldValidate()}}
                            />

                            <div style ={{display: 'none'}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Open Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={renamableTreeData.openDate || null}
                                        onChange={(e)=>{setRenamableTreeData ({...renamableTreeData, openDate : e}) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />

                                    <DatePicker
                                        label="Close Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={renamableTreeData.closeDate || null}
                                        onChange={(e)=>{setRenamableTreeData ({...renamableTreeData, closeDate : e}) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>



                            <div className='TreeSelectWrapper'>
                                <TextField
                                    disabled
                                    className='SelectedFolder'
                                    placeholder='Select Folder'
                                    value={selectedFolderName}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FolderOpenIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton className='openTreeList'><ArrowDropDownCircleOutlinedIcon /></IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    onClick={(e)=>{
                                        let TreeListElement = document.querySelector('.TreeSelect');
                                        if (TreeListElement.classList.contains('displayShow')){
                                            TreeListElement.classList.remove('displayShow')
                                        } else {
                                            TreeListElement.classList.add('displayShow')
                                        }
                                        // document.querySelector('.TreeSelect')
                                    }}

                                />
                                <div className="TreeSelect">
                                    <div className='folderList'>
                                        <FolderTree onFolderTreeChnage = {onFolderTreeChnage} selectedID={selectedID} SelectedParentId = {SelectedParentId} selectedName={selectedName} FolderTreeData={FolderTreeData} selectedCompany={selectedCompany}/>
                                    </div>
                                </div>
                            </div>

                            <FormControl sx={{ m: 3 }}  className='StoreEntytyCheck' component="fieldset" variant="standard">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={RenamestoreEntity} onChange={(e) => setRenameStoreEntity(e.target.checked)}
                                            sx={{
                                                color: '#a8a3db',
                                                '&.Mui-checked': {
                                                    color: '#a8a3db',
                                                },
                                            }}
                                        />
                                    }
                                    label="Store Entity"
                                />
                            </FormControl>

                            <FormControl sx={{ m: 3 }}  className='ThrowIfNotFinishedCheck' component="fieldset" variant="standard">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={RenameThrowIfNotFinished} onChange={(e) => setRenameThrowIfNotFinished(e.target.checked)}
                                            sx={{
                                                color: '#a8a3db',
                                                '&.Mui-checked': {
                                                    color: '#a8a3db',
                                                },
                                            }}
                                        />
                                    }
                                    label="Exception when output node not reached"
                                />
                            </FormControl>

                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={8}
                                placeholder="Description"
                                //style={}
                                value = {renamableTreeData.description || ''}
                                onChange={e => setRenamableTreeData ({...renamableTreeData, description : e.target.value}) }
                            />


                            <Button onClick={()=>{treeRenameHandle()}}><RenameIcon className="icons_margin" /> Rename</Button>
                        </Box>

                    </div>
                </Dialog>





                <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose}
                          TransitionComponent={Fade}
                          key={SlideTransition.name}
                >
                    <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                        {infoMessage}
                    </Alert>
                </Snackbar>
                <ConfirmDialog open={confirmDialog} onClose={(e) => { onConfirmDialogClose(e)  }}
                               title="DELETE"
                               text= {`Do you want to Delete ( ${curRowData ? curRowData.code : null} - ${curRowData ? curRowData.title : "..."} )`}
                />
            </div>

        )
    }

export default TreeList;