import * as React from "react";
import './Hoistory.css';
import Drawer from "@mui/material/Drawer";
import {Alert, Box, Checkbox, Divider, IconButton, ListItem, ListItemButton, Skeleton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HistoryPointIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import {useEffect, useState} from "react";
import Request from "../../utils/request";
import Snackbar from "@mui/material/Snackbar";

import BallotIcon from '@mui/icons-material/Ballot';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import CompareIcon from '@mui/icons-material/Compare';
import List from "@mui/material/List";
import jwt_decode from "jwt-decode";
import {getSelectedCompanyCfg, getTimeZoneCfg, getToken} from "../../utils/Common";
import moment from "moment";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";


export default function History(props) {
    const token = getToken();
    let company = 0;
    if (token){
        const userInfo = jwt_decode(getToken());
        if (getSelectedCompanyCfg()) company = getSelectedCompanyCfg()[userInfo.sub];
    }


    const [state, setState] = useState({
        right: false,
    });
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');


    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const handleInfoClose = (event, reason) => {
        console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };


    // const [treeCode, setTreeCode] = useState('');
    const [treesHistory, setTreesHistory] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [CompareButtonDisabled, setCompareButtonDisabled] = useState(true);

    const [historyChecked, setHistoryChecked] = useState([]);
    const [moreTwoSelected, setMoreTwoSelected] = useState('');



    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.OpenHistoryClose(open);
        setState({ ...state, [anchor]: open });
    };

    const getTreeHistoryList = (treeCode) => {
        let ReqBody = {
            "StartIndex": 0,
            "RowCount": 0,
            "SortBy": [
                {
                    "Field": "AbVersion",
                    "Direction": "desc"
                }
            ],
            "Filter": {
                "Operator": "And",
                "Filters": [
                    {
                        "Field": "Version",
                        "Value": 0,
                        "Operator": "NotEquals"
                    },
                    {
                        "Field": "FolderId",
                        "Value": company,
                        "Operator": "Equals"
                    },
                    {
                        "Operator": "Or",
                        "Filters": [
                            {
                                "Field": "Code",
                                "Value": treeCode,
                                "Operator": "Equals"
                            }
                        ]
                    }
                ]
            }
        }
        setIsLoading(true)
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/list').post(ReqBody)
            .then((data) => {
                if (data) {
                    if (data.errors) {
                        ShowInfo(data.errors[0].message, 'error');
                        return;
                    }
                    if (data.message) {
                        ShowInfo(data.message, 'warning');
                        return;
                    }
                    setIsLoading(false);
                    if (data.rows) {
                        setTimeout(()=>{
                            setTreesHistory(data.rows);
                        },0);
                    }
                }
            }).catch(() => {
            setIsLoading(false);
            ShowInfo('Cannot get History list of Deployed Trees', 'warning')
        })
    }

    useEffect(() => {
           setState({right:props.OpenHistory})
           // setTreeCode(props.treeCode)
        getTreeHistoryList(props.treeCode);
        // eslint-disable-next-line
    },[props.OpenHistory]);

    // esi chgitem xi em grel - hla vor hanum em !
    // useEffect(() => {
    //     if (state.right && treeCode && treesHistory.length < 1){
    //         getTreeHistoryList();
    //     }
    //     // eslint-disable-next-line
    // },[treeCode])

    useEffect(()=>{
            setCompareButtonDisabled(historyChecked.length < 2);
    },[historyChecked])

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const HistoryCompareClick = () => {
        if (historyChecked.length === 2){
            let hstChkd = historyChecked.sort(function(a, b){return b-a});
            window.location.href = '/compare/' + hstChkd[0] + '/' + hstChkd[1];
        }

    }

    const HistoryOnClick = (code , id) => {
        window.location.href = '/diagram/' + code + '/' + id;
    }




        const handleCheckboxChange = (itemId) => {
            if (historyChecked.includes(itemId)) {
                setHistoryChecked(historyChecked.filter((id) => id !== itemId));
                setMoreTwoSelected('');
            } else if (historyChecked.length < 2) {
                // Limit to 2 checked items
                setHistoryChecked([...historyChecked, itemId]);
            } else if (historyChecked.length >= 2) {
                setMoreTwoSelected('Only 2 version can be select');
            }
        };

    const list = (right) => (
        <Box sx={{ width: 450 }} >
            <div className="HistoryHeader">
                <div className="history_title">
               <span>
               <HistoryPointIcon />
                  Deployed Version History
               </span>
                </div>
                <div className="history_compare_button">
                    <Button
                        startIcon={<CompareIcon />}
                        onClick={()=>{HistoryCompareClick()}}
                        disabled={CompareButtonDisabled}
                    >
                        Compare
                    </Button>
                </div>
                <div className="history_close"><IconButton onClick={toggleDrawer(right, false)} size="small" sx={{ ml: 2 }} > <CloseIcon />   </IconButton></div>
                {treesHistory.length > 0 ?
                    <div>
                        <span className="history_title_label">☑ Tick checkboxes to compare versions</span>
                        <Divider />
                    </div>:''
                }
                <div className="HistoryList">
                        <List>
                            {treesHistory.length > 0 ?
                                treesHistory.map((treeHistoryItem,index) =>
                                        <ListItem  disablePadding key={index}>
                                            {/*<div className="passive_status"><CircleIcon/></div>*/}
                                            <Checkbox
                                                title={moreTwoSelected}
                                                className="passive_status"
                                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                checkedIcon={<CheckBoxOutlinedIcon />}
                                                checked={historyChecked.includes(treeHistoryItem.id)}
                                                onChange={()=>{handleCheckboxChange(treeHistoryItem.id)}}
                                            />
                                            <ListItemButton
                                            style={parseInt(treeHistoryItem.id) === parseInt(props.treeId) ? { background: 'rgba(164, 171, 228, 0.28)', boxShadow: '0px 1px 3px #cccccc' , margin: '4px', borderRadius: '2px' } : null}
                                             onClick={(e)=>{
                                               HistoryOnClick(treeHistoryItem.code , treeHistoryItem.id)
                                            }}>
                                                {/*<ListItemIcon>*/}

                                                {/*</ListItemIcon>*/}
                                                <div className="version_info">
                                                    <div className="title_description">
                                                        {treeHistoryItem.title} - {treeHistoryItem.description}
                                                    </div>
                                                    <div className="open_close_dates">
                                                        {moment(new Date(moment(treeHistoryItem.openDate, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')
                                                        } → {treeHistoryItem.closeDate ?
                                                        moment(new Date(moment(treeHistoryItem.closeDate, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')
                                                        : '∞'}
                                                    </div>
                                                    <div className="modified">
                                                        {treeHistoryItem.modifiedBy} - {moment(new Date(moment(treeHistoryItem.modifiedOn, 'DD/MM/YYYY hh:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss')}

                                                    </div>
                                                </div>
                                            </ListItemButton>
                                            <div className="version">
                                                Version {treeHistoryItem.abVersion}
                                            </div>
                                            {treeHistoryItem.isActive ?
                                                <div className="active_status">Current</div> : ""
                                            }
                                        </ListItem>
                                )
                                :
                               isLoading ? <div>
                                    {Array(20).fill(1).map((el, i) =>
                                        <div key={i} style={{padding: '10px 30px 0px 30px'}}>
                                            <Skeleton variant="text" />
                                        </div>
                                    )}
                                </div>
                                   :
                                   <div className="noHistory">
                                       <BallotIcon />
                                   No Version History
                                  </div>

                            }
                        </List>
                    </div>
            </div>
            <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {infoMessage}
                </Alert>
            </Snackbar>
        </Box>
    );



    return (
        <div>
            <React.Fragment key='history'>
                <Drawer
                    anchor='right'
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                    className="History"
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
