import * as React from 'react';
import "../CommentBox/CommentBox.css"
import {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {IconButton, Tooltip} from "@mui/material";
import Fade from "@mui/material/Fade";
import {withStyles} from "@material-ui/core/styles";


const LookupValidateStyle = withStyles({
    arrow: {
        fontSize: 20,
        "&::before": {
            backgroundColor: "rgba(243,62,62,0.8)",
        }
    },
    tooltip: {
        color: "white",
        backgroundColor: "rgba(243,62,62,0.8)",
        height: "20px",
        lineHeight: "20px"
    }
})(Tooltip);


function CommentBox(props) {
    const [value , setValue] = useState('');
    const [required , setRequired] = useState( false);
    const [requiredErr , setRequiredErr] = useState( false);

    useEffect(() => {
        setValue('');
        setRequiredErr(false);
        if (!props.required) {setRequired(false)}
        // eslint-disable-next-line
    }, [props.open]);

    useEffect(() => {
        setRequired(!value)
        setRequiredErr(false)
        if (!props.required) {setRequired(false)}
        // eslint-disable-next-line
    }, [value]);

    return (
        <div>
            <div className='CommenBoxWindow'>
                <Dialog className='CommenBoxDialog'
                        open={props.open}
                >
                    <LookupValidateStyle
                    open={requiredErr}
                    title={requiredErr ? props.requiredText : ''}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 0 }}
                    arrow
                >
                    <div className='form_comment_data_Controls' >

                        <textarea
                            onChange={(e)=>{
                                setValue(e.target.value)}
                            }
                            value={value}
                            style={{border: '1px solid #cecece',width: '100%'+ 45 , resize: 'none' }}
                            autoFocus
                            placeholder="Comment"
                            className={
                                (required ? 'commentbox-required' : '')
                            }
                        ></textarea>

                        <div className="form_comment_data_Actions">
                             <IconButton className="OkIcon" onClick={() =>{
                                 if (required) {
                                     if (!value) setRequiredErr(true);
                                     return false;
                                 } else {
                                         props.CommentText(value);
                                     }
                             }}>OK</IconButton>
                             <IconButton className="CancelIcon" onClick={() => {
                                 setValue('');
                                 setRequiredErr(false);
                                 props.onClose()
                             }}>Cancel</IconButton>
                        </div>

                    </div>
                    </LookupValidateStyle>
                </Dialog>
            </div>
        </div>
        )
}

export default CommentBox;