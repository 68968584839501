import { useEffect, useState } from 'react';
function useSessionStorage(key) {
    const [value, setValue] = useState(sessionStorage.getItem(key));
    useEffect(() => {
        const checkStorage = () => {
            const newValue = sessionStorage.getItem(key);
            if (newValue !== value) {
                setValue(newValue);
            }
        };
        const intervalId = setInterval(checkStorage, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [key, value]);
    return value;
}
export default useSessionStorage;