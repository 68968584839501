function getNodeRef(node) {
    return `<a href="#" data-nodeid=${node.id} class="compare-node">${node.type}</a>`;
};
function getFromMarkup(text) {
    return '<span class="compare-from">' + (text || '') + '</span>';
};
function getToMarkup(text) {
    return '<span class="compare-to">' + (text || '') + '</span>';
};
function getModifiedMarkup(text) {
    return '<span class="compare-mod">' + (text || '') + '</span>';
};
// eslint-disable-next-line
function getTypeMarkup(text) {
    return '<span class="compare-type">' + (text || '') + '</span>';
};
function getPropMarkup(text) {
    return '<span class="compare-property">' + (text || '') + '</span>';
};
function getExpressionMarkup(text) {
    return '<span class="compare-expression">' + (text || '') + '</span>';
};
function getTypeName(typenum) {
    switch(typenum) {
        case 0:
            return 'None';
        case 1:
            return 'Number';
        case 2:
            return 'String';
        case 3:
            return 'Boolean';
        case 4:
            return 'Date';
        case 5:
            return 'Object';
        case 6:
            return 'Array';
        default:
    }   
};

function generateNewNodeMarkup(node) {
    if (node.type === 'Link')
    {
        return `New ${getNodeRef(node)} element with title "${getPropMarkup(node.title)}" targeting tree ${getPropMarkup(node.link)} has been ${getToMarkup('added')}`;
    }

    return `New ${getNodeRef(node)} element with title "${getPropMarkup(node.title)}" has been ${getToMarkup('added')}`;
}

function generateNodeRemovedMarkup(node) {
    if (node.type === 'Link')
    {
        return `${node.type} element with title "${getPropMarkup(node.title)}" targeting tree ${getPropMarkup(node.link)} has been ${getFromMarkup('removed')} `;
    }

    return `${node.type} element with title "${getPropMarkup(node.title)}" has been ${getFromMarkup('removed')} `;
}

function generateRowMarkup(oldRow, newRow, columns) {
    let result = '<div class="compare-table-wrap"><table class="compare-table">';
    columns.forEach(col => {
        result = result + '<tr>'+
                            '<th>'+col.name+'</th>'+
                            (oldRow ? '<td class="compare-table-old">'+(oldRow[col.name] || '')+'</td>' : '')+
                            (newRow ? '<td class="compare-table-new">'+(newRow[col.name] || '')+'</td>' : '')+
                           '</tr>';
    });
    return result + '</table></div>'
}

function compareArray(node, expressionType, sourceItems, targetItems, showReordering) {
    let result = [];
    let source = sourceItems || [];
    let target = targetItems || [];

    target.forEach((targetExpr, index) => {
        const sourceIndex = source.findIndex(x => {
            return x.value === targetExpr.value;
        });

        if (sourceIndex === -1) {
            result.push(`New "${getExpressionMarkup(targetExpr.value)}" ${expressionType} has been ${getToMarkup('added')} in the ${getNodeRef(node)} element with title "${getPropMarkup(node.title)}"`);
        }
        else if (sourceIndex !== index && showReordering)
        {
            result.push(`Reordered "${getExpressionMarkup(targetExpr.Value)}" ${expressionType} of the ${getNodeRef(node)} element with title "${getPropMarkup(node.title)}" from index ${getFromMarkup(sourceIndex)} to ${getToMarkup(index)}`);
        }
    });

    source.forEach(sourceExpr => {
        const targetExpr = target.find(x => {
            return x.value === sourceExpr.value;
        });

        if (targetExpr == null) {
            result.push(`${expressionType} "${getExpressionMarkup(sourceExpr.value)}" has been ${getFromMarkup('removed')} from the ${getNodeRef(node)} element with title "${getPropMarkup(node.title)}"`);
        }
    });

    return result;
}

function compareRules(node, sourceRule, targetRule, showReordering) {
    let result = [];
    let source = sourceRule || {};
    let target = targetRule || {};

    if (source.logic !== target.logic) {
        result.push(`Logic of the ${getNodeRef(node)} element with title "${getPropMarkup(node.title)}" has been changed from ${getFromMarkup(source.logic)} to ${getToMarkup(target.logic)}`);
    }

    let conditionsDiff = compareArray(node, 'Condition', source.items, target.items, showReordering);
    result.push(...conditionsDiff);

    return result;
}


function compareLookup(sourceNode, targetNode) {

    let result = [];
    let sourceTable = sourceNode.table || {};
    let targetTable = targetNode.table || {};
    let sourceDataExpression = sourceTable.dataExpression || {};
    let targetDataExpression = targetTable.dataExpression || {};

    if (sourceDataExpression.value !== targetDataExpression.value) {
        result.push(`Data expression of the ${getNodeRef(sourceNode)} element with title "${getPropMarkup(targetNode.title)}" has been changed from ${getFromMarkup(sourceDataExpression.value)} to ${getToMarkup(targetDataExpression.value)}`);
    }
    if (targetDataExpression.value) {
        return result;
    }

    let sourceColumns = sourceTable.columns || [];
    let targetColumns = targetTable.columns || [];
    
    targetColumns.forEach((targetCol, index) => {
        const sourceIndex = sourceColumns.findIndex(x => {
            return x.name === targetCol.name;
        });

        if (sourceIndex === -1) {
            result.push(`New Column "${getPropMarkup(targetCol.name)}" of type "${getPropMarkup(getTypeName(targetCol.type))}" has been ${getToMarkup('added')} in the ${getNodeRef(targetNode)} element with title "${getPropMarkup(targetNode.title)}"`);
        }
    });

    sourceColumns.forEach(sourceColumn => {
        const targetColumn = targetColumns.find(x => {
            return x.name === sourceColumn.name;
        });

        if (targetColumn == null) {
            result.push(`Column "${getPropMarkup(sourceColumn.name)}" of type "${getPropMarkup(getTypeName(sourceColumn.type))}" has been ${getFromMarkup('removed')} from the ${getNodeRef(targetNode)} element with title "${getPropMarkup(targetNode.title)}"`);
        }
    });

    let sourceRows = sourceTable.rows || [];
    let targetRows = targetTable.rows || [];
    targetRows.forEach((targetRow, index) => {
        const sourceRow = sourceRows.find(x => {
            return x.id === targetRow.id;
        });

        if (sourceRow == null) {
            let tableMarkup = generateRowMarkup(null, targetRow, targetColumns);
            result.push(`New Row has been ${getToMarkup('added')} in the ${getNodeRef(targetNode)} element with title "${getPropMarkup(targetNode.title)}"` + tableMarkup);
        }
        else 
        {
            let isChanged = false;
            targetColumns.every(col => {
                if (targetRow[col.name] !== sourceRow[col.name]) {
                    isChanged = true;
                    return false;
                }
                return true;
            });
            if (isChanged) {

                let tableMarkup = generateRowMarkup(sourceRow, targetRow, targetColumns);
                result.push(`Row has been ${getModifiedMarkup('changed')} in the ${getNodeRef(targetNode)} element with title "${getPropMarkup(targetNode.title)}"` + tableMarkup);
            }
        }
    });

    sourceRows.forEach(sourceRow => {
        const targetRow = targetRows.find(x => {
            return x.id === sourceRow.id;
        });

        if (targetRow == null) {
            let tableMarkup = generateRowMarkup(sourceRow, null, targetColumns);
            result.push(`Row has been ${getFromMarkup('removed')} in the ${getNodeRef(targetNode)} element with title "${getPropMarkup(targetNode.title)}"` + tableMarkup);
        }
    });


    return result;
}

function compareNode(sourceNode, targetNode, showReordering) {
    let result = [];

    if (sourceNode.title !== targetNode.title) {
        result.push(`${getNodeRef(sourceNode)} element's ${getPropMarkup('Title')} has been changed from ${getFromMarkup(sourceNode.title)} to ${getToMarkup(targetNode.title)}`);
    }

    if (sourceNode.link !== targetNode.link) {
        result.push(`${getNodeRef(sourceNode)} element with title "${getPropMarkup(targetNode.title)}" ${getPropMarkup('Targeting tree')} has been changed from ${getFromMarkup(sourceNode.link)} to ${getToMarkup(targetNode.link)}`);
    }

    let actionDiff = compareArray(targetNode, 'Expression', sourceNode.actions, targetNode.actions, showReordering);
    result.push(...actionDiff);

    let rulesDiff = compareRules(targetNode, sourceNode.rules, targetNode.rules, showReordering);
    result.push(...rulesDiff);
    
    if (sourceNode.type === "Lookup") {
        let lookupDiff = compareLookup(sourceNode, targetNode);
        result.push(...lookupDiff);
    }

    return result;
}

function compareModels(source, target, showReordering) {
    let result = [];
    if (source === null || source === undefined || target === null || target === undefined) {
        return result;
    }

    target.nodes.forEach(targetNode => {
        let sourceNode = source.nodes.find(x => x.id === targetNode.id);
        if (sourceNode == null) {
            let newMarkup = generateNewNodeMarkup(targetNode);
            result.push(newMarkup);
        }
        else 
        {
            let nodeDiff = compareNode(sourceNode, targetNode, showReordering);
            result.push(...nodeDiff);
        }
    });

    source.nodes.forEach(sourceNode => {
        let targetNode = target.nodes.find(x => x.id === sourceNode.id);
        if (targetNode == null) {
            let newMarkup = generateNodeRemovedMarkup(sourceNode);
            result.push(newMarkup);
        }
    });

    return result;
}

export default compareModels;