import * as joint from 'jointjs';
import {BaseShape} from './diagram.shape';
import { labelAttributes,
    ShapeTypes,
    bodyAttributes,
    } from './diagram.common';



window.joint = joint;
const RectangleShape = BaseShape.define(ShapeTypes.RECTANGLE, {
    size: { width: 80, height: 60 },
    attrs: {
        root: {
            highlighterSelector: 'body'
        },
        body: {
            width: 'calc(w)',
            height: 'calc(h)',
            ...bodyAttributes.Default
        },
        label: {
            text: 'Rectangle',
            ...labelAttributes.Default
        }
    }
}, {
    markup: [{
        tagName: 'rect',
        selector: 'body',
    }, {
        tagName: 'text',
        selector: 'label'
    }]
});

export {RectangleShape}