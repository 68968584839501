import * as React from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import './FolderTree.css';
import Box from "@mui/material/Box";
import {Alert, Divider, IconButton, TextField, Typography} from "@mui/material";
import {treeItemClasses} from "@mui/x-tree-view";
import {styled} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HomeIcon from '@mui/icons-material/HomeTwoTone';
import useContextMenu from "../ContextMenu/ContextMenu";
import {
    useIconToggleClick
} from "useseparatetoggleclick";
import {useEffect, useState} from "react";
import Request from "../../utils/request";
import Snackbar from "@mui/material/Snackbar";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {
    getBreadCrumps, getSelectedFoler, getToken, removeBreadCrumps,
    removeSelectedFoler,
    setSelectedFoler
} from "../../utils/Common";
import jwt_decode from "jwt-decode";
import _ from "lodash";



const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: 'rgba(101,99,99,0.71)',
        padding: '0',
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
            width: 'calc(100% - 36px) !important',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 5,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(1),
        },
    },
}));


function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography component={'div'} variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}


const FolderTree = (props) => {

    let company = props.selectedCompany;

    const getFolderTree = (folderID) => {
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder/tree/' + company).get()
            .then((data) => {
                if (data && data.status !== 404) {
                    if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                        const userInfo = jwt_decode(getToken());
                        ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                        return;
                    }
                   setTreeData(data);
                   // console.log ('data',data)
                   //  setSelectedFoler({
                   //      "selectedID": data.id,
                   //      "selectedName": data.name
                   //  })

                   props.FolderTreeData(data)
                  setTimeout(()=>{
                      // part for Rename after Create Folder
                      if (folderID) {
                          const elID = document.getElementById(folderID.toString());
                          document.querySelectorAll('.MuiTreeItem-content')
                              .forEach(el => el.classList.remove('Mui-selected'));
                          const element = elID.querySelector('.MuiTreeItem-label .MuiOutlinedInput-input.MuiInputBase-input');
                          element.closest('.MuiTreeItem-content').classList.add('Mui-selected')
                          element.classList.add('active');
                          element.focus();
                          element.scrollIntoView({block: "center", behavior: "smooth"});
                          setCurEditable(true);
                          ShowInfo("Folder Successfuly Created", 'success')
                      }
                      //************************************
                  },500)
                } else {
                    if (data.status === 404){
                        ShowInfo('Cannot get Folder Tree', 'error')
                    }
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Cannot get Folder Tree', 'error')
        })
    }

    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [TreeData, setTreeData] = React.useState({childFolderCount: 0, childs: [], id: 0, itemType: 1 , name: "root", parentId: "", path: "/"});
    const { anchorPoint, show } = useContextMenu();
    const [curId, setCuirId] = React.useState(company);
    const [curEditable, setCurEditable] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [oldDefaultValue, setOldDefaultValue] = useState('');

    const [expandedNodes, setExpandedNodes] = useState([]);



    const onConfirmDialogClose = (result) => {
        setConfirmDialog(false);
        if (result) {
            DeleteFolder (curId)

        }
    };



    const {
        expanded: expanded2,
        onNodeToggle: onIconToggle,
        IconWrapper: IconIconWrapper
    } = useIconToggleClick();
    let expanded = expanded2;

    useEffect(()=>{
        getFolderTree();
        // eslint-disable-next-line
    },[company]);

    useEffect(()=>{
        if (getBreadCrumps() && getBreadCrumps().ExpandFolders) {
            for (let i = 0; i < getBreadCrumps().ExpandFolders.length; i++) {
                expanded.push(getBreadCrumps().ExpandFolders[i]);
            }
        } else {
            // eslint-disable-next-line
            expanded.push(company.toString());
        }
    },[TreeData,expanded,company]);

    useEffect(()=>{
        setExpandedNodes(getBreadCrumps() ? getBreadCrumps().ExpandFolders : expanded);
        // eslint-disable-next-line
    },[expanded]);


    const CreateFolder = (parentId) =>  {
        const NewFolderModel = {
            "name": "New Folder",
            "parentId": parentId,
        }
         Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder').post(NewFolderModel)
            .then((data) => {
                if (data) {
                    getFolderTree(data.id)
                    expanded.push(parentId)
                } else {
                    if (data.errors) {
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message) {
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Folder Creating problem', 'warning');
        })
    }


    const renameFolder = (id, parentId, name) => {
        const RenameFolderModel = {
            "id": id,
            "parentId": parentId,
            "name": name
        }

        if (id && parentId && name){

            Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder').put(RenameFolderModel)
                .then((data) => {
                    if (data) {
                        ShowInfo('Folder Successfully renamed', 'info')
                        console.log ('props',props)
                        if (props.onFolderTreeChnage) props.onFolderTreeChnage('rename')
                    } else {
                        let ee = document.getElementById(id)
                        ee.querySelector('input').value = oldDefaultValue;
                        if (data.errors) {
                            ShowInfo(data.errors[0].message, 'error')
                        }
                        if (data.message) {
                            ShowInfo(data.message, 'warning')
                        }
                    }
                }).catch((e) => {
                let ee = document.getElementById(id)
                ee.querySelector('input').value = oldDefaultValue;
                ShowInfo('Folder Rename problem (' + e + ')', 'warning');
            })
            getFolderTree();
            setCurEditable(false)
        }
    }

    const DeleteFolder = (id) => {
        if (id){
            Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder').del(id)
                .then((e)=>{
                        if(e && e.status === 'ok') {
                            ShowInfo('Folder "' + oldDefaultValue + '" is successfully DELETED', 'success')
                            getFolderTree();
                            if (props.onFolderTreeChnage) props.onFolderTreeChnage('delete')
                        } else {
                            ShowInfo('Cannot Delete Folder "' + oldDefaultValue + '" or it\'s not empty', 'error')
                        }
            })
        }
    }

    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const handleInfoClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    const handleFolderTreeItemClick = (e) => {
        // let Pathname = e.view.location.pathname
        // if (Pathname.split('/').length <= 2) {
            removeBreadCrumps();
        //}
        if (props.FolderTreeClick) props.FolderTreeClick(e.target.closest('.MuiTreeItem-root').id);
        props.selectedID (e.target.closest('.MuiTreeItem-root').id);
        const selEl = e.target.closest('.MuiTreeItem-root');
        const SelectedParentId = e.target.closest('li').closest('div').closest('.MuiTreeItem-root') ? e.target.closest('li').closest('div').closest('.MuiTreeItem-root').id : null;
        props.selectedName (selEl.querySelector('input').value);
        props.SelectedParentId (SelectedParentId);

        setSelectedFoler(
            {
                "selectedID" :  e.target.closest('.MuiTreeItem-root').id,
                "SelectedParentId" :  SelectedParentId,
                "selectedName" : selEl.querySelector('input').value
            }
        )

    }


const renderTree = (nodes) => (
            <StyledTreeItem key={nodes.id} id={(nodes.id).toString()} nodeId={(nodes.id).toString()} onClick={(e)=>{ handleFolderTreeItemClick(e) }  }  labelText={
                <TextField defaultValue={nodes.name}
                           inputProps={{
                               readOnly: !curEditable,
                               onClick: (e) => {
                                   if (e.target.closest('.MuiTreeItem-root').id === company){
                                       removeSelectedFoler()
                                   }

                               },
                               onDoubleClick: (e)=>{

                                   if (e.target.closest('.MuiTreeItem-root').id !== company){
                                       e.currentTarget.classList.add ('active');
                                       setCurEditable(true)
                                   }
                                   setOldDefaultValue (e.target.value)
                                   if (document.querySelector('.TreeSelect')){
                                       document.querySelector('.TreeSelect').classList.remove('displayShow')
                                   }
                               },
                               onBlur: (e) => {

                                   let oldActive = document.getElementsByClassName("active");
                                   const elID = document.getElementById(curId);
                                   if (elID) {elID.querySelector('.MuiTreeItem-content').classList.remove('Mui-selected')};
                                   for (let i = 0; i < oldActive.length; i++) {
                                       oldActive[i].classList.remove("active");
                                   }
                                   setCurEditable(false)
                                  if (nodes.name !== e.currentTarget.value ) {
                                      renameFolder(nodes.id, nodes.parentId, e.currentTarget.value)
                                  }
                               },
                               onKeyDown: (e)=>{
                                   if(e.key === 'Enter') {
                                       e.currentTarget.blur()
                                       e.currentTarget.click();
                                   }
                               }
                           }} />
            } labelIcon={nodes.id === '0'? HomeIcon : expanded.includes(nodes.id) ? FolderOpenIcon : FolderIcon} >
                {Array.isArray(nodes.childs)
                    ? nodes.childs.map((node) => renderTree(node))
                    : null}
            </StyledTreeItem>
    );

  return (
      <div className="FolderTree">
          <TreeView
              id='TreeViewID'
              defaultEndIcon={<div style={{ width: 24 }} />}
              aria-label="TreeFolder"
              sx={{ height: '100%', flexGrow: 1, overflowY: 'auto' }}
              expanded={ expandedNodes }
              defaultExpanded={[company.toString()]}
              onNodeToggle={onIconToggle}
              selected = {getSelectedFoler() ? getSelectedFoler().selectedID.toString() : getBreadCrumps() ? getBreadCrumps().selectedFolderID.toString() : company.toString()}
              // onNodeFocus={(e)=>{ setCuirId(e.target.id) }}
              onContextMenu={(e)=>{
                  if (e.target.closest('.MuiTreeItem-root')) {
                      setCuirId(e.target.closest('.MuiTreeItem-root').id)
                      let rr = document.getElementById(e.target.closest('.MuiTreeItem-root').id)
                      setOldDefaultValue(rr.querySelector('input').value)
                      props.selectedID(e.target.closest('.MuiTreeItem-root').id)
                  }
              }}
              defaultCollapseIcon={
                  <IconIconWrapper>
                      <ArrowDropDownIcon />
                  </IconIconWrapper>
              }
              defaultExpandIcon={
                  <IconIconWrapper>
                      <ArrowRightIcon />
                  </IconIconWrapper>
              }
          >
              {renderTree(TreeData)}
          </TreeView>
          {show &&
              <ul className="menu" style={{ top: anchorPoint.y, left: anchorPoint.x ,padding: '14px', zIndex: 1000, backgroundColor: 'white'}}>
                  {/*{curId}*/}
                  <li><IconButton onClick={()=> CreateFolder(curId,TreeData)}> <CreateNewFolderIcon /> Add Folder</IconButton></li>
                  <div className="TreeMenuControls">
                      <li className='TreeRename' onClick={(e)=>{
                          const elID = document.getElementById(curId);
                          if (curId !== company) {
                              document.querySelectorAll('.MuiTreeItem-content')
                                  .forEach(el => el.classList.remove('Mui-selected'));
                              const element = elID.querySelector('.MuiTreeItem-label .MuiOutlinedInput-input.MuiInputBase-input');
                              element.closest('.MuiTreeItem-content').classList.add('Mui-selected')
                              element.classList.add('active');
                              element.focus();
                              setCurEditable(true)
                          }

                      }}><DriveFileRenameOutlineIcon /> Rename</li>
                      <Divider />
                      <li className='TreeDelete' onClick={(e)=>{
                          if (curId !== company) {setConfirmDialog(true)}
                      }
                      }><DeleteForeverIcon /> Delete</li>
                  </div>

              </ul>
          }
          <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose} >
              <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                  {infoMessage}
              </Alert>
          </Snackbar>
          <ConfirmDialog open={confirmDialog} onClose={(e) => { onConfirmDialogClose(e)  }}
                         title="Delete Folder"
                         text= {`Do you want to Delete Folder "${oldDefaultValue}"?`}
          />
      </div>
  )
}

export default FolderTree;