import * as React from 'react';
import {
    Alert,
    Autocomplete, Checkbox, Divider, FormControlLabel, Grid,
    MenuItem, Slide,
    TextField,
    Tooltip
} from "@mui/material";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {withStyles} from "@material-ui/core/styles";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import FormControl from "@mui/material/FormControl";
import Request from "../../utils/request";
import Snackbar from "@mui/material/Snackbar";

const ValidateStyle = withStyles({
    arrow: {
        fontSize: 20,
        "&::before": {
            backgroundColor: "rgba(243,62,62,0.8)",
        }
    },
    tooltip: {
        color: "white",
        backgroundColor: "rgba(243,62,62,0.8)",
        // height: "20px",
        textAlign: 'center',
        lineHeight: "20px"
    }
})(Tooltip);
function User(props) {

    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyList, setCompanyList] = useState([]); // all company list model , with id , path  and id's
    const [companyListNames, setCompanyListNames] = useState([]); // only company names ALL list
    const [selectedCompanyNames, setSelectedCompanyNames] = useState([]); //Selected comapny only Names
    const [claimGroups, setClaimGroups] = useState([]);
    const [selectedClaims, setSelectedClaims] = useState([]);


    let companNames = [];
    useEffect(()=>{
        setCompanyList(props.companyList);
        for (let i = 0; i < props.companyList.length; i++) {
            companNames.push(props.companyList[i].name) ;
        }
        setCompanyListNames(companNames);
        GetClaimGroups();
        // eslint-disable-next-line
    },[props.companyList]);

    useEffect(()=> {
        if (props.popupOpenMode === 'edit'){
            setEmail(props.userinfo.email);
            setName(props.userinfo.name);

            if (claimGroups) {
                let clms = [];
                props.userinfo.claimGroups.forEach((clim) => clms.push(parseInt(clim.id)));
                setSelectedClaims(clms);
            }

            if (props.userinfo.companies.length > 0){
                let cmps = [];
                props.userinfo.companies.forEach((cmp) => cmps.push(cmp.name));
                setSelectedCompanyNames(cmps);
            }
        }
    },[props.popupOpenMode,claimGroups,props.userinfo])

    const GetClaimGroups = ()=> {
        Request(window._env_.REACT_APP_API_TREE_LIST + 'user/claimgroups').get()
            .then((data) => {
                if (data && data.status !== 404) {
                    setClaimGroups(data.rows)
                } else {
                    if (data.status === 404){
                        ShowInfo('Cannot get Claim Groups', 'error')
                    }
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Cannot get Claim Groups', 'error')
        })
    }

    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    function SlideTransition(props) {
        return <Slide {...props} direction="up"/>;
    }
    const handleInfoClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };
    const EmailFieldValidate = () =>{
            setEmailError(!email ? 'Email Required' : '');
        // eslint-disable-next-line
        let mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(email && !email.toLowerCase().match(mailformat)) {
            setEmailError('Incorrect E-mail format');
        }
    }
    const NameFieldValidate = () =>{
        setNameError(!name ? 'Name Required' : '');
    }
    function getIdsByNameArray(objectsArray, namesArray) {
        const iDsArray = [];
        for (const nameToFind of namesArray) {
            const foundObject = objectsArray.find(obj => obj.name === nameToFind);

            if (foundObject) {
                iDsArray.push(parseInt(foundObject.id));
            } else {
                iDsArray.push(null);
            }
        }
        return iDsArray;
    }

    // function getNameArrayByIds(objectsArray, iDsArray) {
    //     const NamesArray = [];
    //     for (const idsToFind of iDsArray) {
    //         const foundObject = objectsArray.find(obj => obj.id === idsToFind.id);
    //
    //         if (foundObject) {
    //             NamesArray.push(foundObject.name);
    //         } else {
    //             NamesArray.push(null);
    //         }
    //     }
    //     return NamesArray;
    // }
    const handleClaimCheckboxClick = (id) =>{
        setSelectedClaims((prevSelectedIds) => {
            if (prevSelectedIds.includes(parseInt(id))) {
                return prevSelectedIds.filter((selectedId) => parseInt(selectedId) !== parseInt(id));
            } else {
                return [...prevSelectedIds, parseInt(id)];
            }
        });
    }
    const AddEditProcess = (mode) => {
        EmailFieldValidate()
        NameFieldValidate();
        let inviteModel = {
            email: email,
            name: name,
            CompanyIds: getIdsByNameArray(companyList,selectedCompanyNames),
            ClaimGroups: selectedClaims
        }
        if (props && mode === 'edit'){
            inviteModel.id = props.userinfo.id;
        }
        setTimeout(()=>{
            let REQ , ErrMessage , SuccessMessage
           if (mode === 'edit') {
               ErrMessage = 'Cannot Edit user';
               SuccessMessage = 'User already Edited successfully';
           } else {
                ErrMessage = 'Cannot create User';
                SuccessMessage = 'User already created successfully';
           }
            if (email && name) {
                mode === 'edit' ?
                  REQ = Request(window._env_.REACT_APP_API_TREE_LIST + 'user').put(inviteModel)
                :
                  REQ = Request(window._env_.REACT_APP_API_TREE_LIST + 'user/invite').post(inviteModel)
                      REQ.then((data) => {
                          console.log ('data' , data)
                          if (data === false){
                              ShowInfo(ErrMessage,'error')
                              return;
                          }
                          if (data){
                              if (data.errors){
                                  ShowInfo(data.errors[0].message, 'error')
                                  return;
                              }
                              if (data.message){
                                  ShowInfo(data.message,'warning')
                                  return;
                              }
                              if (data || data.status === 'ok'){
                                  props.handleResponseInfo({message: SuccessMessage, type: 'success'});
                                  props.userPopupClose();
                              }
                          }

                    }).catch(() => {
                          ShowInfo(ErrMessage,'error')
                })
            }
        },0)
    }


    return (
            <div className="UserPopupContent">
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '74ch',marginTop: '14px' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <ValidateStyle
                        placement='bottom'
                        open={!!emailError}
                        title={emailError ? emailError : ''}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 0 }}
                        arrow
                    >
                    <TextField
                        disabled={props.popupOpenMode === 'edit'}
                        error = {!!emailError}
                        className={emailError ? 'txtErr':''}
                        id="outlined-email"
                        label="Email*"
                        value={email || ''}
                        onChange={(e)=> {setEmail (e.target.value)}}
                        onBlur={()=>{EmailFieldValidate()}}
                    />
                    </ValidateStyle>
                    <ValidateStyle
                        placement='bottom'
                        open={!!nameError}
                        title={nameError ? nameError : ''}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 0 }}
                        arrow
                    >
                    <TextField
                        error = {!!nameError}
                        className={nameError ? 'txtErr':''}
                        id="outlined-name"
                        label="Name*"
                        value={name || ''}
                        onChange={(e)=> {setName (e.target.value)}}
                        onBlur={()=>{NameFieldValidate()}}
                    />
                    </ValidateStyle>

                    <div style={{display: 'flex', flex: 'auto', marginLeft: '5px', marginTop: '5px'}}>
                        <Autocomplete
                            multiple
                            id="tags-companys"
                            options={companyListNames}
                            getOptionLabel={(option) => option}
                            value={selectedCompanyNames}
                            onChange={(event, newValue) => {
                                setSelectedCompanyNames(newValue);
                            }}
                            disableCloseOnSelect
                            renderOption={(props, option, { selected }) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                    sx={{ justifyContent: "space-between" }}
                                    {...props}
                                >
                                    {option}
                                    {selected ? <CheckIcon color="info" /> : null}
                                </MenuItem>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    className="companyField"
                                    {...params}
                                    variant="outlined"
                                    label="Companies"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        />
                    </div>
                    <span style={{marginLeft: '13px', color: '#b9b7b3'}}>Claim Group</span>
                    <Divider />
                    {!claimGroups.length > 0 ?
                    <div className='climeLoading'> Loading ... </div>
                        :
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{height: '288px', overflowY: 'scroll'}}>
                        {claimGroups.map((clime,index)=>
                                <Grid key={index}>
                                    <FormControl sx={{ m: 3 }}  className='UserClaimCheck' component="fieldset" variant="standard">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedClaims.includes(parseInt(clime.id))}
                                                    onChange={()=>{
                                                        handleClaimCheckboxClick(parseInt(clime.id))
                                                    }
                                                }
                                                    sx={{
                                                        color: '#a8a3db',
                                                        '&.Mui-checked': {
                                                            color: '#a8a3db',
                                                        },
                                                    }}
                                                />
                                            }
                                            label= {`${clime.name}`}
                                        />
                                        <span className='rolDescr'>{clime.description}</span>
                                    </FormControl>

                                </Grid>
                        )}
                    </Grid>
                    }
                </Box>
                <div className='controls'>
                    <Button className='UserOk_button' onClick={()=> {AddEditProcess(props.popupOpenMode)} }> <DoneIcon className="icons_margin" /> {`${props.popupOpenMode === 'edit' ? 'Save' : 'Invite'}`} </Button>
                    <Button className='UserCancel_button' onClick={()=> {props.userPopupClose()} }> <CloseIcon className="icons_margin" /> Cancel </Button>
                </div>
                <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose}
                          TransitionComponent={Fade}
                          key={SlideTransition.name}
                >
                    <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                        {infoMessage}
                    </Alert>
                </Snackbar>
            </div>
    )
}

export default User;

