import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import UsersIcon from '@mui/icons-material/PeopleOutline';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {Badge, IconButton} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from '../../Assets/logo.png';
import {Link} from "react-router-dom";
import useSessionStorage from "../useSessionStorage/useSessionStorage";

export default function Navigation() {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const badgeStyle = {
        "& .MuiBadge-badge": {
            backgroundColor: '#ffce00c9',
            color: 'black'
        }
    }
    let BadgeCount = parseInt(useSessionStorage('DRBadgeCount')) || 0;

    const list = (left) => (
        <Box
            sx={{ width: 300 }}
            role="presentation"
            onClick={toggleDrawer(left, false)}
            onKeyDown={toggleDrawer(left, false)}
        >
            <List>

                    <ListItem>
                        <ListItemIcon>
                            <MenuIcon />
                        </ListItemIcon>
                        <img className="logo" src={logo} alt="Logo"/>
                    </ListItem>

            </List>

            <List>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <ListItem >
                        <ListItemIcon>
                            <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Decision Trees " />
                    </ListItem>
                </Link>

                <Link to="/deploy-request" style={{ textDecoration: 'none' }}>
                <ListItem >
                    <ListItemIcon>
                        <Badge badgeContent={BadgeCount} sx={badgeStyle}>
                            <AltRouteIcon />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Deploy Request" />
                </ListItem>
                </Link>

                <Link to="/user-management" style={{ textDecoration: 'none' }}>
                <ListItem >
                    <ListItemIcon>
                        <UsersIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Management" />
                </ListItem>
                </Link>

            </List>
        </Box>
    );

    return (
        <div>
                <React.Fragment key='navigation'>
                    <div className="navigation">
                        <IconButton
                            size="small"
                            sx={{ ml: 2 }}
                            onClick={toggleDrawer('left', true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <Drawer
                        anchor='left'
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                    >
                        {list('left')}
                    </Drawer>
                </React.Fragment>

        </div>
    );
}