import * as joint from 'jointjs';
import { dia, linkTools, connectors, g } from 'jointjs';
import {
    connectToolAttributes,
    ShapeTypes,
    } from './diagram.common';
const {  Rect } = g;
const { TangentDirections } = connectors.curve;

window.joint = joint;


const BaseShape = dia.Element.define(ShapeTypes.BASE, {
    z: 1
}, {
    getConnectToolMarkup() {
        return [{
            tagName: 'rect',
            attributes: {
                ...this.size(),
                ...connectToolAttributes
            }
        }];
    },

    getCurveDirection() {
        return TangentDirections.AUTO;
    },

    getBoundaryPoint(point, snapRadius = 20) {
        const bbox = this.getBBox();
        const angle = this.angle();
        // Relative to the element's position
        const relPoint = point.clone().rotate(bbox.center(), angle).difference(bbox.topLeft());
        const relBBox = new Rect(0, 0, bbox.width, bbox.height);
        if (!relBBox.containsPoint(relPoint)) {
            const relCenter = relBBox.center();
            const relTop = relBBox.topMiddle();
            const relLeft = relBBox.leftMiddle();
            if (Math.abs(relTop.x - relPoint.x) < snapRadius) {
                return (relCenter.y > relPoint.y) ? relTop : relBBox.bottomMiddle();
            }
            if (Math.abs(relLeft.y - relPoint.y) < snapRadius) {
                return (relCenter.x > relPoint.x) ? relLeft : relBBox.rightMiddle();
            }
        }
        return this.getClosestBoundaryPoint(relBBox, relPoint);
    },

    getClosestBoundaryPoint(bbox, point) {
        return bbox.pointNearestToPoint(point);
    },

    getTools() {
        return [
            new linkTools.Connect({
                focusOpacity: 0,
                markup: this.getConnectToolMarkup()
            })
        ];
    }
});

export {BaseShape}